<template>
  <div
    class="
      d-flex
      flex-column
      align-items-stretch
      justify-content-center
      upload-dropbox
      overflow-hidden
    "
    :class="`${computeFileDraggedOver ? 'empty-dropzone' : ''}`"
  >
    <!-- loading indicator -->
    <div
      class="
        d-flex
        flex-column
        align-items-center
        justify-content-center
        upload-loader-container
      "
      v-if="imgLoading || computeInitialLoading"
    >
      <b-spinner
        type="grow"
        class="upload-loader"
        :variant="computeInitialLoading ? 'dark' : 'light'"
        :label="computeInitialLoading ? 'Loading Image' : 'Uploading Image'"
      ></b-spinner>
      <span
        class="text-left mx-auto upload-loader-text mt-2"
        :class="computeInitialLoading ? 'text-dark' : 'text-light'"
        >{{ computeInitialLoading ? "Loading Image" : "Uploading Image" }}</span
      >
    </div>
    <template v-if="imgData">
      <img
        class="image-preview"
        :src="imgData"
        alt
        srcset
        :width="640"
        :height="360"
      />
      <b-row
        class="d-flex flex-row align-items-center justify-content-center"
        no-gutters
      >
        <!-- remove button -->
        <b-button
          class="remove-button"
          @click="imageRemoveFn"
          variant="outline-danger"
          block
          >Remove Image</b-button
        >
      </b-row>
    </template>
    <template v-else>
      <Croppa
        :prevent-white-space="true"
        :show-remove-button="false"
        :show-loading="true"
        :placeholderFontSize="12"
        :placeholder="computePlaceholder"
        placeholder-color="#343a40"
        canvas-color="#ffffff"
        :width="640"
        :height="360"
        :quality="2"
        :accept="'image/*'"
        v-model="cropperImage"
        :passive="(computeIsImagePresent && imgUploaded) || imgLoading"
        image-border-radius="0"
        @new-image="imageHelper"
        class="
          d-flex
          flex-column
          align-items-center
          justify-content-center
          px-0
          custom-croppa-component
        "
      ></Croppa>
      <b-row
        v-if="computeIsImagePresent && !imgUploaded"
        class="d-flex flex-row align-items-center justify-content-center"
        no-gutters
      >
        <b-col>
          <!-- apply button -->
          <b-button
            id="apply-image-btn"
            class="apply-button"
            @click="imageApplyFn"
            variant="secondary"
            block
            >Apply</b-button
          >
          <b-tooltip
            id="image-apply-tooltip"
            target="apply-image-btn"
            triggers="manual"
            placement="top"
            boundary="window"
            offset="0"
          >
            {{ imageApplyText }}
          </b-tooltip>
        </b-col>
        <b-col>
          <!-- discard button -->
          <b-button
            id="discard-image-btn"
            class="discard-button"
            @click="imageDiscardFn"
            variant="outline-secondary"
            block
            >Discard</b-button
          >
          <b-tooltip
            id="image-discard-tooltip"
            target="discard-image-btn"
            triggers="manual"
            placement="top"
            boundary="window"
            offset="0"
          >
            {{ imageDiscardText }}
          </b-tooltip>
        </b-col>
      </b-row>
      <!-- image type/dimensions badge -->
      <b-row
        v-if="computeIsImagePresent"
        class="
          d-flex
          flex-row
          align-items-center
          justify-content-end
          image-details-row
        "
        no-gutters
      >
        <!-- image file size -->
        <b-badge v-if="computeImageFileSize" variant="dark" class="px-1 m-1">{{
          computeImageFileSize
        }}</b-badge>
        <!-- image type -->
        <b-badge v-if="computeImageType" variant="dark" class="px-1 m-1">{{
          computeImageType
        }}</b-badge>
        <!-- image dimensions -->
        <b-badge
          v-if="computeImageWidth && computeImageHeight"
          variant="dark"
          class="px-1 m-1"
          >{{ `${computeImageWidth} X ${computeImageHeight}` }}</b-badge
        >
      </b-row>
    </template>
  </div>
</template>

<script>
//   import { uploadProductImage } from "../../../service/product.service";
import Croppa from "vue-croppa";
// import { mapGetters } from "vuex";
export default {
  name: "ImageUploader",
  components: {
    Croppa: Croppa.component,
  },
  props: {
    state: {
      default: null,
      type: Boolean,
    },
    placeholder: {
      default: "Upload image here",
      type: String,
    },
  },
  data() {
    return {
      imageApplyText: false,
      imageDiscardText: false,
      imgLoading: false,
      imgData: null,
      imgUploaded: false,
      cropperImage: null,
    };
  },
  computed: {
    // ...mapGetters({
    //   initProduct: "product/getInitEditedProduct",
    //   productId: "product/getProductId",
    // }),
    computeInitialLoading() {
      return this.cropperImage && this.cropperImage.loading;
    },
    computePlaceholder() {
      return this.cropperImage && this.cropperImage.loading
        ? ""
        : "Upload your associated media files here";
    },
    computeIsImagePresent() {
      return this.cropperImage ? this.cropperImage.hasImage() : false;
    },
    computeFileDraggedOver() {
      return this.cropperImage ? this.cropperImage.fileDraggedOver : false;
    },
    computeImageWidth() {
      return this.cropperImage ? this.cropperImage.naturalWidth : false;
    },
    computeImageHeight() {
      return this.cropperImage ? this.cropperImage.naturalHeight : false;
    },
    computeImageType() {
      return this.cropperImage
        ? this.getImageType(this.cropperImage.chosenFile)
        : false;
    },
    computeImageFileSize() {
      return this.cropperImage
        ? this.getImageFileSize(this.cropperImage.chosenFile)
        : false;
    },
  },
  watch: {
    imgData(val) {
      this.$emit("primaryURL", val);
    },
    initProduct: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          if (this.$route.meta.isEdit) {
            this.imgData = val.primaryImage;
          }
        }
      },
    },
  },
  methods: {
    async imageApplyFn() {
      if (this.checkImageFileSize(this.cropperImage.chosenFile)) {
        this.imgLoading = true;
        setTimeout(async () => {
          // if (this.cropperImage.generateDataUrl()) {
          //   try {
          //     let payload = {
          //       Image: this.cropperImage.generateDataUrl(),
          //     };
          //     // console.log(payload);
          //     this.$emit("getImage", payload.Image);
          //     // this.imgData = data.content;
          //     this.cropperImage = null;
          //     // need uploaded or not state to set the passive prop of vue-croppa
          //     this.imgUploaded = true;
          //     this.imgLoading = false;
          //     this.refreshKey = Number(new Date());
          //   } catch (error) {
          //     console.log("Image Upload Error:", error);
          //   }
          // }
          this.cropperImage.generateBlob(
            (blob) => {
              this.$emit("getImage", {
                imageBlob: blob,
                cropperImage: this.cropperImage,
              });
            },
            "image/jpeg",
            0.8
          );
        }, 500);
      } else {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Image Upload",
          content: "Images must be less than 5MB in size.",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    // async imageApplyFn() {
    //   this.imgLoading = true;
    //   setTimeout(async () => {
    //     if (this.cropperImage.generateDataUrl()) {
    //       try {
    //         let payload = {
    //           ProductId: this.$route.meta.isEdit
    //             ? this.$route.params.productId
    //             : this.productId,
    //           Image: this.cropperImage.generateDataUrl(),
    //           Type: "primary",
    //         };
    //         let { data } = await uploadProductImage(payload);

    //         this.imgData = data.content;
    //         this.cropperImage = null;
    //         // need uploaded or not state to set the passive prop of vue-croppa
    //         this.imgUploaded = true;
    //         this.imgLoading = false;
    //         this.refreshKey = Number(new Date());
    //       } catch (error) {
    //         console.log("Image Upload Error:", error);
    //       }
    //     }
    //   }, 500);
    // },
    getImageType(file) {
      let imageType = file.type.split("/")[1];
      return imageType.toUpperCase();
    },
    checkImageFileSize(file) {
      let fileSize = file.size;
      console.log("filesize", fileSize, size);
      let size = Math.round(fileSize / 1024);
      // 1024 x 5
      return size < 5120;
    },
    getImageFileSize(file) {
      let fileSize = file.size;
      let fSExt = new Array("Bytes", "KB", "MB", "GB");
      let i = 0;
      while (fileSize > 900) {
        fileSize /= 1024;
        i++;
      }
      return Math.round(fileSize * 100) / 100 + " " + fSExt[i];
    },
    imageDiscardFn() {
      this.cropperImage && this.cropperImage.remove();
      this.imgData = null;
      this.imgUploaded = false;
      this.imgLoading = false;
    },
    imageRemoveFn() {
      this.imgData = null;
      this.imgUploaded = false;
      this.imgLoading = false;
    },
    imageHelper() {
      if (this.checkImageFileSize(this.cropperImage.chosenFile)) {
        setTimeout(() => {
          this.imageApplyText = "Click here to apply the changes to the image";
          this.$root.$emit("bv::show::tooltip", "image-apply-tooltip");
        }, 500);
        // hide after 6 seconds
        setTimeout(() => {
          this.$root.$emit("bv::hide::tooltip", "image-apply-tooltip");
          this.imageApplyText = null;
        }, 6000);
      } else {
        setTimeout(() => {
          this.imageDiscardText =
            "Please upload an image which is less than 5MB in size";
          this.$root.$emit("bv::show::tooltip", "image-discard-tooltip");
        }, 500);
        // hide after 6 seconds
        setTimeout(() => {
          this.$root.$emit("bv::hide::tooltip", "image-discard-tooltip");
          this.imageDiscardText = null;
        }, 6000);
      }
    },
  },
};
</script>

<style scoped>
.upload-dropbox {
  position: relative;
  background-color: var(--white);
  color: var(--dark);
  border: 1px dotted var(--prime-gray);
  user-select: none;
  /* min-width: 550px; */
  height: auto;
}

.helper-text.is-error {
  color: var(--danger);
}

.upload-dropbox .upload-loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.upload-dropbox .upload-loader {
  width: 3rem;
  height: 3rem;
}

.upload-dropbox .upload-loader-text {
  min-width: 110px;
  font-size: 0.7rem;
}

.upload-dropbox .upload-loader-text:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  -moz-animation: ellipsis steps(4, end) 900ms infinite;
  -o-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0;
}

.upload-dropbox .image-details-row {
  position: absolute;
  bottom: 40px;
  right: 0;
  font-size: 0.8rem;
}

.upload-dropbox .image-preview {
  background-color: var(--light);
  color: var(--dark);
  object-fit: contain;
}

.upload-dropbox .apply-button {
  border-radius: 0 !important;
}

.upload-dropbox .discard-button {
  border-radius: 0 !important;
}

.upload-dropbox .remove-button {
  border-radius: 0 !important;
}

/* croppa component */
.upload-dropbox .custom-croppa-component {
  background-color: var(--white);
  color: var(--dark);
  /* border-radius: 10px 10px 0 0; */
  transition: background-color 200ms ease-in, color 200ms ease-in;
  cursor: pointer;
}

.upload-dropbox .custom-croppa-component:hover {
  color: var(--light);
  background-color: var(--prime-gray);
}

/* if image is in target */
.upload-dropbox .custom-croppa-component.croppa--has-target {
  cursor: move;
}

.upload-dropbox .custom-croppa-component.croppa--passive {
  cursor: default;
}

/* animation keyframes */
@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-moz-keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-o-keyframes ellipsis {
  to {
    width: 20px;
  }
}
</style>
