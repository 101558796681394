<template>
  <!-- order items modal -->
  <b-modal
    id="enable-disable-admin-row-modal"
    ref="enableDisableItemModal"
    title="Update FAQ"
    size="lg"
    header-class="font-primary"
    header-bg-variant="white"
    header-text-variant="secondary"
    header-border-variant="gray-500"
    footer-bg-variant="gray-300"
    footer-border-variant="light"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="
          d-flex
          flex-column flex-sm-row
          align-items-center
          justify-content-center justify-content-sm-end
          mx-0
          w-100
        "
      >
        <FormButton
          variant="light"
          isBlock
          class="
            my-2 my-sm-0
            mr-0 mr-sm-2
            font-weight-normal
            text-secondary
            action-btn
          "
          @click.native="cancel"
          >Cancel</FormButton
        >

        <FormButton
          isBlock
          variant="secondary"
          :disabled="isLoading"
          :isLoading="isLoading"
          type="button"
          class="my-2 my-sm-0 ml-0 ml-sm-2 border action-btn border-secondary"
          @click.native="saveFAQ"
        >
          Save changes
        </FormButton>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <font-awesome-icon
        icon="times"
        class="text-secondary"
        @click="closeModal()"
        aria-label="Close Modal"
      ></font-awesome-icon>
    </template>

    <b-row
      class="d-flex flex-row align-items-start justify-content-start"
      no-gutters
    >
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <!-- Add a New FAQ form -->
        <form
          id="admin-registration-form"
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-start
            w-100
          "
        >
          <b-row
            class="
              d-flex
              flex-row
              align-items-start
              justify-content-start
              w-100
              py-2
            "
            no-gutters
          >
            <b-col cols="12">
              <FormInput
                groupId="admin-name-group"
                :counter="140"
                id="admin-name-group-input"
                class="text-prime-gray w-100"
                :state="titleError.status"
                :invalidFeedback="`${titleError.message}`"
                label="Title"
                v-model="title"
                isRequired
                trim
                form="admin-registration-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <b-row
            class="
              d-flex
              flex-row
              align-items-start
              justify-content-start
              w-100
            "
            no-gutters
          >
            <b-col cols="12">
              <!-- <FormInput
                :isTextArea="true"
                :rows="4"
                groupId="admin-name-group"
                id="admin-name-group-input"
                class="text-prime-gray w-100"
                :state="descriptionError.status"
                :invalidFeedback="`${descriptionError.message}`"
                label="Description"
                v-model="description"
                isRequired
                trim
                form="admin-registration-form"
                type="text"
              ></FormInput> -->
              <p>Description</p>
              <vue-editor
                label="Description"
                v-model="description"
                :editor-toolbar="customToolbar"
                :invalidFeedback="`${descriptionError.message}`"
              >
              </vue-editor>
            </b-col>
          </b-row>
          <!-- form action -->
          <b-row
            v-if="updateFAQError.status"
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-center
              w-100
            "
            no-gutters
          >
            <!-- error message span class -->
            <b-col
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
                px-0
                py-2
              "
              cols="9"
            >
              <span class="text-danger text-center" style="font-size: 0.8rem">{{
                updateFAQError.message
              }}</span>
            </b-col>
          </b-row>
        </form>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
// components
import FormButton from "@/components/Form/FormButton";
import FormInput from "@/components/Form/FormInput";

// services
import { UpdateManyFAQS } from "@/services/content.service";

export default {
  name: "UpdateFAQ",
  components: {
    FormButton,
    FormInput,
  },
  data() {
    return {
      isLoading: false,
      title: null,
      description: null,
      titleError: {
        status: null,
        message: null,
      },
      descriptionError: {
        status: null,
        message: null,
      },
      updateFAQError: {
        status: null,
        message: null,
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
    };
  },
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    itemContent: {
      default: null,
    },
  },
  watch: {
    itemContent(val) {
      if (val) {
        const { title, description } = val;
        this.title = title;
        this.description = description;
      } else {
        this.title = null;
        this.description = null;
      }
    },
    title(val) {
      this.updateFAQError.status = false;
      this.errorFn(val);
    },
    description(val) {
      this.updateFAQError.status = false;
      this.errorFn(this.title, val);
    },
  },
  methods: {
    closeModal() {
      this.$emit("onClose");
    },
    async saveFAQ() {
      var errors = this.errorFn();
      if (errors.length == 0) {
        this.isLoading = true;
        let payload = {
          _id: this.itemContent._id,
          title: this.title,
          description: this.description,
        };
        try {
          let { data } = await UpdateManyFAQS(payload);
          this.isLoading = false;
          if (data && data.code == 200 && data.message == "success") {
            let payloadNotify = {
              isToast: true,
              title: "FAQ Creation",
              content: "FAQ was updated Successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.$emit("reloadFn");
          } else if (data) {
            this.updateFAQError.status = true;
            this.updateFAQError.message = `An error occured when saving data: ${data.details}`;
            this.handleErrorFn("FAQ was not saved", [
              data.details,
              data.errorCode,
            ]);
          } else throw new Error("Error saving details");
        } catch (error) {
          this.updateFAQError.status = true;
          this.updateFAQError.message = `An error occured when saving data: ${error.message}`;
          this.handleErrorFn("FAQ was not saved", [error.message]);
        } finally {
          this.isLoading = false;
        }
      } else
        this.handleErrorFn(
          "It seems like you have missed some important fields",
          errors
        );
    },
    handleErrorFn(content, list) {
      var payloadNotify = {
        isToast: true,
        title: "ERROR! FAQ Update",
        content,
        list,
        variant: "danger",
      };
      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
    errorFn(title = this.title, description = this.description) {
      var titleErrors = this.validateTitle(title);
      var descriptionErrors = this.validateDescription(description);
      if (titleErrors.length > 0) {
        this.titleError.status = false;
        this.titleError.message = titleErrors[0];
      } else this.titleError.status = true;

      if (descriptionErrors.length > 0) {
        this.descriptionError.status = false;
        this.descriptionError.message = descriptionErrors[0];
      } else this.descriptionError.status = true;

      return [...titleErrors, ...descriptionErrors];
    },
    validateTitle(val) {
      var errorList = [];
      if (!val) errorList.push("Please fill in the title");
      else if (val.length > 140)
        errorList.push("Title must no exceed 140 characters");
      return errorList;
    },
    validateDescription(val) {
      var errorList = [];
      if (!val) errorList.push("Please fill in the description");
      return errorList;
    },
  },
};
</script>

<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
