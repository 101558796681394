<template>
  <!-- order items modal -->
  <b-modal
    id="reset-campaign-row-modal"
    ref="resetPayPalProgressModal"
    :title="`Are you sure you want to reset this charity?`"
    size="lg"
    header-class="font-primary"
    header-bg-variant="white"
    header-text-variant="secondary"
    header-border-variant="white"
    footer-bg-variant="gray-300"
    footer-border-variant="light"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="
          d-flex
          flex-column flex-sm-row
          align-items-center
          justify-content-center justify-content-sm-end
          mx-0
          w-100
        "
      >
        <FormButton
          variant="light"
          isBlock
          class="my-2 my-sm-0 mr-0 mr-sm-2 font-weight-normal text-secondary"
          @click.native="cancel"
          >Cancel</FormButton
        >
        <FormButton
          isBlock
          variant="danger"
          :disabled="!canReset"
          :isLoading="isLoading"
          type="button"
          class="my-2 my-sm-0 ml-0 ml-sm-2 border action-btn"
          style="background-color: #007bff"
          @click.native="resetProgress"
        >
          Reset
        </FormButton>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <font-awesome-icon
        icon="times"
        class="text-secondary"
        @click="closeModal()"
        aria-label="Close Modal"
      ></font-awesome-icon>
    </template>
    <b-row
      class="
        d-flex
        flex-row
        align-items-start
        justify-content-start
        w-100
        table-container-row
      "
      no-gutters
    >
      <p class="text-left text-prime-gray mb-4">
        {{ msg }}
      </p>
    </b-row>
  </b-modal>
</template>

<script>
// components
import FormButton from "@/components/Form/FormButton";

// services
import {
  PaypalStatus,
  ResetPayPalProgress,
} from "@/services/campaignManagement.service";

export default {
  name: "ResetPayPalProgress",
  components: {
    FormButton,
  },
  data() {
    return {
      isLoading: false,
      canReset: false,
      msg: "Loading...",
    };
  },
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    itemContent: {
      default: null,
    },
  },
  watch: {
    async itemContent() {
      this.isLoading = true;
      this.msg = "Loading...";
      console.log(this.itemContent);
      const { data } = await PaypalStatus(this.itemContent.id);
      this.isLoading = false;
      if (data.content.onboardingCompleted) {
        this.canReset = true;
        this.msg = "This charity has completed the PayPal integration. Are you sure you want to reset the integration ?";
      } else if (data.content.onboardingInProgress) {
        this.canReset = true;
        this.msg =
          "Are you sure you want to reset the PayPal integration progress for this charity ?";
      } else {
        this.msg = "This charity's PayPal integration is pending. ";
      }
      this.isLoading = false;
    },
  },
  computed: {
    notifyMsg() {
      if (this.itemContent) {
        return "aprrove";
      } else {
        return "";
      }
    },
  },
  methods: {
    async resetProgress() {
      this.isLoading = true;
      const { data } = await ResetPayPalProgress(this.itemContent.id);
      console.log(data);
      if (data.code == 200) {
        console.log("SUCCESS");
        this.canReset = false;
        this.closeModal();
        let payloadNotify = {
          isToast: true,
          title: `Reset PayPal integration progress`,
          content: "The integration progress has been reset successfully.",
          variant: "success",
        };
        this.isLoading = false;
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } else {
        this.isLoading = false;
        let content = "Failed to reset progress. ";
        if (data.display_msg) content = data.display_msg;

        let payloadNotify = {
          isToast: true,
          title: "ERROR!",
          variant: "danger",
          content,
          list: data.reasons,
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
