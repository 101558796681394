<template>
  <b-row
    class="d-flex flex-row align-items-start justify-content-start"
    no-gutters
  >
    <b-col class="d-flex flex-column align-items-start justify-content-start">
      <!-- Add a New FAQ form -->
      <h6
        class="text-left text-dark font-weight-semi-bold main-heading mb-3 mt-3"
      >
        Account retention email message
      </h6>
      <b-row
        class="
          d-flex
          flex-row
          align-items-start
          justify-content-start
          w-100
          py-2
        "
        no-gutters
      >
        <b-col cols="12">
          <!-- <FormInput
            groupId="company-vision-text-group"
            id="company-vision-text-group-input"
            class="text-prime-gray"
            :state="renewalCustomTextError.status"
            :invalidFeedback="`${renewalCustomTextError.message}`"
            label="Text"
            v-model="renewalustomText"
            isRequired
            isTextArea
            trim
            :counter="renewalCustomTextCount"
            form="what-we-do-form"
            type="text"
          ></FormInput> -->
          <vue-editor
            v-model="renewalustomText"
            :editor-toolbar="customToolbar"
            placeholder="Text"
          >
          </vue-editor>
        </b-col>
      </b-row>
      <b-row
        class="d-flex flex-row align-items-center justify-content-center w-100"
        no-gutters
      >
        <!-- reset password button -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            px-0
            py-2
          "
          cols="9"
          sm="6"
        >
          <FormButton
            variant="primary"
            isBlock
            class="font-secondary font-weight-normal text-white mt-2"
            type="submit"
            :isLoading="isLoading"
            @click.native="customTxtBtn"
            >Save Changes</FormButton
          >
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
// components
// @ is an alias to /src
import FormButton from "@/components/Form/FormButton";
//import FormInput from "@/components/Form/FormInput";

// services
import { CreateVideoText, GetVideoText } from "@/services/content.service";

export default {
  name: "Earnings",
  components: {
    FormButton,
    //FormInput,
  },
  data() {
    return {
      isLoading: false,
      isAPILoading: false,
      renewalustomText: null,
      renewalCustomTextError: {
        status: null,
        message: "",
      },
      renewalCustomTextCount: 160,
      grecaptchaToken: null,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
    };
  },

  mounted() {
    this.initFn();
  },
  watch: {
    renewalustomText(val) {
      if (val) {
        this.renewalCustomTextError.status = true;
        if (val.length <= this.renewalCustomTextCount) {
          this.renewalCustomTextError.status = true;
        } else {
          this.renewalCustomTextError.status = false;
          this.renewalCustomTextError.message = `Account retention message text should have less than ${this.renewalCustomTextCount} characters`;
        }
      } else {
        this.renewalCustomTextError.status = false;
        this.renewalCustomTextError.message =
          "Please fill in the account retention message";
      }
    },
  },
  methods: {
    async initFn() {
      try {
        this.isAPILoading = true;
        let { data } = await GetVideoText();
        if (data.code == 200 || data.message == "success") {
          let details = data.content;

          this.renewalustomText = details.customText;
        } else {
          this.isAPILoading = false;
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Account retention mail text",
            content: "Failed to retrieve data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        this.isAPILoading = false;
        console.log(error);
      }
    },
    async customTxtBtn() {
      try {
        this.isLoading = true;
        let payload = {
          retentionText: this.renewalustomText,
        };

        let { data } = await CreateVideoText(payload);

        if (data && data.code == 200 && data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Account retention mail text",
            content: "Account retention mail text was saved successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.$emit("reloadFn");
          this.initFn();
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Account retention mail text",
            content: `Unable to save account retention mail text! ${
              data ? data.msg : "Please try again."
            }.`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Video Account retention mail text",
          content: "Failed to save data! please check again: ",
          list: [error.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
/* table card */
.card-row {
  height: 100%;
}

.card-row .card-container {
  min-height: 560px;
  border-radius: 16px 16px 16px 16px;
}

.body-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  height: 100%;
}

.body-container .left-column .inner-row-grid {
  display: grid;
  grid-template-columns: 25% 70%;
  grid-gap: 5%;
}

.body-container .left-column .inner-row-grid .admin-type-badge {
  width: max-content;
}

.body-container .column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}

.footer-img {
  max-width: 150px;
}

.version-number {
  font-size: 0.7rem;
  line-height: 20px;
}
.contact-number-input.form-group >>> input.form-control {
  border-left: 0px solid transparent;
  border-radius: 0px 4px 4px 0px;
}
.contact-number-input >>> .input-group-prepend {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dee2e6;
  border-color: var(--stroke-light);
  box-shadow: 0px 2px 8px var(--shadow-soft);
  min-width: 60px;
  margin-left: 0;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .body-container {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }

  .body-container .column-divider {
    width: 100%;
    height: 0;
    border-right: none;
    border-top: 1px solid var(--light);
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .body-container .left-column .inner-row-grid {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
}
</style>