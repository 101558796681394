<template>
  <b-navbar
    type="light"
    class="
      d-flex
      flex-column
      align-items-center
      justify-content-between
      px-0
      pt-3
      pb-0
    "
    :class="!sideMenuExpanded ? 'main-navbar collapsed' : 'main-navbar'"
  >
    <!-- start collapse icon -->
    <font-awesome-icon
      :icon="!sideMenuExpanded ? 'arrow-right' : 'arrow-left'"
      class="collapse-icon"
      @click="toggleCollapse"
    />
    <!-- end collapse icon -->

    <b-col
      class="
        d-flex
        flex-column
        justify-content-between
        align-items-center
        nav-collapse
        px-0
        w-100
      "
      is-nav
    >
      <b-navbar-nav
        class="d-flex flex-column align-items-center justify-content-start"
      >
        <template v-for="(item, index) in menuArray">
          <!-- @click.prevent is to prevent default behavior of anchor tag (that is in the b-nav-item component) -->
          <b-nav-item
            :key="key + index"
            :id="`nav-item-${index}`"
            :active="item.routeName == $route.name"
            :title="item.label"
            :class="
              !sideMenuExpanded
                ? 'side-nav-item py-2 collapsed'
                : 'side-nav-item py-2'
            "
            @click="routeFunc(item, index)"
            @click.prevent
          >
            <b-row
              class="
                d-flex
                flex-row
                align-items-center
                side-nav-item-row
                mx-auto
              "
              :class="
                !sideMenuExpanded
                  ? 'justify-content-center'
                  : 'justify-content-start'
              "
            >
              <font-awesome-icon
                :icon="item.icon"
                class="
                  d-flex
                  jusify-content-center
                  align-items-center
                  side-nav-icon
                "
                :class="!sideMenuExpanded ? 'px-1' : 'pr-2'"
              />
              <span
                class="side-nav-text font-primary font-weight-normal text-left"
                >{{ item.label }}</span
              >
            </b-row>
          </b-nav-item>
          <b-tooltip
            :key="key + index + 'pop'"
            :target="`nav-item-${index}`"
            triggers="hover"
            placement="right"
            boundary="window"
            offset="0"
          >
            {{ item.label }}
          </b-tooltip>
        </template>
      </b-navbar-nav>
    </b-col>
  </b-navbar>
</template>
<script>
/* eslint-disable no-console */
import { mapGetters } from "vuex";

import ppNavItems from "./pp-nav-items.json";
import gpNavItems from "./gp-nav-items.json";

export default {
  name: "NavBar",
  props: {
    sideMenuExpanded: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      key: Number(new Date()),
      menuArray: []
    };
  },
  computed: {
    ...mapGetters({
      sideMenuIndex: "getSideMenuIndex",
    }),
  },
  mounted() {
    let adminType = "ADMIN";

    if (localStorage.getItem("adminDetails")) {
      let admin = JSON.parse(localStorage.getItem("adminDetails"));
      adminType = admin.adminType;
    }

    const platform = process.env.VUE_APP_NAME;
    this.populateNavBar(platform, adminType)

  },
  watch: {
    isShow(value) {
      this.showSidebar = value;
    },
  },
  methods: {
    populateNavBar(platform = "personal-pac", role = "ADMIN") {
      let navItems = [];
      if (platform == 'give-proudly') {
        navItems = gpNavItems;
      } else {
        navItems = ppNavItems;
      }
      this.menuArray = navItems.map(item => {
        if (item.accessRoles.includes(role)) {
          return item;
        }
      })
    },
    toggleCollapse() {
      let showSideMenu = !this.sideMenuExpanded;
      this.$store.dispatch("setIsSideMenuExpanded", showSideMenu);
    },
    routeFunc(item, index) {
      this.key = Number(new Date());
      this.$store.dispatch("setSideMenuIndex", index);
      this.$router.replace({ name: item.routeName }).catch((err) => {
        console.log("ROUTE SWITCH ERROR: ", err);
      });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-navbar {
  background-color: var(--white);
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.05);
  position: fixed;
  width: 220px;
  max-width: 220px;
  min-height: calc(100vh - 140px);
  z-index: 200;
  top: 60px;
  bottom: 0;
  left: 0;
  transition: max-width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
/* collapsed state design */
.main-navbar.collapsed {
  max-width: 80px;
}
.collapse-icon {
  position: absolute;
  opacity: 0;
  right: -12px;
  top: 60px;
  width: 24px;
  height: 24px;
  font-size: 0.3rem;
  z-index: 500;
  padding: 5px;
  color: var(--primary);
  background-color: var(--white);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 100ms linear 0s, color 100ms linear 0s,
    opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
    transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
.collapse-icon:hover {
  color: var(--white);
  background-color: var(--primary);
}
/* navbar menus to overflow when height is insufficient */
.nav-collapse {
  height: calc(100% - 60px);
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.nav-collapse::-webkit-scrollbar {
  width: 0.5rem;
  scrollbar-width: thin;
}
.nav-collapse::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.nav-collapse::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.main-navbar:hover .collapse-icon {
  opacity: 1;
}
/* side nav design */
.navbar .navbar-nav .side-nav-item {
  width: 220px;
  transition: background-color 100ms linear 0s, color 100ms linear 0s,
    max-width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
/* nav item hover state */
.navbar .navbar-nav .side-nav-item:hover {
}
/* collapsed state design  */
.navbar .navbar-nav .side-nav-item.collapsed {
  width: 80px;
}
/* nav link (icon/text) color (default state)  */
.side-nav-item a.nav-link {
  background-color: transparent;
  padding: 0.5rem 1.25rem;
  transition: background-color 100ms linear 0s, color 100ms linear 0s;
}
.side-nav-item a.nav-link .side-nav-icon {
  color: var(--prime-gray);
  transition: color 100ms ease-in;
  user-select: none;
  height: 36px;
  width: 36px;
}
.side-nav-item a.nav-link .side-nav-text {
  font-size: 12px;
  color: var(--dark);
  padding-left: 0.65rem;
  transition: color 100ms ease-in, opacity 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
  opacity: 1;
  user-select: none;
}
/* collapsed state design  */
.side-nav-item.collapsed a.nav-link {
  padding: 0.5rem;
}
.side-nav-item.collapsed a.nav-link .side-nav-text {
  opacity: 0;
  width: 0;
  padding-left: 0;
}
/* nav link (icon/text) hover color (default state) */
.side-nav-item:hover a.nav-link .side-nav-icon {
  color: var(--primary);
}
.side-nav-item:hover a.nav-link .side-nav-text {
  color: var(--primary);
}
/* nav link (icon/text) color (active state)  */
.side-nav-item a.nav-link.active {
  color: var(--dark);
  cursor: auto;
  border-right: 3px solid var(--primary);
}
.side-nav-item a.nav-link.active .side-nav-icon {
  color: var(--primary);
}
.side-nav-item a.nav-link.active .side-nav-text {
  color: var(--primary);
}
/* nav link (icon/text) hover color (active state)  */
.side-nav-item:hover a.nav-link.active {
}
.side-nav-item:hover a.nav-link.active .side-nav-icon {
}
.side-nav-item:hover a.nav-link.active .side-nav-text {
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .main-navbar.collapsed {
    max-width: 60px;
  }
  .collapse-icon {
    opacity: 1;
  }
}
/* smartphones, touchscreens */
@media (hover: none) and (pointer: coarse) {
  .collapse-icon {
    opacity: 1;
  }
}
</style>
