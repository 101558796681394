import Instance from "./instance";

export const GetAllComplianceSlides = async () => {
  try {
    return await Instance.instanceToken.get("/complianceslides");
  } catch (error) {
    return error;
  }
};
export const GetSingleComplianceSlide = async (payload) => {
  try {
    return await Instance.instanceToken.get(`/complianceslides/${payload.id}`);
  } catch (error) {
    return error;
  }
};
export const CreateComplianceSlide = async (payload) => {
  try {
    return await Instance.instanceFileUpload.post("/complianceslides", payload);
  } catch (error) {
    return error;
  }
};
export const UpdateComplianceSlide = async (id, payload) => {
  console.log(payload);
  try {
    return await Instance.instanceFileUpload.put(
      `/complianceslides/${id}`,
      payload
    );
  } catch (error) {
    return error;
  }
};
export const DeleteComplianceSlide = async (id) => {
  try {
    return await Instance.instanceToken.delete(`/complianceslides/${id}`);
  } catch (error) {
    return error;
  }
};
