import Instance from "./instance";

export const GetSlideConfiguration = async () => {
    try {
        return await Instance.instanceToken.get(`/slideconfiguration`);
    } catch (error) {
        return error;
    }
};
export const UpdateSlideConfiguration = async (payload) => {
    try {
        return await Instance.instanceToken.put(`/slideconfiguration`, payload);
    } catch (error) {
        return error;
    }
};