<template>
  <!-- order items modal -->
  <b-modal
    id="show-csv-model"
    title="Export Data"
    size="md"
    header-class="font-primary"
    header-bg-variant="white"
    header-text-variant="secondary"
    header-border-variant="white"
    footer-bg-variant="gray-300"
    footer-border-variant="light"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="
          d-flex
          flex-column flex-sm-row
          align-items-center
          justify-content-center justify-content-sm-end
          mx-0
          w-100
        "
      >
        <FormButton
          variant="light"
          isBlock
          class="my-2 my-sm-0 mr-0 mr-sm-2 font-weight-normal text-secondary"
          @click.native="cancel"
          >Cancel</FormButton
        >
        <FormButton
          variant="danger"
          :disabled="isLoading"
          :isLoading="isLoading"
          isBlock
          class="my-2 my-sm-0 ml-0 ml-sm-2 font-weight-normal text-white"
          type="button"
          @click.native="exportUsers"
          @submit="reset"
          @click="$bvModal.hide('show-csv-model')"
          >Export</FormButton
        >
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <font-awesome-icon
        icon="times"
        class="text-secondary"
        @click="closeModal()"
        aria-label="Close Modal"
      ></font-awesome-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100"
      no-gutters
    >
      <div>
        <b-col
          class="
            d-flex
            flex-row
            align-items-center align-content-center
            text-center
          "
          v-model="selected"
        >
          <b-row no-gutters class="ml-5 mr-5">
            <b-form-radio
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="active"
              >Active</b-form-radio
            >
          </b-row>
          <b-row no-gutters class="ml-5">
            <b-form-radio
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="delete"
              >Deleted</b-form-radio
            >
          </b-row>
        </b-col>
      </div>
    </b-row>
  </b-modal>
</template>

<script>
// services
import { ExportCsv } from "@/services/campaignManagement.service";
import FormButton from "@/components/Form/FormButton";

export default {
  name: "ExportCsv",
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    itemContent: {
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      status: "not_accepted",
      selected: "active",
    };
  },
  components: {
    FormButton,
  },
  methods: {
    async exportUsers() {
      this.isLoading = true;
      try {
        const params = {};
        if (this.selected == "active") {
          params.extended = 1;
        } else {
          params.deleted = 1;
        }
        const res = await ExportCsv(params);
        const data = res.data;
        const hiddenElement = document.createElement("a");
        const timeStamp = new Date().toLocaleDateString().replaceAll("/", "-");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(data);
        hiddenElement.target = "_blank";
        hiddenElement.download = `Donors-Export-${timeStamp}.csv`;
        hiddenElement.click();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        this.reset();
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("onClose");
    },
    reset() {
      this.selected = "active";
    },
  },
};
</script>

<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
