<template>
  <b-row
    class="d-flex flex-row align-items-start justify-content-start"
    no-gutters
  >
    <b-col class="d-flex flex-column align-items-start justify-content-start">
      <h6
        class="text-left text-dark font-weight-semi-bold font-primary mb-3 mt-3"
      >
        Create a new coupon
      </h6>
      <!-- Add a New coupon form -->
      <form
        id="admin-registration-form"
        class="d-flex flex-column align-items-start justify-content-start w-100"
      >
        <!-- full row input (coupon code) -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-start
            justify-content-start
            w-100
            py-2
          "
          no-gutters
        >
          <b-col cols="12">
            <FormInputGroup
              groupId="password-group"
              id="password-group-input"
              v-model="coupon"
              :state="couponError.status"
              :invalidFeedback="`${couponError.message}`"
              label="Coupon"
              placeholder="Minimum 6 characters"
              isRequired
              form="admin-registration-form"
              isAppend
            >
              <template v-slot:append>
                <b-button
                  class="px-3 px-md-4 key-row bg-light"
                  variant="transparent"
                  @click="generatePassword"
                >
                  <font-awesome-icon icon="key"></font-awesome-icon>
                  <span> Securely Generate</span>
                </b-button>
              </template>
            </FormInputGroup>
          </b-col>
        </b-row>
        <!-- full row input (percentage) -->

        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100"
          no-gutters
        >
          <b-col cols="12">
            <FormInputGroup
              groupId="admin-email-group"
              id="admin-email-group-input"
              class="text-prime-gray"
              :state="percentOffError.status"
              :invalidFeedback="`${percentOffError.message}`"
              label="Discount value"
              v-model="percentOff"
              isRequired
              trim
              form="admin-registration-form"
              type="number"
              isAppend
            >
              <template v-slot:append>
                <b-button
                  class="
                    px-3 px-md-4
                    icon-btn
                    border border-gray
                    overflow-hidden
                    shadow-sm
                  "
                  variant="transparent"
                >
                  <font-awesome-icon
                    class="text-prime-gray"
                    icon="percent"
                    aria-label="Help"
                  ></font-awesome-icon>
                </b-button>
              </template>
            </FormInputGroup>
          </b-col>
        </b-row>
        <!-- full row input (end date) -->

        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100"
          no-gutters
        >
          <b-col cols="12">
            <b-form-datepicker
              class="font-primary custom-date-picker font-secondary text-red"
              placeholder="Expiry Date"
              locale="en-US"
              v-model="endDate"
              :state="endDateError.status"
              :invalidFeedback="endDateError.message"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              form="create-supplier-form"
              :min="new Date().toISOString().split('T')[0]"
            ></b-form-datepicker>
          </b-col>
        </b-row>
        <!-- form action -->
        <b-row
          v-if="createCouponError.status"
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <!-- error message span class -->
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="9"
          >
            <span class="text-danger text-center" style="font-size: 0.8rem">{{
              createCouponError.message
            }}</span>
          </b-col>
        </b-row>
        <!-- submit button -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="9"
            sm="6"
          >
            <FormButton
              variant="primary"
              isBlock
              class="font-weight-normal text-white mt-2 border-dark"
              type="submit"
              @click.native="createPassword"
              :isLoading="isAPILoading"
              >Create Coupon</FormButton
            >
          </b-col>
        </b-row>
      </form>
    </b-col>
  </b-row>
</template>

<script>
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";

import { CreateCoupon } from "@/services/discountCoupon.service";
// import FormInput from "@/components/Form/FormInput";

export default {
  name: "CreateCoupon",
  components: {
    FormInputGroup,
    FormButton,
    // FormInput,
  },
  props: {
    couponList: Array,
  },
  data() {
    return {
      coupon: null,
      isAPILoading: false,
      couponError: {
        status: null,
        message: "",
      },
      createCouponError: {
        status: null,
        message: "",
      },
      isReset: false,
      percentOff: null,
      percentOffError: {
        status: null,
        message: "",
      },
      endDate: null,
      endDateError: {
        status: null,
        message: "",
      },
    };
  },
  watch: {
    coupon(val) {
      if (!this.isReset) {
        this.couponError.status = null;
        if (val != null) {
          this.couponError.status = true;
          if (!val) {
            this.couponError.status = false;
            this.couponError.message = "Please fill in the field";
          } else if (val.length < 5) {
            this.couponError.status = false;
            this.couponError.message = "Coupon must have at least 5 characters";
          } else if (val.length > 5) {
            this.couponError.status = false;
            this.couponError.message = "Coupon must not exceed 5 characters";
          }
        }
      }
    },
    percentOff(value) {
      if (!this.isReset) {
        this.percentOffError.status = null;
        if (value) {
          this.percentOffError.status = true;
        } else {
          this.percentOffError.status = false;
          this.percentOffError.message = "Please fill out this field";
        }
      }
    },
    endDate(value) {
      if (!this.isReset) {
        this.endDateError.status = null;
        if (value) {
          this.endDateError.status = true;
        } else {
          this.endDateError.status = false;
          this.endDateError.message = "Please fill out this field";
        }
      }
    },
  },
  methods: {
    generatePassword() {
      var length = 5;
      var mask =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      var result = "";
      for (var i = length; i > 0; --i) {
        result += mask[Math.floor(Math.random() * mask.length)];
      }
      this.coupon = result;
    },
    async createPassword() {
      var errors = this.errorFn();
      if (errors.length == 0) {
        let payload = {
          code: this.coupon,
          percentOff: this.percentOff,
          endDate: this.endDate,
        };
        try {
          this.isAPILoading = true;
          let { data } = await CreateCoupon(payload);
          if (data.code == 200 && data.message == "success") {
            let payloadNotify = {
              isToast: true,
              title: "Coupon Creation",
              content: "Coupon has been created successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.$emit("reloadFn");
            this.resetFn();
          } else {
            this.createPasswordError.status = false;
            this.createPasswordError.message = `An error occured when saving the Coupon: ${data.details}`;

            //  notification
            let payloadNotify = {
              isToast: true,
              title: "Coupon Creation",
              content: "Unable to create Coupon.",
              list: [data.details, data.errorCode],
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.isAPILoading = false;
        }
      } else {
        let payloadNotify = {
          isToast: true,
          title: "Coupon Creation",
          content: "Unable to create Coupon.",
          list: errors,
          variant: "danger",
        };

        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    errorFn() {
      var errorList = [];

      if (!this.coupon) {
        this.couponError.status = false;
        this.couponError.message = "Please fill in the coupon";
        errorList.push(this.couponError.message);
      } else if (this.coupon.length < 5) {
        this.couponError.status = false;
        this.couponError.message =
          "A minimum length of 5 characters is required";
        errorList.push(this.couponErrornpm.message);
      } else if (this.couponList.includes(this.coupon)) {
        errorList.push("This coupon is already added.");
      } else this.couponError.status = true;

      if (!this.percentOff) {
        this.percentOffError.status = false;
        this.percentOffError.message = "Please fill in the collection address";
        errorList.push(this.percentOffError.message);
      }

      //  if (!this.endDate) {
      //   this.endDateError.status = false;
      //   this.endDateError.message =
      //     "Please fill in the collection address";
      //   errorList.push(this.endDateError.message);
      // }
      return errorList;
    },
    resetFn() {
      this.isReset = true;
      this.coupon = "";
      this.percentOff = "";
      this.endDate = "";
      this.couponError.status = null;
      this.percentOffError.status = null;
      this.endDateError.status = null;
      this.createCouponError.status = null;
      setTimeout(() => (this.isReset = false), 100);
    },
  },
};
</script>

<style scoped>
.key-row {
  background-color: rgba(236, 239, 244, 1);
  border-radius: 0px 8px 8px 0px;
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* == FORM GROUP STYLING == */
/* customized form control */
#region-selector-group.form-group {
  position: relative;
}

#region-selector-group.form-group
  >>> select.region-selector-select::placeholder {
  color: var(--prime-gray);
}

/* == FORM SELECT STYLING == */
/* form custom select styling (default state) */
#region-selector-group.form-group >>> select.region-selector-select {
  min-height: 50px;
  border-radius: 0;
  background-color: transparent;
  color: var(--muted-gray);
}

#region-selector-group >>> select.region-selector-select option {
  color: #4f5467;
}

/* form custom select error state styling (valid state) */
#region-selector-group.form-group.is-valid >>> select.region-selector-select {
  border-color: var(--success);
}

/* form custom select error state styling (invalid state) */
#region-selector-group.form-group.is-invalid >>> select.region-selector-select {
  border-color: var(--danger);
}

/* form custom select focused styling (focus state) */
#region-selector-group.form-group.is-focus >>> select.region-selector-select {
  z-index: 0;
}

#region-selector-group.form-group >>> select.region-selector-select:focus {
  border-color: var(--info);
  box-shadow: none;
}

/* form custom select focused error state styling (focus/valid state) */
#region-selector-group.form-group.is-focus.is-valid
  >>> select.region-selector-select {
  border-color: var(--success);
}

/* form custom select focused error state styling (focus/invalid state) */
#region-selector-group.form-group.is-focus.is-invalid
  >>> select.region-selector-select {
  border-color: var(--danger);
}

/* form custom select disabled state styling (disabled state) */
#region-selector-group.form-group.is-disabled >>> select.region-selector-select,
#region-selector-group.form-group:disabled >>> select.region-selector-select {
  color: var(--ghost-white);
}

/* label styling */
#region-selector-group.form-group >>> legend.region-selector-label {
  position: absolute;
  color: var(--prime-gray);
  font-size: 1rem;
  /* width: auto; */
  width: calc(100% - 35px);
  top: 13px;
  left: 13px;
  z-index: 1;
  white-space: nowrap;
  word-wrap: normal;
  text-overflow: clip;
  user-select: none;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  transition: top 100ms linear 0s, left 100ms linear 0s, color 100ms linear 0s,
    font-size 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
    opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

/* form label focused styling (focus state) */
#region-selector-group.form-group.is-focus >>> legend.region-selector-label {
  font-size: 0.8rem;
  color: var(--info);
  width: auto;
  top: -9px;
  left: 10px;
  opacity: 1;
  z-index: 2;
}

/* form label focused error state styling (focus/valid state) */
#region-selector-group.form-group.is-focus.is-valid
  >>> legend.region-selector-label {
  color: var(--success);
}

/* form label focused error state styling (focus/invalid state) */
#region-selector-group.form-group.is-focus.is-invalid
  >>> legend.region-selector-label {
  color: var(--danger);
}

/* form label disabled state styling (disabled state) */
#region-selector-group.form-group:disabled >>> legend.region-selector-label,
#region-selector-group.form-group.is-disabled >>> legend.region-selector-label {
  color: var(--ghost-white);
}

/* label before psuedo element (default state) */
#region-selector-group.form-group >>> legend.region-selector-label:before {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--white);
  z-index: -1;
  pointer-events: none;
  transition: width 100ms linear 0s, height 100ms linear 0s;
}

/* form label background focused styling (focus state) */
#region-selector-group.form-group.is-focus
  >>> legend.region-selector-label::before {
  width: 100%;
  height: 100%;
}

/* form label background disabled focused styling (disabled/focus state) */
#region-selector-group.form-group:disabled.is-focus
  >>> legend.region-selector-label::before,
#region-selector-group.form-group.is-disabled.is-focus
  >>> legend.region-selector-label::before {
  background-color: var(--white);
  height: 3px;
  top: 8px;
}
/* custom CSS */
.custom-date-picker {
  min-height: 50px;
  border-color: var(--stroke-light);
  box-shadow: 0px 2px 8px var(--shadow-soft);
  
}
.custom-date-picker >>> label.form-control {
  min-height: 50px;
  /* calculate the line height by reducing the excess padding */
  line-height: calc(50px - calc(0.375rem * 2));
}
</style>
