import Instance from "./instance";

export const CreateAdmin = async (payload) => {
  try {
    return await Instance.instanceToken.post("admin/add", payload);
  } catch (error) {
    return error;
  }
};

export const CreateAdminUser = async (payload) => {
  try {
    return await Instance.instanceToken.post("adminUser/add", payload);
  } catch (error) {
    return error;
  }
};

export const MasterRegister = async (payload) => {
  try {
    return await Instance.instance.post(`/admin/masterAdmin`, payload);
  } catch (error) {
    return error;
  }
};

export const GetAdmins = async () => {
  try {
    return await Instance.instanceToken.get(`/admin/getNonDeleted`);
  } catch (error) {
    return error;
  }
};

export const GetAdminUsers = async () => {
  try {
    return await Instance.instance.get(`/adminUser/all`);
  } catch (error) {
    return error;
  }
};

export const GetAnalytics = async () => {
  try {
    return await Instance.instanceToken.get(`/analytics/admin`);
  } catch (error) {
    return error;
  }
};

export const EnableDisableAdmin = async (payload) => {
  try {
    return await Instance.instanceToken.put(`/admin/enableAdmin`, payload);
  } catch (error) {
    return error;
  }
};

export const DeleteAdmin = async (payload) => {
  try {
    return await Instance.instanceToken.put(`/admin/deleteAdmin`, payload);
  } catch (error) {
    return error;
  }
};

export const EnableDisableAdminNew = (payload) => {
  return Instance.instanceToken.put(`/admin/toggleStatus`, payload);
};

export const DeleteAdminNew = async (payload) => {
  try {
    return await Instance.instanceToken.put(`/admin/delete`, payload);
  } catch (error) {
    return error;
  }
};
export const ChangePassword = async (payload) => {
  try {
    return await Instance.instanceToken.put(`/admin/changePassword`, payload);
  } catch (error) {
    return error;
  }
};
