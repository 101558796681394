import { render, staticRenderFns } from "./DeleteFAQ.vue?vue&type=template&id=0870961c&scoped=true"
import script from "./DeleteFAQ.vue?vue&type=script&lang=js"
export * from "./DeleteFAQ.vue?vue&type=script&lang=js"
import style0 from "./DeleteFAQ.vue?vue&type=style&index=0&id=0870961c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0870961c",
  null
  
)

export default component.exports