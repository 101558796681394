<template>
  <b-row
    class="d-flex flex-row align-items-start justify-content-start"
    no-gutters
  >
    <b-col class="d-flex flex-column align-items-start justify-content-start">
      <!-- Add a New FAQ form -->
      <h6 class="text-left text-dark font-weight-bold font-primary mb-3 mt-3">
        Campaign Registration Disclaimer
      </h6>
      <form
        id="admin-registration-form"
        class="d-flex flex-column align-items-start justify-content-start w-100"
      >
        <b-row
          class="
            d-flex
            flex-row
            align-items-start
            justify-content-start
            w-100
            py-2
          "
          no-gutters
        >
          <b-col cols="12">
            <!-- text editor -->
            <vue-editor
              v-model="disclaimerText"
              :editor-toolbar="customToolbar"
            >
            </vue-editor>
          </b-col>
        </b-row>
        <!-- form action -->
        <b-row
          v-if="saveDisclaimerError.status"
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <!-- error message span class -->
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="9"
          >
            <span class="text-danger text-center" style="font-size: 0.8rem">{{
              saveDisclaimerError.message
            }}</span>
          </b-col>
        </b-row>
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="6"
          >
            <FormButton
              variant="primary"
              isBlock
              class="font-weight-normal text-white mt-2 border-dark"
              type="submit"
              @click.native="saveData"
              :isLoading="isAPILoading"
              :isDisabled="isAPILoading"
              >Save Changes</FormButton
            >
          </b-col>
        </b-row>
      </form>
    </b-col>
    <!-- overlay loader -->
    <b-overlay :show="initLoading" class="mx-n3" no-wrap></b-overlay>
  </b-row>
</template>

<script>
// components
import FormButton from "@/components/Form/FormButton";

// services
import {
  SaveRegistrationDisclaimer,
  GetRegistrationDisclaimer,
} from "@/services/content.service";

export default {
  name: "Disclaimer",
  components: {
    FormButton,
  },
  created() {
    this.onInit();
  },
  data() {
    return {
      initLoading: false,
      isAPILoading: false,
      disclaimerText: null,
      disclaimerTextError: {
        status: null,
        message: null,
      },
      saveDisclaimerError: {
        status: null,
        message: null,
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
    };
  },
  watch: {
    disclaimerText(val) {
      this.saveDisclaimerError.status = false;
      this.errorFn(val);
    },
  },
  methods: {
    closeModal() {
      this.$emit("onClose");
    },
    async onInit() {
      try {
        this.initLoading = true;
        var { data } = await GetRegistrationDisclaimer();
        console.log({ data });
        if (data.code == 200 && data.message == "success") {
          if (data.content)
            this.disclaimerText = data.content.registerDisclaimer;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.initLoading = false;
      }
    },
    async saveData() {
      try {
        this.isAPILoading = true;
        var errors = this.errorFn();
        if (errors.length == 0) {
          let payload = {
            registerDisclaimer: this.disclaimerText,
          };
          let { data } = await SaveRegistrationDisclaimer(payload);

          if (data && data.code == 200 && data.message == "success") {
            let payloadNotify = {
              isToast: true,
              title: "Disclaimer Content",
              content: "Disclaimer Content was saved successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.$emit("reloadFn");
          } else if (data) {
            this.saveDisclaimerError.status = true;
            this.saveDisclaimerError.message = `An error occured when saving the Disclaimer Content: ${data.details}`;
            this.handleErrorFn("Disclaimer Content was not saved", [
              data.details,
              data.errorCode,
            ]);
          } else throw new Error("Error saving details");
        } else {
          this.handleErrorFn(
            "It seems like you have missed some important fields when saving the Disclaimer Content",
            errors
          );
        }
      } catch (error) {
        this.saveDisclaimerError.status = true;
        this.saveDisclaimerError.message = `An error occured when saving the Disclaimer Content: ${error.message}`;
        this.handleErrorFn("Disclaimer Content was not saved", [error.message]);
      } finally {
        this.isAPILoading = false;
      }
    },
    handleErrorFn(content, list) {
      var payloadNotify = {
        isToast: true,
        title: "ERROR! Disclaimer Content",
        content,
        list,
        variant: "danger",
      };
      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
    errorFn(disclaimerText = this.disclaimerText) {
      var disclaimerTextErrors = this.validateDisclaimerText(disclaimerText);
      if (disclaimerTextErrors.length > 0) {
        this.disclaimerTextError.status = false;
        this.disclaimerTextError.message = disclaimerTextErrors[0];
      } else this.disclaimerTextError.status = true;
      return [...disclaimerTextErrors];
    },
    validateDisclaimerText(val) {
      var errorList = [];
      if (!val) errorList.push("Please fill in the Disclaimer text");
      return errorList;
    },
  },
};
</script>
