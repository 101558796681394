<template>
  <HomeLayout>
    <b-container
      class="
        d-flex
        flex-column
        align-items-start
        justify-content-start
        px-0
        position-relative
        pt-5
      "
    >
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          w-100
          user-card-row
        "
        no-gutters
      >
        <b-card
          no-body
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            user-card-container
          "
        >
          <b-row class="px-3 py-0 body-container-cards" no-gutters>
            <b-col class="px-0 px-md- py-3 left-column">
              <h6
                class="
                  text-left text-dark text-large
                  font-weight-semi-bold font-primary
                  mb-4
                  ml-2 ml-lg-0
                  mt-2
                "
              >
                Campaigns
              </h6>
              <b-row class="px-3 py-0 ml-1">
                <h4
                  class="
                    text-left
                    count-text
                    font-secondary font-weight-bold
                    text-dark
                    mr-1
                    mt-1
                  "
                >
                  {{ totalCampaigns }}
                </h4>
                <span
                  class="
                    text-left
                    font-secondary font-weight-normal
                    text-prime-gray
                    ml-1
                    mt-5
                  "
                  >total campaigns</span
                >
              </b-row>
            </b-col>
            <!-- <div class="column-divider-vertical" /> -->
            <!-- <hr class="first-column-divider"/> -->
            <b-col class="px-0 px-md-2 py-3 vertical-line">
              <h6
                class="
                  text-left text-dark text-large
                  font-weight-semi-bold font-primary
                  mb-4
                  mt-2
                  ml-2
                "
              >
                Videos
              </h6>
              <b-row class="px-4 py-0">
                <h4
                  class="
                    text-center
                    count-text
                    font-secondary font-weight-bold
                    text-dark
                    mt-1
                    mr-2
                  "
                >
                  {{ totalVideos }}
                </h4>
                <span
                  class="
                    text-left
                    font-secondary font-weight-normal
                    text-prime-gray
                    mt-5
                    ml-0
                    w-50
                  "
                  >total videos created</span
                >
              </b-row>
            </b-col>
            <!-- <div class="column-divider-vertical" /> -->
            <b-col class="px-2 px-lg-3 py-3 vertical-line">
              <h6
                class="
                  text-left text-dark text-large
                  font-weight-semi-bold font-primary
                  mb-4
                  mt-2
                "
              >
                Total Amount of Donations
              </h6>
              <b-row class="px-0 body-container" no-gutters>
                <!-- last month's numbers -->
                <b-col
                  cols="12"
                  class="
                    d-flex
                    flex-column
                    align-items-start
                    justify-content-start
                    px-0
                    mb-2 mb-md-0
                  "
                >
                  <div v-if="!this.dateRangeApplied">
                    <h6 class="font-primary text-prime-gray">Until</h6>
                    <h6 class="font-primary text-dark font-weight-bold">
                      {{ monthName + " " + yearName }}
                    </h6>
                  </div>
                  <div v-if="this.dateRangeApplied">
                    <h6 class="font-primary text-prime-gray">From</h6>
                    <h6 class="font-primary text-dark font-weight-bold">
                      {{
                        new Date(this.appliedDateFrom).toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </h6>
                    <h6 class="font-primary text-prime-gray">To</h6>
                    <h6 class="font-primary text-dark font-weight-bold">
                      {{
                        new Date(this.appliedDateTo).toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </h6>
                  </div>
                  <!-- main value -->
                  <h1 class="text-primary font-primary text-x-large">
                    {{ getFormattedNumbers(allTimeTotal) }}
                  </h1>
                </b-col>
                <!-- this month's numbers -->
              </b-row>
            </b-col>
            <!-- <div class="column-divider-vertical" /> -->
            <b-col class="px-2 px-lg-3 py-3 vertical-line">
              <h6
                class="
                  text-left text-dark text-large
                  font-weight-semi-bold font-primary
                  mb-4
                  mt-2
                "
              >
                Average Donation Value
              </h6>
              <b-row class="px-0 body-container" no-gutters>
                <!-- last month's numbers -->
                <b-col
                  cols="12"
                  class="
                    d-flex
                    flex-column
                    align-items-start
                    justify-content-start
                    px-0
                    mb-2 mb-md-0
                  "
                >
                  <div v-if="!this.dateRangeApplied">
                    <h6 class="font-primary text-prime-gray">Until</h6>
                    <h6 class="font-primary text-dark font-weight-bold">
                      {{ monthName + " " + yearName }}
                    </h6>
                  </div>
                  <div v-else>
                    <h6 class="font-primary text-prime-gray">From</h6>
                    <h6 class="font-primary text-dark font-weight-bold">
                      {{
                        new Date(this.appliedDateFrom).toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </h6>
                    <h6 class="font-primary text-prime-gray">To</h6>
                    <h6 class="font-primary text-dark font-weight-bold">
                      {{
                        new Date(this.appliedDateTo).toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </h6>
                  </div>
                  <!-- main value -->
                  <h1 class="text-secondary font-primary text-x-large">
                    {{ getFormattedNumbers(averageDonation) }}
                  </h1>
                </b-col>
                <!-- this month's numbers -->
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <!-- target video row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          w-100
          video-card-row
        "
        no-gutters
      >
        <b-card
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            video-card-container
          "
        >
          <b-row class="px-0 py-0 body-container">
            <b-col class="px-0 left-column">
              <b-col>
                <h6
                  class="
                    text-left text-dark
                    font-weight-semi-bold font-primary
                    mb-4
                    text-large
                    mt-2
                  "
                >
                  Top 5 Campaigns
                </h6>
                <b-table
                  :bordered="false"
                  :borderless="true"
                  :hover="true"
                  :striped="true"
                  selectable
                  select-mode="single"
                  small
                  class="table-element"
                  thead-class="table-header font-primary"
                  tbody-class="table-body"
                  :fields="HeaderFields"
                  :items="campaignList"
                  show-empty
                  :busy="isLoading"
                >
                  <!-- video link column -->
                  <template v-slot:cell(campaignName)="data">
                    <b-col
                      class="
                        d-flex
                        flex-column
                        align-items-start
                        justify-content-center
                        px-0
                      "
                    >
                      <div
                        class="
                          d-flex
                          flex-row
                          align-items-center
                          justify-content-center
                          text-dark
                          main-text-container
                        "
                      >
                        <span class="text-truncate">
                          {{ data.value.name }}
                        </span>
                      </div>
                    </b-col>
                  </template>
                  <!-- request column -->
                  <template v-slot:cell(campaignUrl)="data">
                    <b-col
                      class="
                        d-flex
                        flex-column
                        align-items-start
                        justify-content-center
                        px-0
                      "
                    >
                      <span class="request-count-text text-dark">{{
                        data.value.url
                      }}</span>
                    </b-col>
                  </template>
                </b-table>
              </b-col>
            </b-col>
            <div class="column-divider-line" />
            <b-col class="px-4 px-md-2 py-2 mt-3 mt-lg-0">
              <b-row class="d-flex flex-row align-items-center py-0">
                <b-col>
                  <h6
                    class="
                      text-left text-dark text-large
                      font-weight-semi-bold font-primary
                      mb-4
                      m-auto
                      pl-2
                    "
                  >
                    Average Video Rendering Time
                  </h6>
                </b-col>
                <b-col cols="2">
                  <div>
                    <b-button
                      v-b-tooltip.hover.left
                      :title="lastReset"
                      class="bg-white border-0 earser-btn"
                      @click="resetRenderTimer"
                    >
                      <font-awesome-icon
                        icon="eraser"
                        class="text-danger"
                        scale="0.4"
                        aria-hidden="true"
                      ></font-awesome-icon>
                    </b-button>
                    <!-- <span
                    class="
                      text-left text-small
                      font-weight-normal
                      text-danger
                      pl-2
                    "
                    >Clear</span
                  > -->
                  </div>
                </b-col>
              </b-row>
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  px-3
                  ml-1
                  py-0
                  text-start
                "
              >
                <h4
                  class="
                    text-left
                    font-secondary font-weight-bold
                    text-primary text-x-large
                    mr-1
                    mt-1
                  "
                >
                  {{ averageMinutes ? "0" + averageMinutes : "00" }}
                  <span
                    class="
                      text-left text-medium
                      font-secondary font-weight-normal
                      text-prime-gray
                      ml-1
                      mt-5
                      mr-3
                    "
                  >
                    Min</span
                  >
                </h4>
                <h4
                  class="
                    text-left
                    font-secondary font-weight-bold
                    text-info text-x-large
                    mr-1
                    mt-1
                  "
                >
                  {{ averageseconds ? "0" + averageseconds : "00" }}
                  <span
                    class="
                      text-left text-medium
                      font-secondary font-weight-normal
                      text-prime-gray
                      ml-1
                      mt-5
                    "
                  >
                    Sec</span
                  >
                </h4>
                <h4
                  class="
                    text-left
                    font-secondary font-weight-bold
                    text-info text-x-large
                    mt-1
                    ml-3
                  "
                >
                  {{ averageMinSeconds ? averageMinSeconds : "000" }}
                  <span
                    class="
                      text-left text-medium
                      font-secondary font-weight-normal
                      text-prime-gray
                      ml-1
                      mt-5
                    "
                  >
                    Ms</span
                  >
                </h4>
              </b-row>
              <hr class="ml-2 border-bottom-1 w-100 bg-light my-4" />
              <h6
                class="
                  text-left text-dark text-large
                  font-weight-semi-bold font-primary
                  mt-4
                  mb-4
                  pl-3
                  ml-2 ml-lg-0
                "
              >
                Filter
              </h6>
              <b-row
                class="
                  rounded=0
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                  mb-4
                  ml-2
                "
              >
                <b-col cols="12">
                  <b-form-datepicker
                    class="
                      date-picker
                      font-primary
                      datepicker
                      align-items-center
                    "
                    v-model="fromDate"
                    no-flip
                    :min="min"
                    :max="new Date().toISOString().substr(0, 10)"
                    placeholder="From"
                    locale="en-US"
                    button-variant="primary"
                    nav-button-variant="primary"
                    today-variant="primary"
                    menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                    aria-controls="start-date-input"
                    :disabled-date="disabledAfterToday"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  ></b-form-datepicker>
                </b-col>
              </b-row>
              <b-row
                class="
                  rounded=0
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                  mb-5
                  ml-2
                "
              >
                <b-col cols="12">
                  <b-form-datepicker
                    class="
                      date-picker
                      font-primary
                      datepicker
                      align-items-center
                    "
                    v-model="toDate"
                    no-flip
                    :min="min"
                    :max="new Date().toISOString().substr(0, 10)"
                    placeholder="To"
                    locale="en-US"
                    button-variant="primary"
                    nav-button-variant="primary"
                    today-variant="primary"
                    menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                    aria-controls="start-date-input"
                    :disabled-date="disabledAfterToday"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  ></b-form-datepicker>
                </b-col>
              </b-row>
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-center
                  row-padding
                  w-100
                  ml-2
                "
                no-gutters
              >
                <b-col
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    px-0
                    py-2
                    setUpBtn
                  "
                  cols="6"
                >
                  <b-button
                    variant="primary"
                    class="btn-submit text-white mt-2"
                    @click="filterMetrics"
                    >Submit
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <b-overlay :show="isLoading" z-index="4" no-wrap></b-overlay>
    </b-container>
  </HomeLayout>
</template>
<script>
// @ is an alias to /src
// layout
import HomeLayout from "@/layout/HomeLayout";

import { formatPrice } from "@/util/formatPrice.js";

import { GetAnalytics, ResetRenderTimer } from "@/services/dashboard.service";

export default {
  name: "Dashboard",
  components: {
    HomeLayout,
  },
  data() {
    return {
      isLoading: false,
      HeaderFields: [
        {
          key: "campaignName",
          label: "Campaign Name",
        },
        {
          key: "campaignUrl",
          label: "Campaign URL",
        },
      ],
      campaignList: [],
      totalCampaigns: null,
      totalVideos: null,
      allTimeTotal: null,
      averageDonation: null,
      monthName: null,
      yearName: null,
      averageseconds: null,
      averageMinutes: null,
      fromDate: null,
      toDate: null,
      appliedDateFrom:null,
      appliedDateTo:null,
      dateRangeApplied: false,
      averageMinSeconds: null,
      lastReset: "Reset render timer",
    };
  },
  computed: {
    getPlaceholderImageURL() {
      return "https://storage.googleapis.com/personalpac/dev/compliance-slides/white-placeholder.jpg";
    },
  },
  mounted() {
    this.getAnalytics();
    this.getMonths();
  },
  watch: {
    averageseconds(val) {
      if (val.length == 1) {
        "0" + val;
      }
    },
    averageMinutes(val) {
      if (val.length == 1) {
        "0" + val;
      }
    },
    averageMinSeconds(val) {
      if (val.length == 2) {
        "0" + val;
      }
    },
  },
  methods: {
    resetRenderTimer() {
      ResetRenderTimer()
        .then((res) => {
          if (res.status == 200) {
            this.averageseconds = 0;
            this.averageMinutes = 0;
            this.averageMinSeconds = 0;
            let payloadNotify = {
              isToast: true,
              title: "Render Timer",
              content: "Timer was reset",
              variant: "success",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
          } else {
            let payloadNotify = {
              isToast: true,
              title: "Render Timer",
              content: "Failed to reset timer",
              variant: "danger",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    async getAnalytics(from, to) {
      try {
        this.isLoading = true;
        let { data } = await GetAnalytics(from, to);
        console.log({ data });
        if (data.code == 200 || data.message == "success") {
          // total user count
          //this.totalUsers.count = data.content;
          this.totalCampaigns = data.content.noOfCampaigns;
          this.totalVideos = data.content.noOfVideos;
          this.allTimeTotal = data.content.allTimeTotal;
          this.averageDonation = data.content.averageDonation;
          this.averageseconds = data.content.averageRenderTime.sec;
          this.averageMinutes = data.content.averageRenderTime.min;
          this.averageMinSeconds = data.content.averageRenderTime.ms;
          this.dateRangeApplied = data.content.dateRangeApplied;
          if(data.content.dateRangeApplied){
            this.appliedDateFrom = new Date(data.content.dateRange.from)
            this.appliedDateTo = new Date(data.content.dateRange.to)
          }
          if (data.content.lastReset) {
            const d = new Date(data.content.lastReset);
            this.lastReset = `Last reset : ${d.toDateString()}`;
          }

          this.campaignList = data.content.topCampaigns.map((x) => {
            return {
              campaignName: {
                id: x._id,
                name: x.details.name,
              },
              campaignUrl: {
                id: x._id,
                url: x.details.url,
              },
            };
          });
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Users",
            content:
              "Unable to retrieve Donor Details' data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    filterMetrics() {
      this.getAnalytics(this.fromDate, this.toDate);
    },
    getMonths() {
      const d = new Date();
      this.monthName = d.toLocaleString('default', { month: 'short' });
      this.yearName = d.getFullYear();
    },
    getFormattedNumbers(text) {
      if (!text) return "$0.00";
      return formatPrice(text);
    },
    onKeypressDollorSign() {
      var str = this.minValue;
      str = str.replace("$ ", "");
      if (str.length > 0) {
        str = str.substring(0, 0) + "$ " + str.substring(0);
      }
      this.minValue = str;
    },
  },
};
</script>
<style scoped>
.user-card-row {
  height: auto;
  min-height: 180px;
}
.user-card-row .user-card-container {
  width: 100%;
  min-height: 180px;
  background-color: var(--white);
  border-radius: 16px 16px 0 0;
  border: 1px solid rgba(135, 147, 163, 0.25);
}
.video-card-row {
  height: auto;
  min-height: 380px;
}
.video-card-row .video-card-container {
  width: 100%;
  min-height: 380px;
  background-color: var(--white);
  border-radius: 0 0 16px 16px;
  border: 1px solid rgba(135, 147, 163, 0.25);
}
.width-config {
  width: 70%;
}
.body-container {
  display: grid;
  grid-template-columns: 64% 35%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
.btn-submit {
  padding-left: 70px;
  padding-right: 70px;
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.body-container-cards {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  /* grid-gap: 10px;
  padding: 10px; */
}
.body-container::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.body-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.body-container::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.body-container .column-divider {
  width: 560px;
  height: 100%;
  border-top: none;
  border-left: 1px solid var(--light);
}
.vertical-line {
  border-left: 2px solid var(--light);
  height: 100%;
  top: 0;
}
.body-container .first-column-divider {
  width: 753px;
  height: 100%;
  border-top: none;
  border-left: 2px solid var(--light);
}
.column-divider-vertical {
  border-left: 2px solid var(--light);
  height: 100%;
  top: 0;
}
.column-divider-line {
  border-left: 2px solid var(--light);
  height: 100%;
  position: absolute;
  left: 64%;
  top: 0;
}
.count-text {
  font-size: 64px;
}
.datepicker >>> .form-control {
  box-shadow: none;
  /* color: var(--prime-gray) !important; */
}
.date-picker >>> .btn .b-icon.bi {
  color: var(--prime-gray);
}
.row-padding {
  padding-bottom: 100px;
}
/* table element css */
.table-element >>> .table-header th {
  color: rgba(135, 147, 163, 1);
  font-size: 1rem;
  font-weight: 400;
  border-color: transparent;
  font-family: "Roboto", sans-serif;
}
.table-element >>> .table-body td {
  padding: 10px;
}
.table-element >>> .table-body .main-text-container {
  max-width: 400px;
}
.table-element >>> .table-body .main-text-container span {
  display: inline-block;
}
.small-main-heading {
  font-size: 12px;
}
.small-sub-heading {
  font-size: 16px;
}

.earser-btn {
  box-shadow: 1px 1px 1px 1px grey;
}
@media (max-width: 767.98px) {
  .column-divider-line {
    border-left: none;
  }
  .width-config {
    width: 100%;
  }
  .body-container-cards {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
  .vertical-line {
    border-left: none;
  }
  .body-container {
    grid-template-columns: 100%;
  }
}

@media (max-width: 991.98px) {
  .column-divider-line {
    border-left: none;
  }
  .width-config {
    width: 100%;
  }
  .body-container-cards {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
  .vertical-line {
    border-left: none;
  }
  .body-container {
    grid-template-columns: 100%;
  }
}
</style>
