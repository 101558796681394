<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- card container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 card-row"
        no-gutters
      >
        <b-card
          no-body
          class="overflow-hidden border border-light shadow-sm w-100 card-container"
        >
          <b-row class="px-3 py-0 body-container" no-gutters>
            <!-- left column -->
            <b-col class="px-0 px-md-2 py-3 left-column">
              <div class="inner-row-grid-switch w-100 px-0">
                <h6
                  class="text-left text-dark font-weight-semi-bold font-primary mb-2 mt-3"
                >
                  Audio Library
                </h6>
                <br />
              </div>
              <b-row
                class="d-flex flex-row align-items-start justify-content-start w-100"
                no-gutters
              >
                <b-col cols="12">
                  <FormInput
                    groupId="audio-title-group"
                    id="audio-title-group-input"
                    class="text-prime-gray"
                    :state="audioTitleError.status"
                    :invalidFeedback="`${audioTitleError.message}`"
                    label="Audio Title"
                    v-model="audioTitle"
                    isRequired
                    trim
                    form="new-target-audio-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <form-file
                ref="uploadAudio"
                groupId="audio-file-group"
                id="audio-file-group-input"
                class="text-prime-gray"
                :placeholder="audioFile ? getFileName(audioFile) : 'Audio File'"
                description="Only Upload .mp3, .m3a & .m4a files."
                v-model="audioFile"
                isRequired
                trim
                form="new-target-audio-form"
                type="audio"
                :state="audioFileError.status"
                :invalidFeedback="`${audioFileError.message}`"
              >
              </form-file>

              <b-col
                class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
              >
                <FormButton
                  variant="primary"
                  isBlock
                  class="font-weight-normal text-white mt-2 border-dark"
                  type="submit"
                  @click.native="uploadAudioFn"
                  :isLoading="isLoadingUpload"
                  :disabled="isLoadingUpload"
                  style="margin: 0 auto"
                  >Upload Audio</FormButton
                >
              </b-col>

              <!-- table container row -->
              <b-row
                class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
                no-gutters
              >
                <b-card
                  no-body
                  class="overflow-hidden shadow-sm border-0 table-container-card w-100"
                >
                  <!-- table actions row -->
                  <b-row
                    class="d-flex flex-row align-items-center justify-content-between w-100 my-1 my-md-2 px-3 py-3"
                    no-gutters
                  >
                    <b-col
                      class="d-flex flex-column align-items-center justify-content-center px-0 py-2 py-lg-0"
                      cols="12"
                      lg="2"
                    >
                    </b-col>

                    <!-- filter/create actions column -->
                    <b-col
                      class="d-flex flex-column align-items-center justify-content-center px-0 py-2 py-lg-0"
                      cols="12"
                      lg="8"
                    >
                      <b-row
                        class="d-flex flex-row align-items-center justify-content-between justify-content-lg-end w-100 mx-0"
                      >
                        <!-- add any filter elements here -->
                      </b-row>
                    </b-col>
                  </b-row>
                  <!-- table row -->
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start px-3 w-100"
                    no-gutters
                  >
                    <b-table
                      :bordered="false"
                      :borderless="true"
                      :hover="true"
                      show-empty
                      class="table-element"
                      thead-class="table-header font-secondary"
                      tbody-class="table-body"
                      :busy="isLoading"
                      :fields="audioTableHeader"
                      :items="audiolist"
                      no-local-sorting="false"
                      @sort-changed="sortingChanged"
                      sort-icon-left
                      id="audio-table"
                      :per-page="perPage"
                      :current-page="currentPage"
                    >
                      <!-- :sort-compare="customSortRoutine" -->
                      <!-- table busy state -->
                      <template #table-busy>
                        <div class="text-center text-muted my-3">
                          <b-spinner class="align-middle"></b-spinner>
                          <span class="text-left mx-1">Loading...</span>
                        </div>
                      </template>
                      <!-- table empty state -->
                      <template #empty="scope">
                        <b-row
                          class="d-flex flex-row align-items-center justfiy-content-center py-5"
                          no-gutters
                        >
                          <h6 class="text-center mb-0 mx-auto my-5">
                            {{ scope.emptyText }}
                          </h6>
                        </b-row>
                      </template>

                      <!-- name  -->
                      <template #cell(title)="data">
                        <span class="main-text text-dark mb-2">{{
                          data.value
                        }}</span>
                      </template>

                      <!-- name  -->
                      <template #cell(audioFile)="data">
                        <a target="_blank" :href="data.value">
                          <span class="main-text text-dark mb-2"> View </span>
                        </a>
                      </template>

                      <!-- actions column -->
                      <template #cell(enabled)="{ item }">
                        <div>
                          <b-button
                            :variant="
                              item.actions.enabled ? 'dark-green' : 'light-gray'
                            "
                            v-b-tooltip.hover.left
                            :title="`${
                              item.actions
                                ? item.actions.enabled
                                  ? 'Disable'
                                  : 'Enable'
                                : ''
                            } audio`"
                            class="shadow-sm ml-3 more-btn"
                            pill
                            @click="openEnabDisabModal(item, $event)"
                          >
                            <font-awesome-icon
                              icon="check"
                              class="more-icon"
                              size="lg"
                              :class="
                                item.actions.enabled
                                  ? 'text-dark-green'
                                  : 'text-light-gray'
                              "
                            ></font-awesome-icon>
                          </b-button>
                        </div>
                      </template>

                      <template #cell(preview)="{ item }">
                        <!-- <p>{{ item }}</p> -->
                        <div>
                          <b-button
                            title="Preview Audio"
                            class="shadow-sm border more-btn"
                            pill
                            @click="item, $event"
                          >
                            <audio
                              controls
                              :id="item.rowId"
                              class="hidden-player"
                            >
                              <source :src="item.audioFile" type="audio/mpeg" />
                            </audio>
                            <font-awesome-icon
                              :icon="
                                item.isPlaying
                                  ? ['fa', 'pause']
                                  : ['fa', 'play-circle']
                              "
                              class="play-icon center-icon"
                              @click="() => IconChange(item.rowId)"
                            />
                          </b-button>
                        </div>
                      </template>

                      <template #cell(actions)="{ item }">
                        <div>
                          <b-button
                            variant="outline-dark"
                            v-b-tooltip.hover.left
                            title="Delete Audio"
                            class="shadow-sm border border-danger more-btn"
                            pill
                            @click="openDeleteItemModal(item, $event)"
                          >
                            <font-awesome-icon
                              icon="trash"
                              aria-label="View User Details"
                              class="more-icon text-danger"
                              size="lg"
                            ></font-awesome-icon>
                          </b-button>
                        </div>
                      </template>
                    </b-table>
                  </b-row>
                  <!-- table controls -->
                  <!-- safari fix (flex-shrink-0) -->
                  <b-row
                    class="d-flex flex-row align-items-center justify-content-center justify-content-md-end flex-shrink-0 px-3 py-2 w-100"
                    no-gutters
                  >
                    <!-- pagination controls -->
                    <b-col
                      class="d-flex flex-column align-items-center align-items-sm-end justify-content-center py-2 py-md-1"
                      cols="12"
                      sm="6"
                      lg="4"
                      xl="3"
                    >
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="audiolist.length"
                        :per-page="perPage"
                        align="right"
                        size="sm"
                        limit="4"
                        pills
                        class="d-none d-sm-flex table-pagination my-0"
                        page-class="custom-page-item"
                        ellipsis-class="custom-page-item"
                        first-class="custom-page-item"
                        last-class="custom-page-item"
                        next-class="custom-page-item"
                        prev-class="custom-page-item"
                        aria-controls="audio-table"
                      ></b-pagination>
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="audiolist.length"
                        :per-page="perPage"
                        align="center"
                        size="sm"
                        limit="5"
                        pills
                        class="d-flex d-sm-none table-pagination my-0"
                        page-class="custom-page-item"
                        ellipsis-class="custom-page-item"
                        first-class="custom-page-item"
                        last-class="custom-page-item"
                        next-class="custom-page-item"
                        prev-class="custom-page-item"
                        aria-controls="audio-table"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </b-card>
              </b-row>
            </b-col>
            <!-- column divider -->

            <b-col class="px-0 px-md-2 py-3 left-column"> </b-col>
          </b-row>
        </b-card>
      </b-row>
      <EnableDisableCampaign
        :isShow="isShowEnableDisableModal"
        @onClose="onCloseEnableDisableModal"
        :itemContent="rowItem"
        :key="showEnableDisableModalKey"
        @reloadFn="callInitFn"
      />

      <VerifyModal
        :isShow="isShowEnableDisableModal"
        @onClose="onCloseVerifyModal"
        :itemContent="rowItem"
        :key="showVerifyModalKey"
        @reloadFn="callInitFn"
      />
      <DeleteCampaign
        :isShow="isShowDeleteCampaignModal"
        @onClose="onCloseDeleteItemModal"
        :itemContent="rowItem"
        :key="showDeleteCampaignModalKey"
        @reloadFn="callInitFn"
      />
    </b-container>
  </HomeLayout>
</template>

<script>
// components
import HomeLayout from "@/layout/HomeLayout";
import DeleteCampaign from "@/components/Audios//DeleteAudios";
import EnableDisableCampaign from "@/components/Audios/EnableDisableAudios";
import VerifyModal from "@/components/CampaignManagement/Modals/VerifyModal";
import FormFile from "@/components/Form/FormFile";
import FormButton from "@/components/Form/FormButton";
import FormInput from "@/components/Form/FormInput";

// services
import { GetAllAudios } from "@/services/Audios.service";
import { uploadAudio } from "@/services/Audios.service";

export default {
  name: "Users",
  components: {
    HomeLayout,
    EnableDisableCampaign,
    DeleteCampaign,
    VerifyModal,
    FormFile,
    FormButton,
    FormInput,
  },
  data() {
    return {
      isLoadingUpload: false,
      currentPage: 1,
      perPage: 10,
      playIcon: true,
      rowItem: null,
      rowIndex: null,
      isLoading: false,
      isShowEnableDisableModal: false,
      isShowDeleteCampaignModal: false,
      isShowCsvModel: false,
      isShowVerifyModal: false,
      showEnableDisableModalKey: Number(new Date()) + 100,
      showDeleteCampaignModalKey: Number(new Date()) + 110,
      showResetModalKey: Number(new Date()) + 120,
      showVerifyModalKey: Number(new Date()) + 50,
      audioTitle: null,
      audioTitleError: {
        status: null,
        message: null,
      },
      audioFile: null,
      audioFileError: {
        status: null,
        message: null,
      },
      audiolist: [],
      audioTableHeader: [
        {
          key: "title",
          label: "Title",
        },
        // {
        //   key: "audioFile",
        //   label: "Audio File",
        // },
        // {
        //   key: "name",
        //   label: "Name",
        //   sortable: true,
        // },
        {
          key: "enabled",
          label: "Status",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "preview",
          label: "Preview",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      // table pagination controls

      totalRows: 0,
      pageOptions: [5, 10, 30, 60, 100, 200],
      baseURL: process.env.VUE_APP_URL,
      testing: null,
      sortBy: "created_at",
      sortDesc: true,
    };
  },
  watch: {
    perPage() {
      this.initFn();
    },
    currentPage() {
      this.initFn();
    },
  },

  async mounted() {
    this.initFn();
    this.fetchCampaigns();
  },
  methods: {
    errorFn() {
      if (!this.audioFile) {
        this.audioFileError.status = false;
        this.audioFileError.message = "Please upload a audio file";
      }
      if (!this.audioTitle) {
        this.audioTitleError.status = false;
        this.audioTitleError.message = "Please upload a tile for audio file";
      }
    },
    mouseHover(index) {
      this.isHovered = index;
    },
    mouseLeave() {
      this.isHovered = null;
    },
    IconChange(rowId) {
      this.audiolist[rowId].isPlaying = !this.audiolist[rowId].isPlaying;
      this.toggleAudio(rowId);
    },
    toggleAudio(id) {
      var audio = document.getElementById(id);
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }
    },
    async sortingChanged(ctx) {
      console.log(ctx);
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.currentPage = 1;
      this.initFn();
    },
    async fetchCampaigns() {
      this.isLoading = true;
      try {
        const response = await GetAllAudios();
        // assuming the response data is an array of objects with "title" and "audioFile" properties
        this.campaignItems = response.data;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    async initFn() {
      console.log("initFn");
      try {
        this.isLoading = true;
        let { data } = await GetAllAudios();
        console.log(data);
        if (data.code == 200 || data.message == "success") {
          this.totalRows = data.content.lib?.length;
          this.audiolist = data.content.lib.map((x, index) => {
            return {
              id: x._id,
              rowId: index,
              actions: {
                enabled: x.enabled,
              },
              deleted: x.deleted,
              title: x.title,
              audioFile: x.src,
              date: x.created_at,
              isPlaying: false,
            };
          });
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    // function to evoke when filteration is applied
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
    },

    getUniqueId(index) {
      // minus 1 from it to start from 0
      let currentPos = this.currentPage - 1;
      // get the position by multiplying with perpage (so it will change if the per page count is changed)
      let position = currentPos * this.perPage;
      let newIndex = index + 1;
      let newPos = position + newIndex;
      return `C${String(newPos).padStart(3, "0")}`;
    },
    openEnabDisabModal(item, button) {
      console.log(item);
      // this.$store.dispatch("setSelectedItem", item);
      this.isShowEnableDisableModal = true;
      this.rowItem = item;
      this.$root.$emit(
        "bv::show::modal",
        "enable-disable-campaign-row-modal",
        button
      );
    },
    openVerifyModal(item, button) {
      console.log(item);
      // this.$store.dispatch("setSelectedItem", item);
      this.isShowVerifyModal = true;
      this.rowItem = item;
      this.$root.$emit("bv::show::modal", "verify-campaign-row-modal", button);
    },
    onCloseEnableDisableModal() {
      this.isShowEnableDisableModal = false;
    },
    onCloseVerifyModal() {
      this.isShowVerifyModal = false;
    },
    callInitFn() {
      this.showEnableDisableModalKey = Number(new Date()) + 110;
      this.showDeleteCampaignModalKey = Number(new Date()) + 100;

      this.showVerifyModalKey = Number(new Date()) + 50;

      this.initFn();
    },
    openDeleteItemModal(item, button) {
      // this.$store.dispatch("setSelectedItem", item);
      this.isShowDeleteCampaignModal = true;
      this.rowItem = item;
      // this.rowIndex = index;
      this.$root.$emit("bv::show::modal", "delete-campaign-row-modal", button);
    },
    onCloseDeleteItemModal() {
      this.isShowDeleteCampaignModal = false;
    },

    async submitForm() {
      if (!this.audioFile) {
        this.audioFileError.status = "invalid";
        this.audioFileError.message = "Please upload an audio file";
        return;
      }
      this.isLoading = true;
      try {
        const response = await uploadAudio(this.audioFile);
        console.log(response); // handle the response as needed
      } catch (error) {
        console.error(error);
        // handle the error as needed
      }
      this.isLoading = false;
    },
    getFileName(file) {
      return file.name;
    },
    async uploadAudioFn() {
      console.log("uploadingAudio", this.audioFile, this.audioTitle);

      if (!this.audioFile || !this.audioTitle) {
        this.errorFn();
        // handle the error here if either the audio file or title is missing
        return;
      }

      const formData = new FormData();
      formData.append("audio", this.audioFile);
      formData.append("title", this.audioTitle);
      this.isLoadingUpload = true;
      try {
        await uploadAudio(formData);
        this.audioTitle = null;
        this.audioFile = null;
        // this.campaignItems.push({
        //   title: this.audioTitle,
        //   audioFile: response.data.audioFile,
        // });
        this.initFn();
        this.isLoadingUpload = false;
        this.initFn();
      } catch (error) {
        console.error(error);
      }
      this.isLoadingUpload = false;
    },
  },
};
</script>

<style scoped>
.center-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.play-icon {
  width: 28px;
  height: 28px;
}

.hidden-player {
  display: none;
}
/* insights card */
.insight-cards-row {
  height: 100px;
  width: 100%;
  background-color: var(--white);
  border-radius: 0;
  border: 1px solid var(--light);
}
/* table card */
.table-card-row {
  height: 100%;
}
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 16px 16px 16px 16px;

  border: 1px solid var(--light);
}

/* filter/country/export dropdown styling */
.table-card-row .filter-dropdown,
.table-card-row .country-dropdown,
.table-card-row .export-dropdown {
  height: 50px;
  min-width: 200px;
}
/* filter/country/export dropdown menu styling */
.table-card-row .filter-dropdown >>> .filter-dropdown-menu,
.table-card-row .country-dropdown >>> .country-dropdown-menu,
.table-card-row .export-dropdown >>> .export-dropdown-menu {
  min-width: 200px;
}
/* country dropdown menu styling */
.table-card-row .country-dropdown >>> .country-dropdown-menu {
  height: auto;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
/* filter/export inner icon styling */
.table-card-row .export-dropdown >>> .export-dropdown-menu .inner-icon,
.table-card-row .filter-dropdown >>> .filter-dropdown-menu .inner-icon {
  width: 16px;
  height: 16px;
}
/* filter/country/export dropdown button styling */
.table-card-row .filter-dropdown >>> .filter-dropdown-menu button,
.table-card-row .country-dropdown >>> .country-dropdown-menu button,
.table-card-row .export-dropdown >>> .export-dropdown-menu button {
  height: 50px;
}
/* button active/selected state styling */
.table-card-row .filter-dropdown >>> .filter-dropdown-menu button.active,
.table-card-row .filter-dropdown >>> .filter-dropdown-menu button:active,
.table-card-row .country-dropdown >>> .country-dropdown-menu button.active,
.table-card-row .country-dropdown >>> .country-dropdown-menu button:active,
.table-card-row .export-dropdown >>> .export-dropdown-menu button.active,
.table-card-row .export-dropdown >>> .export-dropdown-menu button:active {
  background-color: var(--light);
}
/* country dropdown button active text styling */
.table-card-row
  .country-dropdown
  >>> .country-dropdown-menu
  button.active
  span {
  color: var(--dark);
  font-weight: bold;
}
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.table-container-row .table-element {
  min-width: 1000px;
  /* temp fix for make an inner element fill the entire table cell (https://stackoverflow.com/a/56913789) */
  display: table;
  height: 1px;
}
/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}
.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 1rem;
  font-weight: 400;
  border-color: transparent;
  font-family: "Roboto", sans-serif;
}
.table-element >>> .table-body .main-text {
  font-size: 1rem;
}
.table-element >>> .table-body .sub-text {
  font-size: 0.75rem;
}
/* hyperlink custom styling */
.table-element >>> .table-body td {
  vertical-align: middle;
  padding: 8px;
  /* temp fix for make an inner element fill the entire table cell (https://stackoverflow.com/a/56913789) */
  height: 100%;
}
.table-element >>> .table-body td > a {
  padding: 0.75rem;
  /* temp fix for make an inner element fill the entire table cell (https://stackoverflow.com/a/56913789) */
  height: 100%;
}
.table-element >>> .table-body .status-badge {
  font-size: 0.8rem;
  min-width: 95px;
}
.table-element >>> .table-body .sub-badge-text {
  font-size: 0.55rem;
}
.table-element >>> .table-body .product-img img {
  object-fit: cover;
}
.testing {
  width: 100px;
  height: 40px;
  padding: 7px;
  /* background-color: var(--white); */
  transition: background-color 200ms ease-in;
}
/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 40px;
  height: 40px;
  position: relative;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--light);
}
.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}
/* table per page selector */
.per-page-select {
  cursor: pointer;
  width: 10px;
  min-width: 60px;
}
/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}
.table-pagination >>> .custom-page-item button:hover {
  color: var(--secondary);
  border-color: var(--secondary);
  background-color: var(--light);
}
.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}
.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .table-card-row .filter-dropdown,
  .table-card-row .export-dropdown {
    min-width: auto;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .insight-cards-row {
    height: auto;
  }
}
</style>
