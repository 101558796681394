<template>
  <!-- order items modal -->
  <b-modal
    id="delete-blackList-row-modal"
    ref="deleteItemModal"
    title="Are you sure you want to delete this word?"
    size="lg"
    header-class="font-primary"
    header-bg-variant="white"
    header-text-variant="secondary"
    header-border-variant="white"
    footer-bg-variant="gray-300"
    footer-border-variant="light"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end mx-0 w-100"
      >
        <FormButton
          variant="light"
          isBlock
          class="my-2 my-sm-0 mr-0 mr-sm-2 font-weight-normal text-secondary"
          @click.native="cancel"
          >Cancel</FormButton
        >
        <FormButton
          variant="danger"
          isBlock
          :disabled="apiLoader"
          :isLoading="apiLoader"
          class="my-2 my-sm-0 ml-0 ml-sm-2 font-weight-normal text-white"
          type="button"
          @click.native="deleteFn"
          >Delete</FormButton
        >
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <font-awesome-icon
        icon="times"
        class="text-secondary"
        @click="closeModal()"
        aria-label="Close Modal"
      ></font-awesome-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100"
      no-gutters
    >
      <p class="text-left text-prime-gray mb-4">
        Are you sure you want to delete this word ? This word will be
        permanently removed. This action cannot be undone.
      </p>
    </b-row>
  </b-modal>
</template>

<script>
// services
// import { DeleteAudio } from "@/services/Audios.service";
import FormButton from "@/components/Form/FormButton";

export default {
  name: "DeleteCampaign",
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    itemContent: {
      default: null,
    },
    apiLoader: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    FormButton,
  },
  methods: {
    async deleteFn() {
      this.$emit("deleteBlackList", this.itemContent);
    },
    closeModal() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
