import Instance from "./instance";

// Hero Body/Image APIs
export const SaveHeroBody = (payload) => {
  return Instance.instanceToken.post("/content/heroBody", payload);
};

export const GetHeroBody = () => {
  return Instance.instanceToken.get("/content/heroBody");
};

export const SaveHeroImage = (payload) => {
  return Instance.instanceFileUpload.post("/content/heroImage", payload);
};

// export const GetHeroImage = () => {
//   return Instance.instanceToken.get("/content/heroImage");
// };

// What We Do APIs
export const SaveWhatWeDoNext = (payload) => {
  return Instance.instanceToken.post("/content/whatWeDoNext", payload);
};

export const GetWhatWeDoNext = () => {
  return Instance.instanceToken.get("/content/whatWeDoNext");
}

// Demo Video APIs
export const SaveDemoVideo = (payload) => {
  return Instance.instanceToken.post("/content/demoVideo", payload);
};

export const GetDemoVideo = () => {
  return Instance.instanceToken.get("/content/demoVideo");
}

export const SaveDemoVideoPreview  = (payload) => {
  return Instance.instanceFileUpload.post("/content/demoVideoPreview", payload);
};

// export const GetDemoVideoPreview = () => {
//   return Instance.instanceToken.get("/content/demoVideoPreview");
// }

// About APIs
export const SaveAbout = (payload) => {
  return Instance.instanceToken.post("/content/about", payload);
};

export const GetAbout = () => {
  return Instance.instanceToken.get("/content/about");
};


// FAQ APIs
export const CreateFAQS = (payload) => {
  return Instance.instanceToken.put("/content/faq", payload);
};

export const GetAllFAQS = () => {
  return Instance.instanceToken.get("/content/faq");
};

export const UpdateManyFAQS = (payload) => {
  return Instance.instanceToken.put("/content/faq", payload);
};

export const DeleteManyFAQS = (payload) => {
  return Instance.instanceToken.delete("/content/faq", {
    data: payload,
  });
};

// info APIs
export const GetAllInfoS = () => {
  return Instance.instanceToken.get("/content/gs");
};

export const GetSingleInfo = () => {
  return Instance.instanceToken.get("/content/gs");
};

export const UpdateInfo = (id , payload) => {
  return Instance.instanceToken.put(`/content/gs/${id}`, payload);
};

export const DeleteManyInfoS = (id) => {
  return Instance.instanceToken.delete(`/content/gs/${id}`);
};

export const CreateInfoS = (payload) => {
  return Instance.instanceToken.post("/content/gs", payload);
};

// Disclaimer APIs
export const SaveDisclaimer = (payload) => {
  return Instance.instanceToken.post("/content/disclaimer", payload);
};

export const GetDisclaimer = () => {
  return Instance.instanceToken.get("/content/disclaimer");
};

export const SaveRegistrationDisclaimer = (payload) => {
  return Instance.instanceToken.post("/content/disclaimer/register", payload);
};

export const GetRegistrationDisclaimer = () => {
  return Instance.instanceToken.get("/content/disclaimer/register");
};

// Privacy APIs
export const SavePrivacy = (payload) => {
  return Instance.instanceToken.post("/content/privacy", payload);
};

export const GetPrivacy = () => {
  return Instance.instanceToken.get("/content/privacy");
};

// Terms APIs
export const SaveTerms = (payload) => {
  return Instance.instanceToken.post("/content/terms", payload);
};

export const GetTerms = () => {
  return Instance.instanceToken.get("/content/terms");
};

// renewal apis
export const CreateVideoText = (payload) => {
  return Instance.instanceToken.post("/content/emailContent",payload);
};
export const GetVideoText = () => {
  return Instance.instanceToken.get("/content/emailContent");
};