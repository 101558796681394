<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- card container row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          w-100
          card-row
        "
        no-gutters
      >
        <b-card
          no-body
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            card-container
          "
        >
          <b-row class="px-3 py-0 body-container" no-gutters>
            <!-- left column -->
            <b-col class="px-0 px-md-2 py-3 left-column">
              <!-- admin details row -->
              <h6
                class="
                  text-left text-dark
                  font-weight-semi-bold font-primary
                  mb-2
                  mt-3
                "
              >
                Account details
              </h6>
              <!-- admin name -->
              <div class="inner-row-grid py-2 w-100">
                <span class="text-left text-prime-gray font-secondary">
                  Name
                </span>
                <span class="text-left text-dark font-secondary">
                  {{ accountDetails.adminName }}
                </span>
              </div>
              <!-- admin email -->
              <div class="inner-row-grid py-2 w-100">
                <span class="text-left text-prime-gray font-secondary">
                  Email
                </span>
                <span class="text-left text-dark font-secondary">
                  {{ accountDetails.adminEmail }}
                </span>
              </div>
              <!-- admin joined date -->
              <div class="inner-row-grid py-2 w-100">
                <span class="text-left text-prime-gray font-secondary">
                  Joined Date
                </span>
                <span class="text-left text-dark font-secondary">
                  {{
                    accountDetails.createdAt
                      ? accountDetails.createdAt.split("T")[0]
                      : "06/08/2021"
                  }}
                </span>
              </div>
              <!-- admin type -->
              <div class="inner-row-grid py-2 w-100">
                <span class="text-left text-prime-gray font-secondary">
                  Type
                </span>
                <template v-if="accountDetails.adminType == 'Administrator'">
                  <span
                    class="
                      text-center
                      font-secondary font-weight-normal
                      text-primary text-small
                      bg-light
                      px-2
                      py-1
                      rounded-lg
                      admin-type-badge
                    "
                  >
                    {{ accountDetails.adminType }}
                  </span>
                </template>
                <template v-else>
                  <span
                    class="
                      text-center
                      font-secondary font-weight-normal
                      text-warning text-small
                      bg-light
                      px-2
                      py-1
                      rounded-lg
                      admin-type-badge
                    "
                  >
                    {{ accountDetails.adminType }}
                  </span>
                </template>
              </div>
            </b-col>
            <!-- column divider -->
            <hr class="column-divider align-self-center" />
            <!-- right column -->
            <b-col class="px-0 px-md-2 py-3 right-column">
              <!-- Password Settings -->
              <h6
                class="
                  text-left text-dark
                  font-weight-semi-bold font-primary
                  mb-4
                  mt-3
                "
              >
                Password Settings
              </h6>
              <!-- Password Settings form -->
              <form
                id="reset-password-form"
                class="
                  d-flex
                  flex-column
                  align-items-start
                  justify-content-start
                  w-100
                "
              >
                <!-- full row input (Password) -->
                <b-row
                  class="
                    rounded=0
                    d-flex
                    flex-row
                    align-items-start
                    justify-content-start
                    w-100
                  "
                  no-gutters
                >
                  <b-col cols="12">
                    <FormInputGroup
                      groupId="password-group"
                      id="password-group-input"
                      v-model="oldPassword"
                      label="Old Password"
                      placeholder="Minimum 6 characters"
                      isRequired
                      :state="oldPasswordError.status"
                      :invalidFeedback="`${oldPasswordError.message}`"
                      :type="oldPasswordShow ? 'text' : 'password'"
                      form="reset-password-form"
                      isAppend
                    >
                      <template v-slot:append>
                        <b-button
                          class="
                            px-3 px-md-4
                            icon-btn
                            border border-gray
                            overflow-hidden
                            shadow-sm
                          "
                          variant="transparent"
                          @click="oldPasswordShow = !oldPasswordShow"
                        >
                          <font-awesome-icon
                            class="text-prime-gray"
                            :icon="oldPasswordShow ? 'eye-slash' : 'eye'"
                            aria-label="Help"
                          ></font-awesome-icon>
                        </b-button>
                      </template>
                    </FormInputGroup>
                  </b-col>
                </b-row>
                <!-- full row input (Password) -->
                <b-row
                  class="
                    d-flex
                    flex-row
                    align-items-start
                    justify-content-start
                    w-100
                  "
                  no-gutters
                >
                  <b-col cols="12">
                    <FormInputGroup
                      groupId="password-group"
                      id="password-group-input"
                      v-model="newPassword"
                      label="New Password"
                      placeholder="Minimum 6 characters"
                      isRequired
                      :state="newPasswordError.status"
                      :invalidFeedback="`${newPasswordError.message}`"
                      :type="newPasswordShow ? 'text' : 'password'"
                      form="reset-password-form"
                      isAppend
                    >
                      <template v-slot:append>
                        <b-button
                          class="
                            px-3 px-md-4
                            border border-gray
                            overflow-hidden
                            shadow-sm
                          "
                          variant="transparent"
                          @click="newPasswordShow = !newPasswordShow"
                        >
                          <font-awesome-icon
                            class="text-prime-gray"
                            :icon="newPasswordShow ? 'eye-slash' : 'eye'"
                            aria-label="Help"
                          ></font-awesome-icon>
                        </b-button>
                      </template>
                    </FormInputGroup>
                  </b-col>
                </b-row>
                <!-- full row input (Password) -->
                <b-row
                  class="
                    d-flex
                    flex-row
                    align-items-start
                    justify-content-start
                    w-100
                  "
                  no-gutters
                >
                  <b-col cols="12">
                    <FormInputGroup
                      groupId="password-group"
                      id="password-group-input"
                      v-model="confirmNewPassword"
                      label="Confirm New Password"
                      placeholder="Minimum 6 characters"
                      isRequired
                      :state="confirmNewPasswordError.status"
                      :invalidFeedback="`${confirmNewPasswordError.message}`"
                      :type="confirmNewPasswordShow ? 'text' : 'password'"
                      form="reset-password-form"
                      isAppend
                    >
                      <template v-slot:append>
                        <b-button
                          class="
                            px-3 px-md-4
                            border border-gray
                            overflow-hidden
                            shadow-sm
                          "
                          variant="transparent"
                          @click="
                            confirmNewPasswordShow = !confirmNewPasswordShow
                          "
                        >
                          <font-awesome-icon
                            class="text-prime-gray"
                            :icon="confirmNewPasswordShow ? 'eye-slash' : 'eye'"
                            aria-label="Help"
                          ></font-awesome-icon>
                        </b-button>
                      </template>
                    </FormInputGroup>
                  </b-col>
                </b-row>
                <!-- form action -->
                <b-row
                  class="
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-center
                    w-100
                  "
                  no-gutters
                >
                  <!-- reset password button -->
                  <b-col
                    class="
                      d-flex
                      flex-column
                      align-items-center
                      justify-content-center
                      px-0
                      py-2
                    "
                    cols="9"
                    sm="6"
                  >
                    <FormButton
                      variant="primary"
                      isBlock
                      class="font-secondary font-weight-normal text-white mt-2"
                      type="submit"
                      :isLoading="apiLoading"
                      @click.native="resetPwdFn"
                      >Reset Password</FormButton
                    >
                  </b-col>
                </b-row>
              </form>
            </b-col>
          </b-row>
          <b-row
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-center
              px-3
              pt-2 pt-sm-0
              pb-1 pb-sm-2
            "
            no-gutters
          >
            <b-col
              cols="12"
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
              "
            >
              <!--<b-img
                class="footer-img"
                src="@/assets/logo_footer_black_vault.png"
                contain
              ></b-img> -->
              <h5 class="version-number mb-0 text-muted text-center w-100">
                {{ this.version }}
              </h5>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>

<script>
// components
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";

// services
import { ChangePassword } from "@/services/admin.service";

export default {
  name: "Settings",
  components: {
    HomeLayout,
    FormInputGroup,
    FormButton,
  },
  data() {
    return {
      accountDetails: {
        firstName: "john",
        email: "john@example.com",
        adminType: "Administrator",
      },
      adminId: null,
      isReset: false,
      oldPassword: null,
      oldPasswordShow: false,
      newPassword: null,
      newPasswordShow: false,
      confirmNewPassword: null,
      confirmNewPasswordShow: false,
      apiLoading: false,
      oldPasswordError: {
        status: null,
        message: "",
      },
      newPasswordError: {
        status: null,
        message: "",
      },
      confirmNewPasswordError: {
        status: null,
        message: "",
      },
      // table pagination controls
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100, 500],
      totalRows: 500,
    };
  },

  mounted() {
    // this.initFn();
    this.adminId = JSON.parse(localStorage.getItem("adminDetails")).adminId;
    if (localStorage.getItem("adminDetails")) {
      this.accountDetails = JSON.parse(localStorage.getItem("adminDetails"));
    }
  },
  watch: {
    oldPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.oldPasswordError.status = false;
            this.oldPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            this.oldPasswordError.status = true;
          }
        } else {
          this.oldPasswordError.status = false;
          this.oldPasswordError.message = "Please fill in your old password";
        }
      }
    },
    newPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.newPasswordError.status = false;
            this.newPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            this.newPasswordError.status = true;
          }
        } else {
          this.newPasswordError.status = false;
          this.newPasswordError.message = "Please fill in your new password";
        }
      }
    },
    confirmNewPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.confirmNewPasswordError.status = false;
            this.confirmNewPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            if (val == this.newPassword) {
              this.confirmNewPasswordError.status = true;
            } else {
              this.confirmNewPasswordError.status = false;
              this.confirmNewPasswordError.message = "Passwords do not match";
            }
          }
        } else {
          this.confirmNewPasswordError.status = false;
          this.confirmNewPasswordError.message =
            "Please fill in your new password again";
        }
      }
    },
  },
  methods: {
    async resetPwdFn() {
      let errorList = this.errorFn();
      if (errorList.length == 0) {
        let payload = {
          adminId: this.adminId,
          oldPassword: this.oldPassword,
          password: this.newPassword,
        };
        try {
          this.apiLoading = true;
          let { data } = await ChangePassword(payload);
          if (data.message == "success") {
            let payloadNotify = {
              isToast: true,
              title: "Password Change",
              content:
                "Your password has been changed successfully, you will be redirected to the login page shortly.",
              variant: "success",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.resetFn();

            // clear storage and route to login after 3 seconds
            setTimeout(() => {
              localStorage.removeItem("adminDetails");
              this.$router.push({
                name: "Login",
              });
            }, 3000);
          } else {
            let payloadNotify = {
              isToast: true,
              title: "ERROR! Password Reset",
              content: data.details,
              variant: "danger",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.apiLoading = false;
        }
      } else {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Password Reset",
          list: errorList,
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    errorFn() {
      var errorList = [];

      if (!this.oldPassword) {
        this.oldPasswordError.status = false;
        this.oldPasswordError.message = "Please fill in your old password";
        errorList.push("Please fill in your old password");
      } else if (
        (!this.oldPassword && this.oldPassword.length < 6) ||
        (this.oldPassword &&
          this.oldPassword.length > 0 &&
          this.oldPassword.length < 6)
      ) {
        this.oldPasswordError.status = false;
        this.oldPasswordError.message =
          "A minimum length of 6 characters needed";
        errorList.push("A minimum length of 6 characters needed");
      }
      if (!this.newPassword) {
        this.newPasswordError.status = false;
        this.newPasswordError.message = "Please fill in your new password";
        errorList.push("Please fill in your new password");
      } else if (
        (!this.newPassword && this.newPassword.length < 6) ||
        (this.newPassword &&
          this.newPassword.length > 0 &&
          this.newPassword.length < 6)
      ) {
        this.newPasswordError.status = false;
        this.newPasswordError.message =
          "A minimum length of 6 characters is required";
        errorList.push("A minimum length of 6 characters needed");
      }
      if (!this.confirmNewPassword) {
        this.confirmNewPasswordError.status = false;
        this.confirmNewPasswordError.message =
          "Please fill in your new password again";
        errorList.push("Please fill in your new password again");
      } else if (this.confirmNewPassword != this.newPassword) {
        this.confirmNewPasswordError.status = false;
        this.confirmNewPasswordError.message = "Passwords do not match";
        errorList.push("Passwords do not match");
      }

      return errorList;
    },
    resetFn() {
      this.isReset = true;
      this.oldPassword = null;
      this.newPassword = null;
      this.confirmNewPassword = null;
    },
  },
};
</script>

<style scoped>
/* table card */
.card-row {
  height: 100%;
}

.card-row .card-container {
  min-height: 560px;
  border-radius: 16px 16px 16px 16px;
}

.body-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  height: 100%;
}

.body-container .left-column .inner-row-grid {
  display: grid;
  grid-template-columns: 25% 70%;
  grid-gap: 5%;
}

.body-container .left-column .inner-row-grid .admin-type-badge {
  width: max-content;
}

.body-container .column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}

.footer-img {
  max-width: 150px;
}

.version-number {
  font-size: 0.7rem;
  line-height: 20px;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .body-container {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }

  .body-container .column-divider {
    width: 100%;
    height: 0;
    border-right: none;
    border-top: 1px solid var(--light);
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .body-container .left-column .inner-row-grid {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
}
</style>
