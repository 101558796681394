<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          w-100
          card-row
        "
        no-gutters
      >
        <b-overlay
          :show="isAPILoading"
          rounded="sm"
          no-wrap
          variant="light"
          :opacity="0.8"
          blur="2px"
        ></b-overlay>
        <b-card no-body class="d-flex flex-column w-100 card-scroll">
          <b-row
            class="
              d-flex
              flex-row
              align-items-start
              justify-content-start
              px-3
              py-0
              card-body-container
            "
            no-gutters
          >
            <!-- left column -->
            <b-col class="d-flex flex-column px-md-2 py-3 left-column">
              <!-- Campaign details section -->
              <div class="user-details-section">
                <h5 class="inner-heading py-3">Charity Details</h5>
                <!-- Campaign Website URL -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Charity Website URL :
                  </span>
                  <span
                    class="textpadding text-dark font-secondary maxWidthtext"
                  >
                    {{ campaignUrl ? campaignUrl : "N/A" }}
                  </span>
                </div>
                <!-- Campaign name -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Charity Name :
                  </span>
                  <span
                    class="textpadding text-dark font-secondary maxWidthtext"
                  >
                    {{
                      userDetails && userDetails.campName
                        ? userDetails.campName
                        : "N/A"
                    }}
                  </span>
                </div>
                <!-- Treasurer’s Full Name -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Treasurer’s Full Name :
                  </span>
                  <span
                    class="textpadding text-dark font-secondary maxWidthtext"
                  >
                    {{
                      userDetails && userDetails.ownerFirstName
                        ? userDetails.ownerFirstName
                        : "N/A"
                    }}
                  </span>
                </div>
                <!-- Treasurer’s email -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Treasurer’s Email :
                  </span>
                  <b-row
                    class="
                      d-flex
                      flex-row
                      align-items-center
                      justify-content-start
                    "
                    no-gutters
                  >
                    <span
                      class="textpadding text-dark font-secondary maxWidthtext"
                    >
                      {{
                        userDetails && userDetails.email
                          ? userDetails.email
                          : "N/A"
                      }}
                    </span>
                  </b-row>
                </div>
                <!--Treasurer’s contact -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Treasurer’s Contact No. :
                  </span>
                  <span
                    class="textpadding text-dark font-secondary maxWidthtext"
                  >
                    {{
                      userDetails && userDetails.contactNo
                        ? userDetails.contactNo
                        : "N/A"
                    }}
                  </span>
                </div>
                <!-- user joined date -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Joined Date :
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{
                      userDetails && userDetails.joinedDate
                        ? userDetails.joinedDate
                        : "N/A"
                    }}
                  </span>
                </div>
                <!-- Renewal -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Subscription Renewal Date :
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{ renewalDate ? renewalDate : "N/A" }}
                  </span>
                </div>
                <!-- Setup Fees  -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Setup Fees :
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{ setupFees == true ? "Paid" : "Not paid" }}
                  </span>
                </div>
                <!-- Candidate Type -->
                <!-- <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Candidate Type :
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{
                      candidateType == "federalCandidate"
                        ? "Federal"
                        : candidateType == "federalPAC"
                        ? "Federal PAC"
                        : candidateType == "candidateBar"
                        ? "State/Local Candidate bar"
                        : "N/A"
                    }}
                  </span>
                </div> -->
                <!-- Campaign pac name -->
                <!-- <div
                  class="inner-row-grid py-3 w-100"
                  v-if="candidateType == 'federalPAC'"
                >
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    PAC Name:
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{ PACName ? PACName : "N/A" }}
                  </span>
                </div> -->
                <!-- Campaign user name -->
                <!-- <div
                  class="inner-row-grid py-3 w-100"
                  v-if="
                    candidateType == 'federalCandidate' ||
                    candidateType == 'candidateBar'
                  "
                >
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Candidate Name:
                  </span>
                  <span
                    class="textpadding text-dark font-secondary maxWidthtext"
                  >
                    {{ candidateName ? candidateName : "N/A" }}
                  </span>
                </div> -->
                <!-- Campaign name of the race -->
                <!-- <div
                  class="inner-row-grid py-3 w-100"
                  v-if="candidateType == 'candidateBar'"
                >
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Name of The Race
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{ nameOfTheRace ? nameOfTheRace : "N/A" }}
                  </span>
                </div> -->
                <!-- Party Type -->
                <!-- <div
                  class="inner-row-grid py-3 w-100"
                  v-if="
                    candidateType == 'federalCandidate' ||
                    candidateType == 'candidateBar'
                  "
                >
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Party Type :
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{
                      partyType == "republican"
                        ? "Republican"
                        : partyType == "democrat"
                        ? "Democrat"
                        : partyType == "other"
                        ? "Other"
                        : "N/A"
                    }}
                  </span>
                </div> -->
                <!-- Campaign specify party -->
                <!-- <div
                  class="inner-row-grid py-3 w-100"
                  v-if="
                    candidateType == 'federalCandidate' ||
                    candidateType == 'candidateBar'
                  "
                >
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Specify Party:
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{ specifyParty ? specifyParty : "N/A" }}
                  </span>
                </div> -->
                <!-- Campaign race type -->
                <!-- <div
                  class="inner-row-grid py-3 w-100"
                  v-if="candidateType == 'federalCandidate'"
                >
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Race Information:
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{ raceType ? raceType : "N/A" }}
                  </span>
                </div> -->

                <!-- State -->
                <!-- <div
                  class="inner-row-grid py-3 w-100"
                  v-if="
                    candidateType == 'federalCandidate' && raceType == 'house'
                  "
                >
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    State :
                  </span>
                  <span
                    class="textpadding text-dark font-secondary maxWidthtext"
                  >
                    {{ state ? state : "N/A" }}
                  </span>
                </div> -->
                <!-- District No -->
                <!-- <div
                  class="inner-row-grid py-3 w-100"
                  v-if="
                    (candidateType == 'federalCandidate') &
                    (raceType != 'presidential')
                  "
                >
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    District No. :
                  </span>
                  <span
                    class="textpadding text-dark font-secondary maxWidthtext"
                  >
                    {{ districtNo }}
                  </span>
                </div> -->
                <!--Election Type -->
                <!-- <div
                  class="inner-row-grid py-3 w-100"
                  v-if="
                    candidateType == 'federalCandidate' ||
                    candidateType == 'candidateBar'
                  "
                >
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Election Type. :
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{
                      electionType == "primary"
                        ? "Primary"
                        : electionType == "general"
                        ? "General"
                        : "N/A"
                    }}
                  </span>
                </div> -->
                <!-- Date of Election
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Date of Election :
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{ dateOfElection ? dateOfElection : "N/A" }}
                  </span>
                </div> -->
                <!--FID  Federal Tax ID -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                   Federal Tax ID :
                  </span>
                  <span
                    class="textpadding text-dark font-secondary maxWidthtext"
                  >
                  {{
                      userDetails && userDetails.federalTaxId
                        ? userDetails.federalTaxId
                        : "N/A"
                    }}
                  </span>
                </div>
                <!--Committee Tax ID No -->
                <!-- <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Committee Tax ID No. :
                  </span>
                  <span
                    class="textpadding text-dark font-secondary maxWidthtext"
                  >
                    {{ taxId ? taxId : "N/A" }}
                  </span>
                </div> -->
                <!--Campaign Maling Address -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Charity Physical Address. :
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{ address ? address : "N/A" }}
                  </span>
                </div>
                <!--Campaign Declaration Name -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Declaration Name :
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{
                      userDetails && userDetails.declarationName
                        ? userDetails.declarationName
                        : "N/A"
                    }} 
                  </span>
                </div>


                <!--Campaign Maling Address -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                   State Identification No :
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{
                      userDetails && userDetails.stateidentificationNo
                        ? userDetails.stateidentificationNo
                        : "N/A"
                    }}
                    
                  </span>
                </div>



                <!-- Campaign Donation Page URL -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Donation Page :
                  </span>
                  <span
                    class="textpadding text-dark font-secondary maxWidthtext"
                  >
                    <a target="_blank" :href="campaignDonationPage">{{
                      campaignDonationPage
                    }}</a>
                  </span>
                </div>
                <!-- contact
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Contact No :
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{
                      userDetails && userDetails.contactNo
                        ? userDetails.contactNo
                        : "N/A"
                    }}
                  </span>
                </div> -->

                <!-- contact
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary textWidth"
                  >
                    Payment Details :
                  </span>
                  <span class="textpadding text-dark font-secondary">
                    {{ pDetails }} 
                  </span>
                </div> -->
              </div>
            </b-col>
            <!-- column divider -->
            <div class="column-divider align-self-center" />
            <!-- right column -->
            <b-col class="px-0 px-md-2 py-3 right-column">
              <!-- right column heading -->
              <h6 class="inner-heading py-3">Contact Section</h6>
              <!-- video-container-row -->
              <b-row class="px-0 slides-card-scroll">
                <b-col cols="12" class="pr-lg-3">
                  <FormInput
                    groupId="contact-title-group"
                    id="contact-title-group-input"
                    class="text-prime-gray font-secondary"
                    :state="emailError.status"
                    :invalidFeedback="`${emailError.message}`"
                    label="Email"
                    v-model="email"
                    isRequired
                    trim
                    form="contact-form"
                    type="text"
                  >
                  </FormInput>
                </b-col>
              </b-row>
              <b-row class="px-0 slides-card-scroll">
                <b-col cols="12">
                  <FormInput
                    groupId="contact-title-group"
                    id="contact-title-group-input"
                    class="text-prime-gray font-secondary"
                    :state="phoneError.status"
                    :invalidFeedback="`${phoneError.message}`"
                    label="Phone "
                    v-model="phone"
                    isRequired
                    trim
                    form="contact-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <b-row class="px-0 slides-card-scroll">
                <b-col cols="12" class="pr-lg-3">
                  <FormInput
                    groupId="contact-title-group"
                    id="contact-title-group-input"
                    class="text-prime-gray font-secondary"
                    :state="addressError.status"
                    :invalidFeedback="`${addressError.message}`"
                    label="Address"
                    v-model="address"
                    isRequired
                    trim
                    form="contact-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <b-row class="px-0 slides-card-scroll">
                <b-col cols="12" class="pr-lg-3">
                  <FormInput
                    groupId="contact-title-group"
                    id="contact-title-group-input"
                    class="text-prime-gray font-secondary"
                    :state="campaignUrlError.status"
                    :invalidFeedback="`${campaignUrlError.message}`"
                    label="Website URL"
                    v-model="campaignUrl"
                    isRequired
                    trim
                    form="contact-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <b-row class="px-0 slides-card-scroll mt-2">
                <b-col cols="12" class="pr-lg-3">
                  <b-row>
                    <b-col
                      cols="2"
                      lg="1"
                      md="1"
                      class="pr-lg-2 justify-content-center py-2"
                    >
                      <b-img
                        src="@/assets/SocialMedia/LinkedIn.svg"
                        class="status-icon"
                        alt="edit-icon"
                      ></b-img>
                    </b-col>
                    <b-col cols="10" lg="11" md="11">
                      <FormInputGroup
                        groupId="contact-title-group"
                        id="contact-title-group-input"
                        class="text-prime-gray font-secondary"
                        :state="linkedInError.status"
                        :invalidFeedback="`${linkedInError.message}`"
                        label="LinkedIn"
                        v-model="linkedIn"
                        isRequired
                        trim
                        form="contact-form"
                        type="text"
                        isAppend
                      >
                        <template v-slot:append>
                          <b-input-group-text class="bg-white">
                            <b-icon
                              variant="light-gray"
                              icon="link45deg"
                              class="px-0 w-auto edit-icon"
                            ></b-icon
                          ></b-input-group-text> </template
                      ></FormInputGroup>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="px-0 slides-card-scroll mt-2">
                <b-col cols="12" class="pr-lg-3">
                  <b-row>
                    <b-col
                      cols="2"
                      lg="1"
                      md="1"
                      class="pr-lg-2 justify-content-center py-2"
                    >
                      <b-img
                        src="@/assets/SocialMedia/Twitter.svg"
                        class="status-icon"
                        alt="edit-icon"
                      ></b-img>
                    </b-col>
                    <b-col cols="10" lg="11" md="11">
                      <FormInputGroup
                        groupId="contact-title-group"
                        id="contact-title-group-input"
                        class="text-prime-gray font-secondary"
                        :state="twitterError.status"
                        :invalidFeedback="`${twitterError.message}`"
                        label="Twitter"
                        v-model="twitter"
                        isRequired
                        trim
                        form="contact-form"
                        type="text"
                        isAppend
                      >
                        <template v-slot:append>
                          <b-input-group-text class="bg-white">
                            <b-icon
                              variant="light-gray"
                              icon="link45deg"
                              class="px-0 w-auto edit-icon"
                            ></b-icon
                          ></b-input-group-text> </template
                      ></FormInputGroup>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="px-0 slides-card-scroll mt-2">
                <b-col cols="12" class="pr-lg-3">
                  <b-row>
                    <b-col
                      cols="2"
                      lg="1"
                      md="1"
                      class="pr-lg-2 justify-content-center py-2"
                    >
                      <b-img
                        src="@/assets/SocialMedia/Instagram.svg"
                        class="status-icon"
                        alt="edit-icon"
                      ></b-img>
                    </b-col>
                    <b-col cols="10" lg="11" md="11">
                      <FormInputGroup
                        groupId="contact-title-group"
                        id="contact-title-group-input"
                        class="text-prime-gray font-secondary"
                        :state="instagramError.status"
                        :invalidFeedback="`${instagramError.message}`"
                        label="Instagram"
                        v-model="instagram"
                        isRequired
                        trim
                        form="contact-form"
                        type="text"
                        isAppend
                      >
                        <template v-slot:append>
                          <b-input-group-text class="bg-white">
                            <b-icon
                              variant="light-gray"
                              icon="link45deg"
                              class="px-0 w-auto edit-icon"
                            ></b-icon
                          ></b-input-group-text> </template
                      ></FormInputGroup>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="px-0 slides-card-scroll mt-2">
                <b-col cols="12" class="pr-lg-3">
                  <b-row>
                    <b-col
                      cols="2"
                      lg="1"
                      md="1"
                      class="pr-lg-2 justify-content-center py-2"
                    >
                      <b-img
                        src="@/assets/SocialMedia/Facebook.svg"
                        class="status-icon"
                        alt="edit-icon"
                      ></b-img>
                    </b-col>
                    <b-col cols="10" lg="11" md="11">
                      <FormInputGroup
                        groupId="contact-title-group"
                        id="contact-title-group-input"
                        class="text-prime-gray font-secondary"
                        :state="facebookError.status"
                        :invalidFeedback="`${facebookError.message}`"
                        label="Facebook"
                        v-model="facebook"
                        isRequired
                        trim
                        form="contact-form"
                        type="text"
                        isAppend
                      >
                        <template v-slot:append>
                          <b-input-group-text class="bg-white">
                            <b-icon
                              variant="light-gray"
                              icon="link45deg"
                              class="px-0 w-auto edit-icon"
                            ></b-icon
                          ></b-input-group-text> </template
                      ></FormInputGroup>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="px-0 slides-card-scroll mt-2">
                <b-col cols="12" class="pr-lg-3">
                  <b-row>
                    <b-col
                      cols="2"
                      lg="1"
                      md="1"
                      class="pr-lg-2 justify-content-center py-2"
                    >
                      <b-img
                        src="@/assets/SocialMedia/Youtube.svg"
                        class="status-icon"
                        alt="edit-icon"
                      ></b-img>
                    </b-col>
                    <b-col cols="10" lg="11" md="11">
                      <FormInputGroup
                        groupId="contact-title-group"
                        id="contact-title-group-input"
                        class="text-prime-gray font-secondary"
                        :state="youTubeError.status"
                        :invalidFeedback="`${youTubeError.message}`"
                        label="YouTube"
                        v-model="youTube"
                        isRequired
                        trim
                        form="contact-form"
                        type="text"
                        isAppend
                      >
                        <template v-slot:append>
                          <b-input-group-text class="bg-white">
                            <b-icon
                              variant="light-gray"
                              icon="link45deg"
                              class="px-0 w-auto edit-icon"
                            ></b-icon
                          ></b-input-group-text> </template
                      ></FormInputGroup>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <hr />
              <b-row>
                <h6 class="inner-heading py-2 pl-3">About Section</h6>
              </b-row>
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                  py-2
                "
                no-gutters
              >
                <b-col cols="12">
                  <!-- text editor -->
                  <vue-editor
                    class="editor mb-4 font-secondary"
                    v-model="aboutText"
                    :editor-toolbar="customToolbar"
                    placeholder="Notes"
                  >
                  </vue-editor>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <h6 class="inner-heading py-2 pl-3">Additional Comments</h6>
              </b-row>
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                "
                no-gutters
              >
                <b-col cols="12">
                  <FormInput
                    groupId="legal-text-group"
                    id="legal-text-group-input"
                    class="text-prime-gray font-secondary"
                    :state="notesTextError.status"
                    :invalidFeedback="`${notesTextError.message}`"
                    label="Notes"
                    v-model="notes"
                    isRequired
                    isTextArea
                    trim
                    :counter="300"
                    form="slide-configuration-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- form action -->
              <b-row
                class="
                  d-flex
                  flex-column-reverse flex-md-row
                  align-items-center
                  justify-content-end
                  mt-3
                  w-100
                "
                no-gutters
              >
                <!-- save and exit button -->
                <b-col
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                  "
                  cols="12"
                  md="4"
                  lg="6"
                >
                  <FormButton
                    @click.native="resetForm"
                    variant="light"
                    isBlock
                    class="font-weight-bold text-secondary"
                    type="reset"
                    :isLoading="isResetLoading"
                    >Reset</FormButton
                  >
                </b-col>
                <!-- cancel button -->
                <b-col
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    pr-0 pr-md-2
                    py-2
                    mt-3 mt-lg-0
                  "
                  cols="12"
                  md="4"
                  lg="4"
                >
                  <FormButton
                    id="save-question-btn"
                    variant="primary"
                    isBlock
                    :isLoading="isLoading"
                    class="font-weight-normal text-white"
                    type="submit"
                    @click.native="saveData"
                    >Save</FormButton
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";
import FormInput from "@/components/Form/FormInput";

// services
import {
  GetSingleUser,
  UpdateUser,
} from "@/services/campaignManagement.service";

import CheckEmail from "@/util/CheckEmail";

export default {
  name: "CharityProfile",
  components: {
    HomeLayout,
    FormInputGroup,
    FormButton,
    FormInput,
  },
  data() {
    return {
      isAPILoading: true,
      isLoading: false,
      modalShow: false,
      previewVideoItem: null,
      isShowPreviewModal: false,
      userDetails: null,
      targetVideo: null,
      videoTitle: null,
      videoLink: null,
      video: null,
      campaignUrl: null,
      campaignDonationPage: null,
      campaignUrlError: {
        status: null,
        message: null,
      },
      webURL: process.env.VUE_APP_WEB_URL,
      email: null,
      emailError: {
        status: null,
        message: "",
      },
      phone: null,
      phoneError: {
        status: null,
        message: "",
      },
      address: null,
      addressError: {
        status: null,
        message: "",
      },
      linkedIn: null,
      linkedInError: {
        status: null,
        message: "",
      },
      twitter: null,
      twitterError: {
        status: null,
        message: "",
      },
      instagram: null,
      instagramError: {
        status: null,
        message: "",
      },
      facebook: null,
      facebookError: {
        status: null,
        message: "",
      },
      youTube: null,
      youTubeError: {
        status: null,
        message: "",
      },
      aboutText: null,
      aboutTextError: {
        status: null,
        message: null,
      },
      notes: null,
      notesTextError: {
        status: null,
        message: null,
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
      renewalDate: null,
      setupFees: null,
      // candidateType: null,
      candidateName: null,
      partyType: null,
      state: null,
      districtNo: null,
      electionType: null,
      // dateOfElection: null,
      identificationNo: null,
      taxId: null,
      mailingAddress: null,
      declarationName: null,
      specifyPatry: null,
      raceType: null,
      PACName: null,
      nameOfTheRace: null,
      isReset: false,
      isResetLoading: false,
    };
  },
  async mounted() {
    await this.initFn(this.$route.params.userId);
  },
  computed: {
    getVideoPreviewLink() {
      return `${this.webURL}/preview/${this.userDetails.userId}`;
    },
    getVideoDownloadLink() {
      return `${this.webURL}/download/${this.userDetails.userId}`;
    },
  },
  watch: {
    email(val) {
      if (val) {
        if (CheckEmail(val) == true) this.emailError.status = true;
        else {
          this.emailError.status = false;
          this.emailError.message = "Please fill in a valid email address";
        }
      }
    },
    phone(val) {
      if (!this.isReset) {
        if (val) {
          this.phoneError.status = true;
        }
      }
    },
    address(val) {
      if (!this.isReset) {
        if (val) {
          this.addressError.status = true;
        }
      }
    },
    campaignUrl(val) {
      if (!this.isReset) {
        if (val) {
          this.campaignUrlError.status = true;
        }
      }
    },
    notes(val) {
      if (!this.isReset) {
        if (val) {
          this.notesTextError.status = true;
        }
      }
    },
    linkedIn(val) {
      if (!this.isReset) {
        if (val) {
          this.linkedInError.status = true;
        }
      }
    },
    twitter(val) {
      if (!this.isReset) {
        if (val) {
          this.twitterError.status = true;
        }
      }
    },
    instagram(val) {
      if (!this.isReset) {
        if (val) {
          this.instagramError.status = true;
        }
      }
    },
    facebook(val) {
      if (!this.isReset) {
        if (val) {
          this.facebookError.status = true;
        }
      }
    },
    youTube(val) {
      if (!this.isReset) {
        if (val) {
          this.youTubeError.status = true;
        }
      }
    },
  },
  methods: {
    copyLink(elementId) {
      try {
        let copyInputElement = document.getElementById(elementId);
        copyInputElement.setAttribute("type", "text");
        copyInputElement.select();
        document.execCommand("copy");
        let payloadNotify = {
          isToast: true,
          title: "Video Preview Link",
          content: "Link was copied successfully.",
          variant: "success",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } catch (err) {
        let payloadNotify = {
          isToast: true,
          title: "Video Preview Link",
          content: "Unabe to copy vidoe preview link to the clipboard.",
          list: [err.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    async initFn(userId) {
      try {
        this.isAPILoading = true;
        let { data } = await GetSingleUser(userId);
        console.log({ data });
        if (data.code == 200 || data.message == "success") {
          let campaign = data.content.campaign;
          let campaignInfo = data.content.campaignInfo;

          // if user id is valid object id but does not exist
          if (!campaign) return this.handleUserFetchError();

          if (!campaignInfo) campaignInfo = {}

          this.userDetails = {
            userId: campaign._id,
            // campName: campaign.idName,
            campName: campaign.name,
            federalTaxId: campaign.federalTaxId,
            ownerFirstName: campaign.ownerFirstName + ' ' + campaign.ownerLastName,
            email: campaign.ownerEmail,
            declarationName: campaignInfo.declarationName,
            stateidentificationNo: campaign.stateidentificationNo ?? campaignInfo.stateidentificationNo,
            contactNo: campaignInfo ? campaignInfo.treasurerPhoneNumber : "",
            joinedDate: campaign.created_at.split("T")[0],
          };
          
          this.email = campaign.ownerEmail;
          this.phone = campaignInfo ? campaignInfo.treasurerPhoneNumber : "";
          this.campaignUrl = campaign.url
          this.address = campaignInfo ? campaignInfo.campaignMailingAddress : ""
          this.campaignDonationPage = `${process.env.VUE_APP_DONATION_BASE_URL}/${data.content.campaign.idName}`;
          this.linkedIn = campaign.linkedIn;
          this.twitter = campaign.twitter;
          this.instagram = campaign.instagram;
          this.facebook = campaign.facebook;
          this.youTube = campaign.youtube;
          this.aboutText = campaign.about;
          this.notes = campaign.comments;

          // to get the renewalDate
          const endDate = new Date(campaign.created_at.split("T")[0]);
          this.renewalDate = `${endDate.getFullYear() + 1}-${String(
            endDate.getMonth() + 1
          ).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`;

          this.setupFees = campaign.setupFeePaid;
          // this.candidateType = campaignInfo
          //   ? campaignInfo.candidateType
          //   : "N/A";
          this.candidateName = campaignInfo
            ? campaignInfo.candidateName
            : "N/A";
          this.specifyParty = campaignInfo ? campaignInfo.specifyParty : "N/A";
          this.raceType = campaignInfo ? campaignInfo.raceType : "N/A";
          this.districtNo = campaignInfo ? campaignInfo.districtNo : "N/A";
          this.state = campaignInfo ? campaignInfo.state : "N/A";
          this.PACName = campaignInfo ? campaignInfo.PACName : "N/A";
          this.nameOfTheRace = campaignInfo ? campaignInfo.race : "N/A";
          this.partyType = campaignInfo ? campaignInfo.partyType : "N/A";
          this.state = campaignInfo ? campaignInfo.state : "N/A";
          this.districtNo = campaignInfo ? campaignInfo.districtNo : "N/A";
          this.electionType = campaignInfo ? campaignInfo.electiontype : "N/A";
          // this.dateOfElection = campaignInfo
          //   ? campaignInfo.dateOfElection.split("T")[0]
          //   : "N/A";
          this.identificationNo = campaignInfo
            ? campaignInfo.fecIdentificationNumber
            : "N/A";
          this.taxId = campaignInfo ? campaignInfo.committeeTaxId : "N/A";
          this.mailingAddress = campaign ? campaign.address : "N/A";
          this.declarationName = campaignInfo
            ? campaignInfo.declarationName
            : "N/A";
        } else
          this.handleUserFetchError(data.status, data.details, [data.others]);
      } catch (error) {
        console.log(error);
      } finally {
        this.isAPILoading = false;
      }
    },
    handleUserFetchError(
      title = "User Details",
      content = "Requested user not found",
      list = ["Unable to find User by ID"]
    ) {
      let payloadNotify = {
        isToast: true,
        title,
        content,
        list,
        variant: "danger",
      };
      this.$store.dispatch("notification/setNotify", payloadNotify);
      setTimeout(() => this.$router.back(), 2000);
    },
    // preview modal functions
    openPreviewVideoModal(item, button) {
      console.log("preview", item);
      this.isShowPreviewModal = true;
      this.previewVideoItem = item;
      this.$root.$emit("bv::show::modal", "preview-video-row-modal", button);
    },
    onClosePreviewVideoModal() {
      this.isShowPreviewModal = false;
    },

    async saveData() {
      try {
        this.isLoading = true;

        let payload = {
          ownerEmail: this.email,
          treasurerPhoneNumber: this.phone,
          address: this.address,
          linkedIn: this.linkedIn,
          twitter: this.twitter,
          instagram: this.instagram,
          facebook: this.facebook,
          youtube: this.youTube,
          about: this.aboutText,
          comments: this.notes,
        };

        const id = this.$route.params.userId;
        let { data } = await UpdateUser(id, payload);

        console.log("tets", payload);

        if (data && data.code == 200 && data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Charity Data",
            content: "Charity data was saved successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.callInitFn(id);
        } else {
          console.log("Update Charity data Response Error: ", data);
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Update user",
            content: `Unable to update user details! ${
              data ? data.msg : "Please try again."
            }.`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Charity Contentt",
          content: "Failed to retrieve data! please check again: ",
          list: [error.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isLoading = false;
      }
    },
    async resetForm() {
      const id = this.$route.params.userId;

      this.isReset = true;
      // this.linkedIn =null;
      // this.twitter =null;
      // this.instagram =null;
      // this.facebook =null;
      // this.youTube =null;
      // this.aboutText =null;
      // this.notes =null;
      // this.linkedInError.status = null;
      // this.twitterError.status = null;
      // this.instagramError.status = null;
      // this.facebookError.status = null;
      // this.youTubeError.status = null;
      // this.notesTextError.status = null;
      if (
        this.linkedIn ||
        this.twitter ||
        this.instagram ||
        this.facebook ||
        this.youTube ||
        this.notes ||
        this.aboutText
      ) {
        this.isResetLoading = true;
        let payload = {
          linkedIn: "",
          twitter: "",
          instagram: "",
          facebook: "",
          youtube: "",
          about: "",
          comments: "",
        };
        let { data } = await UpdateUser(id, payload);
        console.log(payload);

        if (data && data.code == 200 && data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Charity Data",
            content: "Charity data was reset successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.initFn(id);
          this.notesTextError.status = null;
          this.linkedInError.status = null;
          this.twitterError.status = null;
          this.instagramError.status = null;
          this.facebookError.status = null;
          this.youTubeError.status = null;
          this.isResetLoading = false;
          this.isReset = false;
        } else {
          console.log("Reset Campaign data Response Error: ", data);
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Reset Charity data",
            content: `Unable to reset Charity details! ${
              data ? data.msg : "Please try again."
            }.`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      }
    },
    callInitFn(id) {
      this.initFn(id);
    },
  },
};
</script>
<style scoped>
/* container card */
/* .textpadding {
  padding-left: 100px;
}
.textWidth {
  width: 210px;
} */
.card-row {
  height: 100%;
}
.card-row .card-scroll {
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
.card-row .card-scroll::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.card-row .inner-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
/* card body container */
.card-body-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  height: 100%;
}
/* card body left column */
.card-body-container .left-column {
  display: grid;
  grid-gap: 3rem;
  margin-right: 1rem;
}
.card-body-container .left-column .user-details-section .inner-row-grid {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 5%;
}
/* card body right column */
.card-body-container .right-column {
  margin-left: 1rem;
}
.card-body-container .right-column .video-container-row {
  overflow: hidden;
}
/* video preview image */
.video-container-row .video-preview-image {
  width: 100%;
  height: 280px;
  border-radius: 8px;
  outline: none;
  border: none;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  object-fit: cover;
  filter: brightness(0.6);
}
/* video preview button */
.video-container-row .video-preview-button {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 38px;
  box-shadow: 0px 2px 8px var(--shadow-soft);
  border: 2px solid var(--white);
  padding: 0 0 0 3px;
  color: var(--white);
  background-color: transparent;
  transition: color, background-color 200ms ease-in;
}
.video-container-row .video-preview-button .play-icon {
  width: 24px;
  height: 24px;
}
/* video preview button (hover state) */
.video-container-row .video-preview-button:hover {
  color: var(--dark);
  background-color: var(--white);
}
/* video actions row */
.video-actions-row .video-preview-form-group {
  height: 50px;
}
.video-actions-row .video-preview-form-group .append-button {
  background-color: rgba(236, 239, 244, 1);
  border-radius: 0px 8px 8px 0px;
  width: auto;
  max-width: 160px;
  height: 100%;
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}
.card-body-container .column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}

/* .card-body-container .right-column .inner-row-grid {
  display: grid;
  grid-gap: 1.5rem;
  background-color:red;
} */
.card-body-container .right-column .right-column-card {
  background: #333333;
  opacity: 0.6;
  border-radius: 13px;
  width: 520px;
  height: 292.5px;
}

.card-body-container .right-column {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
}
#video-preview-link-input ~ div {
  max-height: 50px;
}
.maxWidthtext {
  max-width: 300px;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .card-body-container {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
  .card-body-container .left-column {
    margin-right: 0;
  }
  .card-body-container .right-column {
    margin-left: 0;
  }
  .card-body-container .column-divider {
    width: 100%;
    height: 0;
    border-right: none;
    border-top: 1px solid var(--light);
  }
  .card-row .card-scroll {
    padding: 1.5rem 0;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .card-body-container .left-column .user-details-section .inner-row-grid {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
  }
}
</style>
