<template>
  <b-form-group
    :id="groupId"
    :label="label"
    :label-for="id"
    :description="description"
    :invalid-feedback="invalidFeedback"
    :state="state"
    :class="[
      localValue !== null ? 'is-filled is-focus' : '',
      isFocused ? 'is-focus' : '',
      whiteBG ? 'white-label-bg' : '',
      isDisabled ? 'is-disabled' : '',
    ]"
  >
    <b-form-select
      :id="id"
      :options="options"
      :required="isRequired"
      :state="state"
      :ref="id"
      :form="form"
      :disabled="isDisabled"
      :multiple="isMultiple"
      :value="value"
      @change="(e) => updateInput(e)"
      @blur.native="(e) => localChangeFn(e)"
      @focus.native="(e) => onFocus(e)"
    ></b-form-select>
  </b-form-group>
</template>

<script>
  /* eslint-disable no-console */
  export default {
    name: "FormSelect",
    data() {
      return {
        localValue: null,
        isFocused: false,
      };
    },
    props: {
      id: String,
      groupId: String,
      type: String,
      label: String,
      description: {
        default: "",
        type: String,
      },
      isRequired: Boolean,
      options: {
        isRequired: true,
        type: Array,
      },
      invalidFeedback: {
        default: "",
        type: String,
      },
      isMultiple: {
        default: false,
        type: Boolean,
      },
      isDisabled: {
        default: false,
        type: Boolean,
      },
      state: {
        default: null,
        type: Boolean,
      },
      form: {
        default: "",
        type: String,
      },
      whiteBG: {
        default: true,
        type: Boolean,
      },
      value: {
        default: null,
        // type: String,
      },
    },
    watch: {
      value: {
        immediate: true,
        handler(val) {
          this.localValue = val;
        },
      },
    },
    mounted() {
      this.localValue = this.value;
    },
    methods: {
      updateInput(val) {
        this.localValue = val;
        console.log("select value - ", val);
        this.$emit("input", val);
      },
      localChangeFn(val) {
        this.isFocused = false;
        this.$emit("blurLocal", val.target.value);
      },
      onFocus() {
        this.isFocused = true;
      },
    },
  };
</script>
<style scoped>
  /* == FORM GROUP STYLING == */
  /* customized form control */
  .form-group {
    position: relative;
  }
  /* placeholder text color */
  .form-group >>> select.custom-select::placeholder {
    color: var(--prime-gray);
  }
  /* == FORM SELECT STYLING == */
  /* form custom select styling (default state) */
  .form-group >>> select.custom-select {
    background-color:transparent;
    z-index: 5;
    border-radius: 4px;
    min-height: 50px;
    border-color: var(--prime-gray-muted);
    box-shadow: 0px 2px 8px var(--shadow-soft);
  }
  /* form custom select error state styling (valid state) */
  .form-group.is-valid >>> select.custom-select {
    border-color: var(--success);
  }
  /* form custom select error state styling (invalid state) */
  .form-group.is-invalid >>> select.custom-select {
    border-color: var(--danger);
  }
  /* form custom select focused styling (focus state) */
  .form-group.is-focus >>> select.custom-select {
    z-index: 0;
  }
  .form-group >>> select.custom-select:focus {
    border-color: var(--info);
    box-shadow: none;
  }
  /* form custom select focused error state styling (focus/valid state) */
  .form-group.is-focus.is-valid >>> select.custom-select {
    border-color: var(--success);
  }
  /* form custom select focused error state styling (focus/invalid state) */
  .form-group.is-focus.is-invalid >>> select.custom-select {
    border-color: var(--danger);
  }
  /* form custom select filled styling (filled state) */
  .form-group.is-filled >>> select.custom-select {
    border-color: var(--info);
    box-shadow: none;
  }
  /* form custom select filled error state styling (filled/valid state) */
  .form-group.is-filled.is-valid >>> select.custom-select {
    border-color: var(--success);
  }
  /* form custom select filled error state styling (filled/invalid state) */
  .form-group.is-filled.is-invalid >>> select.custom-select {
    border-color: var(--danger);
  }
  /* form custom select disabled state styling (disabled state) */
  .form-group.is-disabled >>> select.custom-select,
  .form-group:disabled >>> select.custom-select {
    color: var(--ghost-white);
  }
  /* == LABEL STYLING == */
  /* form label (default state) */
  .form-group >>> label {
    position: absolute;
    color: var(--prime-gray);
    font-size: 1rem;
    /* width: auto; */
    width: calc(100% - 45px);
    max-width: 300px;
    top: 13px;
    left: 13px;
    z-index: 1;
    cursor: default;
    white-space: nowrap;
    word-wrap: normal;
    text-overflow: clip;
    user-select: none;
    pointer-events: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    transition: top 100ms linear 0s, left 100ms linear 0s, color 100ms linear 0s,
      font-size 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
      opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  }
  /* form label filled styling (filled state) */
  .form-group.is-filled >>> label {
    opacity: 0;
  }
  /* form label focused styling (focus state) */
  .form-group.is-focus >>> label {
    font-size: 0.8rem;
    color: var(--info);
    width: auto;
    top: -9px;
    left: 10px;
    opacity: 1;
    z-index: 2;
  }
  /* form label focused error state styling (focus/valid state) */
  .form-group.is-focus.is-valid >>> label {
    color: var(--success);
  }
  /* form label focused error state styling (focus/invalid state) */
  .form-group.is-focus.is-invalid >>> label {
    color: var(--danger);
  }
  /* form label disabled state styling (disabled state) */
  .form-group:disabled >>> label,
  .form-group.is-disabled >>> label {
    color: var(--ghost-white);
  }
  /* form label background (default state) */
  .form-group >>> label:before {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--ghost-white);
    z-index: -1;
    pointer-events: none;
  }
  /* form label background focused styling (focus state) */
  .form-group.is-focus >>> label::before {
    width: 100%;
    height: 100%;
  }
  /* form label background disabled focused styling (disabled/focus state) */
  .form-group:disabled.is-focus >>> label::before,
  .form-group.is-disabled.is-focus >>> label::before {
    background-color: var(--white);
    height: 3px;
    top: 8px;
  }
  /* white label bg */
  .form-group.white-label-bg >>> label:before {
    background-color: var(--white);
  }
  .form-group.white-label-bg >>> select:disabled {
    background-color: var(--white);
  }
</style>
