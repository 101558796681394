<template>
  <b-navbar
    type="light"
    class="
      d-flex
      flex-row
      justify-content-start
      align-items-center
      px-2 px-sm-3 px-md-6
      top-navbar
    "
    :class="isShowSideMenu ? 'top-navbar collapsed' : 'top-navbar'"
  >
    <!-- logo (visible only on desktop) -->
    <b-navbar-brand
      class="d-none d-md-flex align-items-center px-0 mr-4 mx-0 my-1"
      to="/"
    >
    <b-img
        src="@/assets/The Logo.png"
        class="px-0 w-auto"
        height="45px"
        alt="Give Proudly"
        v-if="env === 'give-proudly'"
      ></b-img>
      <b-img
        src="@/assets/personalPac-log.svg"
        class="px-0 w-auto"
        height="20px"
        alt="Personal PAC"
        v-else
      ></b-img>
    </b-navbar-brand>
    <b-row
      class="
        d-flex
        flex-row
        align-items-center
        justify-content-start
        mx-0
        mr-auto
      "
    >
      <!-- mobile side menu button (only visible on mobile) -->
      <b-button
        class="d-block d-md-none border border-0 px-2 py-1 mr-2"
        variant="transparent"
        @click="showSideMenu"
      >
        <font-awesome-icon
          icon="bars"
          size="lg"
          aria-label="Menu"
        ></font-awesome-icon>
      </b-button>
      <!-- title -->
      <template v-if="subPage">
        <h5 class="font-primary display-text text-left mb-0">
          {{ parentPage }}
        </h5>
        <font-awesome-icon
          icon="chevron-right"
          aria-label="SubMenu"
          class="mx-2 mt-1"
        ></font-awesome-icon>
        <h5 class="font-primary display-text text-left mb-0">
          {{ subPage }}
        </h5>
      </template>
      <template v-else>
        <h5 class="font-primary display-text text-left mb-0">{{ title }}</h5>
      </template>
    </b-row>
    <!-- user account icon -->
    <b-dropdown
      variant="light"
      class="user-icon-button ml-0 ml-sm-2"
      toggle-class="d-flex flex-row align-items-center justify-content-between rounded-circle p-0 text-decoration-none user-icon-pill"
      menu-class="w-100 bg-white border border-prime-gray rounded-lg user-icon-menu"
      right
      no-caret
    >
      <template #button-content>
        <!-- TODO: get the text avatar from user name -->
        <b-avatar
          class="
            user-avatar
            bg-light
            text-dark
            font-weight-bold
            border border-dark
          "
          :text="
            adminDetails && adminDetails.adminName
              ? firstLetterFn(adminDetails.adminName)
              : ''
          "
          size="34px"
        ></b-avatar>
      </template>
      <b-dropdown-group>
        <!-- current user details -->
        <b-dropdown-text class="w-100" text-class="px-3">
          <b-col cols="12" class="px-0 align-items-start">
            <!-- user name -->
            <span
              class="
                d-block
                text-left text-medium text-dark
                font-secondary font-weight-bold
                text-truncate
                mb-1
              "
            >
              {{ adminDetails ? adminDetails.adminName : "" }}
            </span>
            <!-- user type -->
            <span
              class="
                d-block
                text-left text-prime-gray text-small
                font-secondary
              "
            >
              {{ adminDetails ? adminDetails.adminType : "" }}
            </span>
          </b-col>
        </b-dropdown-text>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item-button
          variant="dark"
          button-class="px-3 py-2"
          @click="settingsFn"
        >
          <font-awesome-icon
            icon="cog"
            class="mr-1 text-dark"
            scale="0.9"
            aria-hidden="true"
          ></font-awesome-icon>
          <span class="text-left text-small text-dark pl-2"
            >Account Settings</span
          >
        </b-dropdown-item-button>
        <b-dropdown-item-button button-class="px-3 py-2" @click="logoutFn">
          <font-awesome-icon
            icon="sign-out-alt"
            class="mr-1 text-dark"
            scale="0.9"
            aria-hidden="true"
          ></font-awesome-icon>
          <span class="text-left text-small text-dark pl-2">Sign Out</span>
        </b-dropdown-item-button>
      </b-dropdown-group>
    </b-dropdown>
  </b-navbar>
</template>

<script>
export default {
  name: "TopBar",
  data() {
    return {
      env: null,
      adminDetails: null,
    };
  },
  props: {
    isShowSideMenu: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    title() {
      return this.$route.name;
    },
    subPage() {
      return this.$route.meta.subPage;
    },
    parentPage() {
      return this.$route.meta.parentPage;
    },
  },
  mounted() {
    this.env = process.env.VUE_APP_NAME;
    if (localStorage.getItem("adminDetails")) {
      this.adminDetails = JSON.parse(localStorage.getItem("adminDetails"));
    }
  },
  methods: {
    showSideMenu() {
      this.$store.dispatch("setIsSideMenuExpanded", true);
    },
    settingsFn() {
      this.$router.replace({
        name: "Account Settings",
      });
    },
    logoutFn() {
      localStorage.clear();
      this.$router.replace({
        name: "Login",
      });
    },
    firstLetterFn(text) {
      var initials = text.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
      return initials;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.top-navbar {
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 180;
  top: 0;
  right: 0;
  background-color: var(--white);
  transition: max-width 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
    background-color 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

.top-navbar .display-text {
  font-size: 1.3rem;
}

.filter-dropdown {
  height: 40px;
  min-width: 180px;
}

.filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.user-icon-button >>> .user-icon-menu {
  min-width: 240px;
}

.filter-dropdown >>> .filter-dropdown-menu button.dropdown-item.active,
.filter-dropdown >>> .filter-dropdown-menu button.dropdown-item:active,
.user-icon-button >>> .user-icon-menu button.dropdown-item.active,
.user-icon-button >>> .user-icon-menu button.dropdown-item:active {
  background-color: var(--light);
}

.user-icon-button >>> .user-icon-pill {
  cursor: pointer;
  border: 1px solid transparent;
  transition: border 150ms ease-in, shadow 150ms ease-in;
}

.user-icon-button:hover >>> .user-icon-pill {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}

.user-icon-button >>> .user-icon-pill span.text-small {
  font-size: 0.85rem;
  max-width: 120px;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .filter-dropdown {
    min-width: auto;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .top-navbar,
  .top-navbar.collapsed {
    left: 0;
    width: 100%;
    max-width: 100vw;
    min-width: 100%;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .top-navbar .display-text {
    font-size: 1rem;
  }

  .user-icon-button >>> .user-icon-pill .user-avatar {
    font-size: 0.7rem;
  }

  .filter-dropdown {
    height: 35px;
    min-width: auto;
  }
}
</style>
