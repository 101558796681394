<template>
  <b-row
    class="d-flex flex-row align-items-start justify-content-start"
    no-gutters
  >
    <b-col class="d-flex flex-column align-items-start justify-content-start">
      <h6
        class="text-left text-dark font-weight-semi-bold font-primary mb-3 mt-3"
      >
        Getting Started
      </h6>
      <!-- Add a New Info form -->
      <form
        id="admin-registration-form"
        class="d-flex flex-column align-items-start justify-content-start w-100"
      >
        <div v-for="(info, index) in infoList" :key="info.id" class="w-100">
          <b-row
            v-if="index != 0"
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-start
              w-100
              mt-4
              mb-2
            "
            no-gutters
          >
            <div
              @click="info.detailsShowing = !info.detailsShowing"
              class="
                d-flex
                justify-content-between
                flex-row
                align-items-center
                w-100
              "
              style="cursor: pointer"
            >
              <div
                class="d-flex flex-row align-items-center justify-content-start"
              >
                <b-button
                  @click="removeFromList(info.id)"
                  size="sm"
                  variant="light"
                  class="
                    d-flex
                    p-0
                    justify-content-center
                    rounded-circle
                    bg-light
                    mr-2
                  "
                >
                  <font-awesome-icon
                    icon="times-circle"
                    class="text-gray-550"
                    style="font-size: 18px"
                  ></font-awesome-icon>
                </b-button>
                Info #{{ index + 1 }}
              </div>
              <font-awesome-icon
                :icon="info.detailsShowing ? 'chevron-up' : 'chevron-down'"
                class="text-gray-550"
                style="font-size: 16px"
              ></font-awesome-icon>
            </div>
          </b-row>
          <b-row
            v-if="info.detailsShowing"
            class="
              d-flex
              flex-row
              align-items-start
              justify-content-start
              w-100
              py-2
            "
            no-gutters
          >
            <b-col cols="12">
              <FormInput
                groupId="admin-name-group"
                :counter="140"
                id="admin-name-group-input"
                class="text-prime-gray w-100"
                :state="info.title.status"
                :invalidFeedback="`${info.title.message}`"
                label="Info Title"
                v-model="info.title.value"
                isRequired
                trim
                form="admin-registration-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <b-row
            v-if="info.detailsShowing"
            class="
              d-flex
              flex-row
              align-items-start
              justify-content-start
              w-100
            "
            no-gutters
          >
            <b-col cols="12">
              <vue-editor
                v-model="info.description.value"
                :editor-toolbar="customToolbar"
                placeholder="Info Answer"
              >
              </vue-editor>
            </b-col>
          </b-row>
        </div>
        <!-- form action -->
        <b-row
          v-if="createInfoError.status"
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <!-- error message span class -->
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="9"
          >
            <span class="text-danger text-center" style="font-size: 0.8rem">{{
              createInfoError.message
            }}</span>
          </b-col>
        </b-row>
        <!-- action buttons -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
              faqBtns
            "
            cols="6"
          >
            <FormButton
              variant="secondary"
              isBlock
              class="font-weight-normal text-white mt-2 border-dark"
              type="submit"
              @click.native="addAnotherInfo"
              :isDisabled="isLoading"
              >Add Another</FormButton
            >
          </b-col>
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="6"
          >
            <FormButton
              variant="primary"
              isBlock
              class="font-weight-normal text-white mt-2 border-dark"
              type="submit"
              @click.native="saveInfos"
              :isLoading="isLoading"
              :isDisabled="isLoading"
              >Save Changes</FormButton
            >
          </b-col>
        </b-row>
      </form>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from "@/components/Form/FormInput";
import FormButton from "@/components/Form/FormButton";
import { CreateInfoS } from "@/services/content.service";

export default {
  name: "CreateInfo",
  components: {
    FormInput,
    FormButton,
  },
  data() {
    return {
      infoList: [
        {
          id: 0,
          title: {
            value: null,
            status: null,
            message: null,
          },
          description: {
            value: null,
            status: null,
            message: null,
          },
          detailsShowing: true,
        },
      ],
      createInfoError: {
        status: null,
        message: null,
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
      isLoading: false,
    };
  },
  watch: {
    infoList: {
      handler: function (val) {
        this.createInfoError.status = false;
        this.errorFn(val);
      },
      deep: true,
    },
  },
  methods: {
    async saveInfos() {
      var errors = this.errorFn(this.infoList, true);
      if (errors.length == 0) {
        this.isLoading = true;
        let payload = this.infoList.map((info) => {
          return {
            title: info.title.value,
            description: info.description.value,
          };
        });
        console.log(payload);
        try {
          let { data } = await CreateInfoS(payload);
          this.isLoading = false;
          if (data && data.code == 200 && data.message == "success") {
            let payloadNotify = {
              isToast: true,
              title: "Info Creation",
              content:
                this.infoList.length == 1
                  ? "Info was saved successfully"
                  : "All Infos were saved successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.$emit("reloadFn");
            this.resetFn();
          } else if (data) {
            this.createInfoError.status = true;
            this.createInfoError.message = `An error occured when saving the data: ${data.details}`;
            this.handleErrorFn(
              this.infoList.length == 1
                ? "Info was not saved"
                : "All Infos were not saved",
              [data.details, data.errorCode]
            );
          } else throw new Error("Error saving details");
        } catch (error) {
          console.log(error);
          this.createInfoError.status = true;
          this.createInfoError.message = `An error occured when saving the data: ${error.message}`;
          this.handleErrorFn(
            this.infoList.length == 1
              ? "Info was not saved"
              : "All Infos were not saved",
            [error.message]
          );
        } finally {
          this.isLoading = false;
        }
      } else
        this.handleErrorFn(
          "It seems like you have missed some important fields",
          errors
        );
    },
    handleErrorFn(content, list) {
      var payloadNotify = {
        isToast: true,
        title: "ERROR! Info Update",
        content,
        list,
        variant: "danger",
      };
      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
    errorFn(infoList = this.infoList, isSubmit = false) {
      var errorList = [];

      infoList.forEach((info, index) => {
        var titleErrors = this.validateTitle(info.title.value);
        var descriptionErrors = this.validateDescription(
          info.description.value
        );

        if (titleErrors.length > 0) {
          if (info.title.value != null || isSubmit) {
            info.title.status = false;
            info.title.message = titleErrors[0];
          }
          errorList.push(`Info #${index + 1}: ${titleErrors[0]}`);
        } else if (info.title.value) info.title.status = true;

        if (descriptionErrors.length > 0) {
          if (info.description.value != null || isSubmit) {
            info.description.status = false;
            info.description.message = descriptionErrors[0];
          }
          errorList.push(`Info #${index + 1}: ${descriptionErrors[0]}`);
        } else if (info.description.value) info.description.status = true;
      });

      return errorList;
    },
    validateTitle(val) {
      var errorList = [];
      if (!val) errorList.push("Please fill in the title");
      else if (val.length > 140)
        errorList.push("Title must not exceed 140 characters");
      return errorList;
    },
    validateDescription(val) {
      var errorList = [];
      if (!val) errorList.push("Please fill in the description");
      return errorList;
    },
    addAnotherInfo() {
      const newInfo = {
        id: this.infoList.length + 1,
        title: {
          value: null,
          status: null,
          message: null,
        },
        description: {
          value: null,
          status: null,
          message: null,
        },
        detailsShowing: true,
      };
      this.infoList.push(newInfo);
    },
    removeFromList(id) {
      var index = this.infoList.findIndex((info) => info.id == id);
      this.infoList = [
        ...this.infoList.slice(0, index),
        ...this.infoList.slice(index + 1),
      ];
    },
    resetFn() {
      this.infoList = [];
      this.createInfoError.status = null;
      this.addAnotherInfo();
    },
  },
};
</script>

<style scoped>
.key-row {
  background-color: rgba(236, 239, 244, 1);
  border-radius: 0px 8px 8px 0px;
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* == FORM GROUP STYLING == */
/* customized form control */
#region-selector-group.form-group {
  position: relative;
}

#region-selector-group.form-group
  >>> select.region-selector-select::placeholder {
  color: var(--prime-gray);
}

/* == FORM SELECT STYLING == */
/* form custom select styling (default state) */
#region-selector-group.form-group >>> select.region-selector-select {
  min-height: 50px;
  border-radius: 0;
  background-color: transparent;
  color: var(--muted-gray);
}

#region-selector-group >>> select.region-selector-select option {
  color: #4f5467;
}

/* form custom select error state styling (valid state) */
#region-selector-group.form-group.is-valid >>> select.region-selector-select {
  border-color: var(--success);
}

/* form custom select error state styling (invalid state) */
#region-selector-group.form-group.is-invalid >>> select.region-selector-select {
  border-color: var(--danger);
}

/* form custom select focused styling (focus state) */
#region-selector-group.form-group.is-focus >>> select.region-selector-select {
  z-index: 0;
}

#region-selector-group.form-group >>> select.region-selector-select:focus {
  border-color: var(--info);
  box-shadow: none;
}

/* form custom select focused error state styling (focus/valid state) */
#region-selector-group.form-group.is-focus.is-valid
  >>> select.region-selector-select {
  border-color: var(--success);
}

/* form custom select focused error state styling (focus/invalid state) */
#region-selector-group.form-group.is-focus.is-invalid
  >>> select.region-selector-select {
  border-color: var(--danger);
}

/* form custom select disabled state styling (disabled state) */
#region-selector-group.form-group.is-disabled >>> select.region-selector-select,
#region-selector-group.form-group:disabled >>> select.region-selector-select {
  color: var(--ghost-white);
}

/* label styling */
#region-selector-group.form-group >>> legend.region-selector-label {
  position: absolute;
  color: var(--prime-gray);
  font-size: 1rem;
  /* width: auto; */
  width: calc(100% - 35px);
  top: 13px;
  left: 13px;
  z-index: 1;
  white-space: nowrap;
  word-wrap: normal;
  text-overflow: clip;
  user-select: none;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  transition: top 100ms linear 0s, left 100ms linear 0s, color 100ms linear 0s,
    font-size 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
    opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

/* form label focused styling (focus state) */
#region-selector-group.form-group.is-focus >>> legend.region-selector-label {
  font-size: 0.8rem;
  color: var(--info);
  width: auto;
  top: -9px;
  left: 10px;
  opacity: 1;
  z-index: 2;
}

/* form label focused error state styling (focus/valid state) */
#region-selector-group.form-group.is-focus.is-valid
  >>> legend.region-selector-label {
  color: var(--success);
}

/* form label focused error state styling (focus/invalid state) */
#region-selector-group.form-group.is-focus.is-invalid
  >>> legend.region-selector-label {
  color: var(--danger);
}

/* form label disabled state styling (disabled state) */
#region-selector-group.form-group:disabled >>> legend.region-selector-label,
#region-selector-group.form-group.is-disabled >>> legend.region-selector-label {
  color: var(--ghost-white);
}

/* label before psuedo element (default state) */
#region-selector-group.form-group >>> legend.region-selector-label:before {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--white);
  z-index: -1;
  pointer-events: none;
  transition: width 100ms linear 0s, height 100ms linear 0s;
}

/* form label background focused styling (focus state) */
#region-selector-group.form-group.is-focus
  >>> legend.region-selector-label::before {
  width: 100%;
  height: 100%;
}

/* form label background disabled focused styling (disabled/focus state) */
#region-selector-group.form-group:disabled.is-focus
  >>> legend.region-selector-label::before,
#region-selector-group.form-group.is-disabled.is-focus
  >>> legend.region-selector-label::before {
  background-color: var(--white);
  height: 3px;
  top: 8px;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .faqBtns {
    margin: 2px;
  }
}
</style>
