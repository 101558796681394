<template>
  <b-row
    class="d-flex flex-row align-items-start justify-content-start"
    no-gutters
  >
    <b-col class="d-flex flex-column align-items-start justify-content-start">
      <!-- Add a New FAQ form -->
      <h6
        class="text-left text-dark font-weight-semi-bold font-primary mb-3 mt-3"
      >
        Privacy
      </h6>
      <form
        id="admin-registration-form"
        class="d-flex flex-column align-items-start justify-content-start w-100"
      >
        <b-row
          class="
            d-flex
            flex-row
            align-items-start
            justify-content-start
            w-100
            py-2
          "
          no-gutters
        >
          <b-col cols="12">
            <!-- text editor -->
            <vue-editor
              class="editor mb-4"
              v-model="privacyText"
              :editor-toolbar="customToolbar"
            >
            </vue-editor>
          </b-col>
        </b-row>
        <!-- form action -->
        <b-row
          v-if="savePrivacyError.status"
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <!-- error message span class -->
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="9"
          >
            <span class="text-danger text-center" style="font-size: 0.8rem">{{
              savePrivacyError.message
            }}</span>
          </b-col>
        </b-row>
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="6"
          >
            <FormButton
              variant="primary"
              isBlock
              class="font-weight-normal text-white mt-4 border-dark"
              type="submit"
              @click.native="saveData"
              :isLoading="isAPILoading"
              :isDisabled="isAPILoading"
            >
              Save Changes
            </FormButton>
          </b-col>
        </b-row>
      </form>
    </b-col>
    <!-- overlay loader -->
    <b-overlay :show="initLoading" class="mx-n3" no-wrap></b-overlay>
  </b-row>
</template>

<script>
// components
import FormButton from "@/components/Form/FormButton";

// services
import { SavePrivacy, GetPrivacy } from "@/services/content.service";

export default {
  name: "Privacy",
  components: {
    FormButton,
  },
  created() {
    this.onInit();
  },
  data() {
    return {
      initLoading: false,
      isAPILoading: false,
      privacyText: null,
      privacyTextError: {
        status: null,
        message: null,
      },
      savePrivacyError: {
        status: null,
        message: null,
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link"],
      ],
    };
  },
  watch: {
    privacyText(val) {
      this.savePrivacyError.status = false;
      this.errorFn(val);
    },
  },
  methods: {
    closeModal() {
      this.$emit("onClose");
    },
    async onInit() {
      try {
        this.initLoading = true;
        var { data } = await GetPrivacy();

        if (data.code == 200 && data.message == "success") {
          if (data.content) this.privacyText = data.content.privacy;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.initLoading = false;
      }
    },
    async saveData() {
      try {
        this.isAPILoading = true;
        var errors = this.errorFn();
        if (errors.length == 0) {
          let payload = {
            privacy: this.privacyText,
          };
          let { data } = await SavePrivacy(payload);
          if (data && data.code == 200 && data.message == "success") {
            let payloadNotify = {
              isToast: true,
              title: "Privacy Content",
              content: "Privacy Content was saved successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.$emit("reloadFn");
          } else if (data) {
            this.savePrivacyError.status = true;
            this.savePrivacyError.message = `An error occured when saving the Privacy Content: ${data.details}`;
            this.handleErrorFn("Privacy Content was not saved", [
              data.details,
              data.errorCode,
            ]);
          } else throw new Error("Error saving details");
        } else {
          this.handleErrorFn(
            "It seems like you have missed some important fields when saving the Privacy Content",
            errors
          );
        }
      } catch (error) {
        this.savePrivacyError.status = true;
        this.savePrivacyError.message = `An error occured when saving the Privacy Content: ${error.message}`;
        this.handleErrorFn("Privacy Content was not saved", [error.message]);
      } finally {
        this.isAPILoading = false;
      }
    },
    handleErrorFn(content, list) {
      var payloadNotify = {
        isToast: true,
        title: "ERROR! Privacy Content",
        content,
        list,
        variant: "danger",
      };
      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
    errorFn(privacyText = this.privacyText) {
      var privacyTextError = this.validatePrivacyText(privacyText);
      if (privacyTextError.length > 0) {
        this.privacyTextError.status = false;
        this.privacyTextError.message = privacyTextError[0];
      } else this.privacyTextError.status = true;
      return [...privacyTextError];
    },
    validatePrivacyText(val) {
      var errorList = [];
      if (!val) errorList.push("Please fill in the Privacy text");
      return errorList;
    },
  },
};
</script>
<style scoped>
.editor {
  height: 750px;
  max-height: calc(100vh - 120px);
}
.editor >>> .ql-tooltip {
  z-index: 50;
}
</style>