import Instance from "./instance";

export const Login = async (payload) => {
  try {
    return await Instance.instance.post(`/login/admin`, payload);
  } catch (error) {
    return error;
  }
};
export const LoginNew = async (payload) => {
  try {
    return await Instance.instance.post(`/admin/login`, payload);
  } catch (error) {
    return error;
  }
};

export const Auth = async (payload) => {
  try {
    return await Instance.instanceToken.get(`/vendor/details`, payload);
  } catch (error) {
    return error;
  }
};
