<template>
  <b-row
    class="d-flex flex-row align-items-start justify-content-start main-table"
    no-gutters
  >
    <b-col
      class="d-flex flex-column align-items-start justify-content-start mb-5"
    >
      <h6
        class="text-left text-dark font-weight-semi-bold font-primary mb-4 mt-3"
      >
        Password management
      </h6>
      <!-- Admin Management table -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-start
          justify-content-start
          w-100
          table-container-row
        "
        no-gutters
      >
        <!-- search row -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-center
            mb-3
          "
        >
          <!-- table search input -->
          <b-input-group class="search-input-group">
            <b-input-group-prepend>
              <b-row
                class="
                  search-input-icon
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-center
                  pl-3
                  pr-1
                  border border-prime-white
                "
                no-gutters
              >
                <font-awesome-icon
                  icon="search"
                  size="lg"
                  color="rgba(236, 239, 244, 1)"
                  aria-label="Search"
                ></font-awesome-icon>
              </b-row>
            </b-input-group-prepend>
            <b-form-input
              class="search-input border-left-0 border-prime-white"
              type="text"
              placeholder="Search passwords"
              v-model="searchTable"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-table
          :bordered="false"
          :borderless="true"
          :hover="true"
          :striped="true"
          :filter="searchTable"
          @row-selected="onRowSelected"
          small
          class="table-element"
          thead-class="table-header font-primary"
          tbody-class="table-body "
          :fields="adminsHeaderFields"
          :items="passwordList"
          show-empty
          :busy="isLoading"
        >
          <!-- loading state -->
          <template v-slot:table-busy>
            <div class="text-center text-muted my-5">
              <b-spinner class="align-middle"></b-spinner>
              <span class="text-left mx-1">Loading...</span>
            </div>
          </template>
          <!-- Admin Type (and Date) column -->
          <template v-slot:cell(dateCreated)="data">
            <b-col
              class="
                d-flex
                flex-column
                align-items-start
                justify-content-center
                px-0
              "
            >
              <!-- <span class="main-text">{{
                data.value.type == "ADMIN"
                  ? "Administrator"
                  : data.value.type == "MANAGER"
                  ? "Manager"
                  : "Master"
              }}</span> -->
              <span class="text-dark main-text">{{ data.value.date }}</span>
            </b-col>
          </template>
          <!-- Admin Name (and email) column -->
          <template v-slot:cell(passwordCode)="data">
            <b-col
              class="
                d-flex
                flex-column
                align-items-start
                justify-content-center
                px-0
              "
            >
              <span class="main-text text-truncate">{{
                data.value.password
              }}</span>
              <!-- <a
                class="text-muted sub-text"
                :href="data.value.email ? `mailto:${data.value.email}` : ''"
                >{{ data.value.email }}</a -->
              <!-- > -->
            </b-col>
          </template>
          <!-- Admin Status column -->
          <template v-slot:cell(passwordStatus)="data">
            <b-col class="d-flex justify-content-start px-0 mx-0">
              <div
                v-if="data.value"
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  text-info text-password-status
                "
              >
                <b-icon icon="check-circle-fill" class="mr-1"></b-icon>
                <span class="text-nowrap"> Enabled </span>
              </div>

              <div
                v-else
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  text-danger text-password-status
                "
              >
                <b-icon icon="x-circle-fill" class="mr-1 type-bold"></b-icon>
                <span class="text-nowrap"> Disabled </span>
              </div>
            </b-col>
          </template>
          <!-- Admin actions column -->
          <template v-slot:cell(actionsState)="data">
            <!-- actions dropdown -->
            <b-dropdown
              id="dropdown-form"
              ref="dropdown"
              variant="white"
              class="mx-0 mx-md-2 my-2 my-md-0 actions-dropdown"
              toggle-class="px-2 border border-secondary rounded-circle actions-button-icon"
              menu-class="bg-white border border-prime-gray rounded-lg actions-dropdown-menu"
              right
              no-caret
            >
              <template v-slot:button-content>
                <font-awesome-icon
                  class="text-secondary"
                  icon="ellipsis-h"
                  size="lg"
                  aria-label="Open Actions"
                ></font-awesome-icon>
              </template>
              <b-dropdown-group>
                <!-- current user details -->
                <b-dropdown-text
                  class="w-100"
                  text-class="text-medium text-secondary font-weight-normal px-2 py-2"
                >
                  Actions
                </b-dropdown-text>
                <b-dropdown-item-button
                  button-class="px-3 py-2"
                  @click="openEnabDisabModal(data.value.details, $event.target)"
                >
                  <font-awesome-icon
                    :icon="data.value.state ? 'ban' : 'check'"
                    class="mr-1 text-secondary inner-icon"
                    scale="0.9"
                    aria-hidden="true"
                  ></font-awesome-icon>
                  <span
                    class="
                      text-left text-small
                      font-weight-normal
                      text-secondary
                      pl-2
                    "
                    >{{
                      data.value.state ? "Disable Password" : "Enable Password"
                    }}</span
                  >
                </b-dropdown-item-button>
                <b-dropdown-item-button
                  variant="secondary"
                  button-class="px-3 py-2"
                  @click="
                    openDeleteItemModal(data.value.details, $event.target)
                  "
                >
                  <font-awesome-icon
                    icon="trash"
                    class="mr-1 text-danger inner-icon"
                    scale="0.9"
                    aria-hidden="true"
                  ></font-awesome-icon>
                  <span
                    class="
                      text-left text-small
                      font-weight-normal
                      text-danger
                      pl-2
                    "
                    >Delete Password</span
                  >
                </b-dropdown-item-button>
              </b-dropdown-group>
            </b-dropdown>
          </template>
          <!-- expandable row -->
          <!-- <template #row-details="row">
            <b-card class="border border-light shadow-sm">
              <b-row
                class="d-flex flex-row align-items-start justify-content-between"
                no-gutters
              > -->
          <!-- question answer choices -->
          <!-- <b-col
                  class="d-flex flex-column align-items-start justify-content-start"
                  cols="6"
                >
                  <h6 class="text-left text-dark font-weight-semi-bold font-primary mb-2">
                    Admin Type
                  </h6> -->
          <!-- answers group -->
          <!-- <b-row
                    class="d-flex flex-row flex-wrap align-items-start justify-content-start"
                    no-gutters
                  >
                    <div
                      class="d-flex flex-row align-items-center justify-content-center bg-light rounded-lg overflow-hidden text-dark mr-2 mb-2 text-secondary font-weight-normal"
                    > -->
          <!-- admin tyep -->
          <!-- <template v-if="row.item.adminType.type == 'ADMIN'">
                        <span
                          class="text-center font-secondary font-weight-normal text-primary text-small bg-light px-2 py-1 rounded-lg admin-type-badge"
                        >
                          Administrator
                        </span>
                      </template>
                      <template v-else>
                        <span
                          class="text-center font-secondary font-weight-normal text-warning text-small bg-light px-2 py-1 rounded-lg admin-type-badge"
                        >
                          Manager
                        </span>
                      </template>
                    </div>
                  </b-row>
                </b-col> -->
          <!-- regions assigned -->
          <!-- <template v-if="row.item.regions && row.item.regions.length > 0">
                  <b-col
                    class="d-flex flex-column align-items-start justify-content-start"
                    cols="6"
                  >
                    <h6
                      class="text-left text-dark font-weight-semi-bold font-primary mb-2"
                    >
                      Countries Assigned
                    </h6> -->
          <!-- languages group -->
          <!-- <b-row
                      class="d-flex flex-row flex-wrap align-items-start justify-content-start"
                      no-gutters
                    >
                      <div
                        class="d-flex flex-row align-items-center justify-content-center bg-light rounded-lg text-dark mr-2 mb-2 px-2 py-1"
                        v-b-tooltip.hover.top
                        v-for="(country, index) in row.item.regions"
                        :key="index"
                        :title="`${country.countryName} - ${country.languageName}`"
                      >
                        <span
                          class="text-secondary font-weight-normal text-capitalize text-small"
                          >{{ `${country.countryName} - ${country.languageName}` }}</span
                        >
                      </div>
                    </b-row>
                  </b-col>
                </template>
              </b-row>
            </b-card>
          </template> -->
        </b-table>
      </b-row>
    </b-col>
    <!-- table controls -->
    <!-- safari fix (flex-shrink-0) -->
    <b-row
      class="
        d-flex
        flex-row
        align-items-center
        justify-content-center justify-content-md-end
        flex-shrink-0
        px-3
        w-100
      "
      no-gutters
    >
      <!-- pages per page control -->
      <b-col
        class="
          d-flex
          flex-column
          align-items-center
          align-items-sm-start
          align-items-md-end
          align-items-xl-start
          justify-content-center
        "
        cols="12"
        sm="6"
      >
        <b-form-group
          label="Per page"
          label-cols="12"
          label-cols-sm="8"
          label-align="left"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0 w-100"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            class="per-page-con border-1 py-0"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <!-- pagination controls -->
      <b-col
        class="
          d-flex
          flex-column
          align-items-center align-items-sm-end
          justify-content-center
        "
        cols="12"
        sm="6"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          limit="4"
          pills
          class="d-none d-sm-flex table-pagination my-0"
          page-class="custom-page-item"
          ellipsis-class="custom-page-item"
          first-class="custom-page-item"
          last-class="custom-page-item"
          next-class="custom-page-item"
          prev-class="custom-page-item"
        ></b-pagination>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          limit="5"
          pills
          class="d-flex d-sm-none table-pagination my-0"
          page-class="custom-page-item"
          ellipsis-class="custom-page-item"
          first-class="custom-page-item"
          last-class="custom-page-item"
          next-class="custom-page-item"
          prev-class="custom-page-item"
        ></b-pagination>
      </b-col>
    </b-row>
    <DeletePassword
      :isShow="isShowDeleteAdminModal"
      @onClose="onCloseDeleteItemModal"
      :itemContent="rowItem"
      :key="showDeleteAdminModalKey"
      @reloadFn="callInitFn"
    />
    <EnableDisablePassword
      :isShow="isShowEnableDisableModal"
      @onClose="onCloseEnableDisableModal"
      :itemContent="rowItem"
      :key="showEnableDisableModalKey"
      @reloadFn="callInitFn"
    />
    <!-- :isEnabled="rowIndex" -->
  </b-row>
</template>

<script>
// components
import DeletePassword from "@/components/Passwords/Modals/DeletePassword";
import EnableDisablePassword from "@/components/Passwords/Modals/EnableDisablePassword";

// services
import { GetAllPasswords } from "../../services/passwords.service";

export default {
  name: "PasswordList",
  components: {
    DeletePassword,
    EnableDisablePassword,
  },
  data() {
    return {
      isShowDeleteAdminModal: false,
      isShowEnableDisableModal: false,
      showEnableDisableModalKey: Number(new Date()) + 100,
      showDeleteAdminModalKey: Number(new Date()) + 110,
      rowItem: null,
      searchTable: null,
      isLoading: false,
      adminsHeaderFields: [
        {
          key: "dateCreated",
          label: "Date Created",
          sortable: true,
        },
        {
          key: "passwordCode",
          label: "Password Code",
          sortable: true,
        },
        {
          key: "passwordStatus",
          label: "Status",
          sortable: true,
        },
        {
          key: "actionsState",
          label: "Actions",
        },
      ],
      passwordList: [],
      // table pagination controls
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100, 500],
    };
  },
  mounted() {
    this.initFn();
  },
  computed: {
    totalRows() {
      return this.passwordList.length;
    },
  },
  watch: {
    passwordList(val) {
      const passwords = val.map((pwd) => pwd.passwordCode.password);
      this.$emit("onChangePasswords", passwords);
    },
  },
  methods: {
    onRowSelected(item) {
      // only triggers when the row is selected (not when it gets unselected)
      if (item.length > 0) {
        // get the unique id of the current item
        let _id = item[0]._id;
        console.log("item - ", item);
        // find the row index of the selected row
        let selectedIndex = this.passwordList.findIndex((x) => x._id == _id);
        // iterate through the object array and toggle the showDetails property to show the expanded row on the selected row only
        // the other rows' expanded row is collapsed
        this.passwordList.forEach((element, index) => {
          if (index == selectedIndex) {
            element._showDetails = true;
          } else {
            element._showDetails = false;
          }
        });
      } else {
        // when unselected hides all the row details card
        this.passwordList.forEach((element) => {
          element._showDetails = false;
        });
      }
    },
    openDeleteItemModal(item, button) {
      this.isShowDeleteAdminModal = true;
      this.rowItem = item;
      this.$root.$emit("bv::show::modal", "delete-admin-row-modal", button);
    },
    onCloseDeleteItemModal() {
      this.isShowDeleteAdminModal = false;
    },
    openEnabDisabModal(item, button) {
      this.isShowEnableDisableModal = true;
      this.rowItem = item;
      this.$root.$emit(
        "bv::show::modal",
        "enable-disable-admin-row-modal",
        button
      );
    },
    onCloseEnableDisableModal() {
      this.isShowEnableDisableModal = false;
    },
    async initFn() {
      try {
        this.isLoading = true;
        let { data } = await GetAllPasswords();
        console.log(data);
        if (data.code == 200 && data.message == "success") {
          this.passwordList = data.content.map((x) => {
            return {
              _id: x._id,
              dateCreated: {
                date: x.created_at.split("T")[0],
              },
              passwordCode: {
                password: x.password,
              },
              passwordStatus: x.isEnabled,
              actionsState: {
                details: x,
                state: x.isEnabled,
              },
            };
          });
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Password List",
            content: "Failed to retrieve data! please check again",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        console.log(error);
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Password List",
          content: "Failed to retrieve data! please check again: ",
          list: [error.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isLoading = false;
      }
    },
    callInitFn() {
      this.showDeleteAdminModalKey = Number(new Date()) + 100;
      this.showEnableDisableModalKey = Number(new Date()) + 110;
      this.initFn();
    },
  },
};
</script>

<style scoped>
/* table container */
.main-table {
  min-height: 592px;
}
.search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 520;
}
.search-input-icon {
  border-radius: 5px 0px 0px 5px;
  border-color: var(--prime-gray-muted);
  box-shadow: 0px 2px 8px var(--shadow-soft);
}
.search-input-group .search-input {
  border-radius: 0px 5px 5px 0px;
  border: 1px solid rgba(135, 147, 163, 0.25);
  height: 50px;
}
.search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}
.search-input-group .search-input:focus {
  box-shadow: none;
}
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
.per-page-con {
  border-color: var(--prime-gray-muted);
  box-shadow: 0px 2px 8px var(--shadow-soft);
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.table-container-row .table-element {
  min-width: 480px;
}
/* table element css */
.table-element >>> .table-header {
  background-color: transparent;
}
.table-element >>> .table-header th {
  color: rgba(135, 147, 163, 1);
  font-size: 0.9rem;
  font-weight: 400;
  border-color: transparent;
  font-family: "Roboto", sans-serif;
}
.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}
.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}
.table-element >>> .table-body td {
  background-color: white;
  vertical-align: middle;
}
.table-element >>> .table-body .actions-button-icon {
  width: 40px;
  height: 40px;
}
.table-element >>> .table-body .actions-dropdown-menu {
  min-width: 200px;
}
.table-element
  >>> .table-body
  .actions-dropdown-menu
  button.dropdown-item.active,
.table-element
  >>> .table-body
  .actions-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}
.table-element >>> .table-body .actions-dropdown-menu .inner-icon {
  width: 16px;
  height: 16px;
}
.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}
.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}
/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}
.table-pagination >>> .custom-page-item button:hover {
  color: var(--secondary);
  border-color: var(--secondary);
  background-color: var(--light);
}
.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}
.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}
.text-password-status {
  font-size: 0.8rem;
  font-weight: bolder;
}
</style>
