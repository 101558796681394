<template>
<!-- order items modal -->
<b-modal id="enable-disable-admin-row-modal" ref="enableDisableItemModal" :title="`Are you sure you want to ${
      itemContent ? (itemContent.isEnabled ? 'disable' : 'enable') : ''
    } this password?`" size="lg" header-class="font-primary" header-bg-variant="white" header-text-variant="secondary" header-border-variant="white" footer-bg-variant="gray-300" footer-border-variant="light" @hide="closeModal()">
    <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <div class="
          d-flex
          flex-column flex-sm-row
          align-items-center
          justify-content-center justify-content-sm-end
          mx-0
          w-100
        ">
            <FormButton variant="light" isBlock class="my-2 my-sm-0 mr-0 mr-sm-2 font-weight-normal text-secondary action-btn" @click.native="cancel">Cancel</FormButton>

            <FormButton isBlock :variant="itemContent.isEnabled ? 'danger' : 'secondary'" :disabled="isLoading" :isLoading="isLoading" type="button" class="my-2 my-sm-0 ml-0 ml-sm-2 border action-btn" :class="itemContent.isEnabled ? 'border-danger' : 'border-secondary'" @click.native="enableDisableFn">
                {{
            itemContent ? (itemContent.isEnabled ? "Disable" : "Enable") : ""
          }}
            </FormButton>
        </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
        <font-awesome-icon icon="times" class="text-secondary" @click="closeModal()" aria-label="Close Modal"></font-awesome-icon>
    </template>
    <b-row class="
        d-flex
        flex-row
        align-items-start
        justify-content-start
        w-100
        table-container-row
      " no-gutters>
        <p class="text-left text-prime-gray mb-4">
            Are you sure you want to
            {{ itemContent ? (itemContent.isEnabled ? "disable" : "enable") : "" }}
            the <b>{{ itemContent ? itemContent.type : "" }}</b> password of value
            <b>{{ itemContent ? itemContent.password : "" }}</b>? This action can always be revoked.
        </p>
    </b-row>
</b-modal>
</template>

<script>
// components
import FormButton from "@/components/Form/FormButton";

// services
import {
    EnableDisablePassword
} from "@/services/passwords.service";

export default {
    name: "EnableDisablePassword",
    components: {
        FormButton,
    },
    data() {
        return {
            isLoading: false,
        };
    },
    props: {
        isShow: {
            default: false,
            type: Boolean,
        },
        itemContent: {
            default: null,
        },
    },
    computed: {
        notifyMsg() {
            if (this.itemContent) {
                return this.itemContent.isEnabled ? "disabled" : "enabled";
            } else {
                return "";
            }
        },
    },
    methods: {
        closeModal() {
            this.$emit("onClose");
        },
        enableDisableFn() {
            if (!this.itemContent || this.isLoading) return false;
            let payload = {
                id: this.itemContent._id,
            };
            this.isLoading = true;
            EnableDisablePassword(payload)
                .then((res) => {
                    if (res.data.code == 200 && res.data.message == "success") {
                        var payloadNotify = {
                            isToast: true,
                            title: this.notifyMsg + " Password",
                            content: "The password (" +
                                this.itemContent.password +
                                ") has been " +
                                this.notifyMsg +
                                " successfully.",
                            variant: "success",
                        };
                        this.$store.dispatch("notification/setNotify", payloadNotify);
                    } else
                        this.handleErrorFn([res.data.errorCode])
                })
                .catch((err) => {
                    console.log(err);
                    this.handleErrorFn([err.message]);
                })
                .finally(() => {
                    this.isLoading = false;
                    this.$emit("reloadFn");
                });
        },
        handleErrorFn(list) {
            var payloadNotify = {
                isToast: true,
                title: "ERROR! Password Status",
                content: "The password (" +
                    this.itemContent.password +
                    ") was not " +
                    this.notifyMsg + ".",
                list,
                variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
        }
    },
};
</script>

<style scoped>
.action-btn {
    height: 50px;
    min-width: 180px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .action-btn {
        width: 100%;
    }
}
</style>
