<template>
  <b-form-group
    :id="groupId"
    :label="label"
    :label-for="id"
    ref="formGroup"
    label-class="form-label"
    :description="description"
    :invalid-feedback="invalidFeedback"
    :state="state"
    :class="[
      computeFileValidation ? 'is-filled' : '',
      isFocused ? 'is-focus' : '',
      isDisabled ? 'is-disabled' : '',
    ]"
  >
    <b-form-file
      :accept="accept"
      :required="isRequired"
      :placeholder="placeholder"
      :state="state"
      :ref="id"
      :form="form"
      :value="value"
      :disabled="isDisabled"
      no-drop
      @input="(e) => updateInput(e)"
      @change="(e) => localChangeFn(e)"
    ></b-form-file>
  </b-form-group>
</template>

<script>
/* eslint-disable no-console */
export default {
  name: "FormFile",
  data() {
    return {
      localValue: null,
      isFocused: false,
    };
  },
  computed: {
    computeFileValidation() {
      // check if the video file is a valid video file in the mp4 format
      if ("File" in window && this.localValue instanceof File) {
        return true;
      } else return false;
    },
  },
  props: {
    id: String,
    groupId: String,
    accept: String,
    label: String,
    description: {
      default: "",
      type: String,
    },
    isRequired: Boolean,
    placeholder: String,
    invalidFeedback: {
      default: "",
      type: String,
    },
    isDisabled: {
      default: false,
      type: Boolean,
    },
    state: {
      default: null,
      type: Boolean,
    },
    form: {
      default: "",
      type: String,
    },
    value: {
      default: null,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.localValue = val;
      },
    },
  },
  mounted() {
    this.localValue = this.value;
  },
  methods: {
    updateInput(val) {
      this.localValue = val;
      this.$emit("input", val);
    },
    localChangeFn(val) {
      this.isFocused = false;
      this.$emit("change", val.target.value);
    },
  },
};
</script>
<!-- "unscoped" attribute for global CSS -->
<style scoped>
/* == FORM GROUP STYLING == */
/* customized form control */
.form-group {
  position: relative;
}
.form-group >>> .custom-file {
  background-color: transparent;
  z-index: 5;
  border-radius: 4px;
  min-height: 50px;
}
/* == FORM INPUT STYLING == */
/* form input control styling (default state) */
.form-group >>> input.custom-file-input,
.form-group >>> label.custom-file-label {
  background-color: transparent;
  z-index: 5;
  border-radius: 4px;
  min-height: 50px;
  border-color: var(--stroke-light);
  box-shadow: 0px 2px 8px var(--shadow-soft);
}
/* form input control error state styling (valid state) */
.form-group >>> .custom-file-input.is-valid,
.form-group >>> .custom-file-input.is-valid ~ label.custom-file-label {
  border-color: var(--success);
}
/* form input control error state styling (invalid state) */
.form-group >>> .custom-file-input.is-invalid,
.form-group >>> .custom-file-input.is-invalid ~ label.custom-file-label {
  border-color: var(--danger);
}
/* form input control focused styling (focus state) */
.form-group >>> .custom-file-input.is-focus,
.form-group >>> .custom-file-input.is-focus ~ label.custom-file-label {
  z-index: 0;
}
.form-group >>> .custom-file-input:focus,
.form-group >>> .custom-file-input:focus ~ label.custom-file-label {
  box-shadow: none;
  border-color: var(--info);
}
/* form input control focused error state styling (focus/valid state) */
.form-group >>> .custom-file-input.is-focus.is-valid,
.form-group >>> .custom-file-input.is-focus.is-valid ~ label.custom-file-label,
.form-group >>> .custom-file-input.is-valid:focus,
.form-group >>> .custom-file-input.is-valid:focus ~ label.custom-file-label {
  box-shadow: none;
  border-color: var(--success);
}
/* form input control focused error state styling (focus/invalid state) */
.form-group >>> .custom-file-input.is-focus.is-invalid,
.form-group
  >>> .custom-file-input.is-focus.is-invalid
  ~ label.custom-file-label,
.form-group >>> .custom-file-input.is-invalid:focus,
.form-group >>> .custom-file-input.is-invalid:focus ~ label.custom-file-label {
  box-shadow: none;
  border-color: var(--danger);
}
/* form input control filled styling (filled state) */
.form-group >>> .custom-file-input.is-filled,
.form-group >>> .custom-file-input.is-filled ~ label.custom-file-label {
  border-color: var(--info);
}
/* form input control filled error state styling (filled/valid state) */
.form-group >>> .custom-file-input.is-filled.is-valid,
.form-group
  >>> .custom-file-input.is-filled.is-valid
  ~ label.custom-file-label {
  border-color: var(--success);
}
/* form input control filled error state styling (filled/invalid state) */
.form-group >>> .custom-file-input.is-filled.is-invalid,
.form-group
  >>> .custom-file-input.is-filled.is-invalid
  ~ label.custom-file-label {
  border-color: var(--danger);
}
/* form input control disabled state styling (disabled state) */
.form-group >>> .custom-file-input.is-disabled,
.form-group >>> .custom-file-input.is-disabled ~ label.custom-file-label,
.form-group >>> .custom-file-input:disabled,
.form-group >>> .custom-file-input:disabled ~ label.custom-file-label {
  color: var(--prime-gray);
  border-color: var(--ghost-white);
}
/* == LABEL STYLING == */
/* form label (default state) */
.form-group >>> label.custom-file-label {
  color: var(--prime-gray);
  font-size: 1rem;
  padding: 0 0.75rem;
  /* to match the height of container */
  line-height: 50px;
}
/* form label filled styling (filled/valid state) */
.form-group >>> .custom-file-input.is-valid ~ label.custom-file-label,
.form-group.is-filled >>> label.custom-file-label {
  color: var(--gray-700);
}
/* form label filled styling (filled state) */
.form-group >>> .custom-file-input:disabled ~ label.custom-file-label,
.form-group >>> .custom-file-input.is-disabled ~ label.custom-file-label,
.form-group >>> .custom-file-input.is-focus:disabled ~ label.custom-file-label,
.form-group
  >>> .custom-file-input.is-focus.is-disabled
  ~ label.custom-file-label {
  color: var(--ghost-white);
}
.form-group >>> label.custom-file-label::after {
  border-radius: 0 4px 4px 0;
  height: 100%;
  font-size: 1rem;
  padding: 0 1.25rem;
  /* to match the height of container */
  line-height: 50px;
}
</style>
