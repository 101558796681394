<template>
  <!-- order items modal -->
  <b-modal
    id="delete-info-row-modal"
    ref="deleteItemModal"
    title="Are you sure you want to delete this Info?"
    size="lg"
    header-class="font-primary"
    header-bg-variant="white"
    header-text-variant="secondary"
    header-border-variant="white"
    footer-bg-variant="gray-300"
    footer-border-variant="light"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="
          d-flex
          flex-column flex-sm-row
          align-items-center
          justify-content-center justify-content-sm-end
          mx-0
          w-100
        "
      >
        <FormButton
          variant="light"
          isBlock
          class="
            my-2 my-sm-0
            mr-0 mr-sm-2
            font-weight-normal
            text-secondary
            action-btn
          "
          @click.native="cancel"
          >Cancel</FormButton
        >
        <FormButton
          variant="danger"
          isBlock
          :disabled="isLoading"
          :isLoading="isLoading"
          class="
            my-2 my-sm-0
            ml-0 ml-sm-2
            font-weight-normal
            text-white
            action-btn
          "
          type="button"
          @click.native="deleteFn"
          >Delete</FormButton
        >
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <font-awesome-icon
        icon="times"
        class="text-secondary"
        @click="closeModal()"
        aria-label="Close Modal"
      ></font-awesome-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100"
      no-gutters
    >
      <p class="text-left text-prime-gray mb-4">
        Are you sure you want to delete Info with title
        <b>{{ itemContent ? itemContent.title : "" }}</b
        >? This action cannot be undone.
      </p>
    </b-row>
  </b-modal>
</template>

<script>
// services
import { DeleteManyInfoS } from "@/services/content.service";
import FormButton from "@/components/Form/FormButton";

export default {
  name: "DeleteInfo",
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    itemContent: {
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    FormButton,
  },
  methods: {
    closeModal() {
      this.$emit("onClose");
    },
    async deleteFn() {
      if (!this.itemContent || this.isLoading) return false;
      try {
        this.isLoading = true;
        console.log(this.itemContent._id,"test")
        let { data } = await DeleteManyInfoS(this.itemContent._id);
        if (data.code == 200 && data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Delete Info",
            content:
              "Info with title [" +
              this.itemContent.title +
              "] has been removed successfully.",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.$emit("reloadFn");
        } else this.handleErrorFn([data.errorCode]);
      } catch (error) {
        console.log(error);
        this.handleErrorFn([error.message]);
      } finally {
        this.isLoading = false;
      }
    },
    handleErrorFn(list) {
      var payloadNotify = {
        isToast: true,
        title: "ERROR! Delete Info",
        content:
          "Info with title [" + this.itemContent.title + "] was not removed.",
        list,
        variant: "danger",
      };
      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
  },
};
</script>

<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
