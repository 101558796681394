<template>
  <b-row
    class="d-flex flex-row align-items-start justify-content-start"
    no-gutters
  >
    <!-- Home Hero Content Form -->
    <b-col
      cols="12"
      class="d-flex flex-column align-items-start justify-content-start mb-4"
    >
      <h6
        class="text-left text-dark font-weight-semi-bold font-primary mb-3 mt-3"
      >
        Home (Hero Content)
      </h6>
      <form
        id="hero-content-form"
        class="d-flex flex-column align-items-start justify-content-start w-100"
      >
        <!-- Hero Title row -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-start
            justify-content-start
            w-100
            py-2
          "
          no-gutters
        >
          <b-col cols="12">
            <FormInput
              groupId="hero-title-group"
              id="hero-title-group-input"
              class="text-prime-gray"
              :state="heroTitleError.status"
              :invalidFeedback="`${heroTitleError.message}`"
              label="Hero Title"
              v-model="heroTitle"
              isRequired
              trim
              :counter="heroTitleMaxCount"
              form="hero-content-form"
              type="text"
            ></FormInput>
          </b-col>
        </b-row>
        <!-- Hero Subtitle row -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-start
            justify-content-start
            w-100
            py-2
          "
          no-gutters
        >
          <b-col cols="12">
            <FormInput
              groupId="hero-subtitle-group"
              id="hero-subtitle-group-input"
              class="text-prime-gray"
              :state="heroSubtitleError.status"
              :invalidFeedback="`${heroSubtitleError.message}`"
              label="Hero Subtitle"
              v-model="heroSubtitle"
              isRequired
              trim
              :counter="heroSubtitleMaxCount"
              form="hero-content-form"
              type="text"
            ></FormInput>
          </b-col>
        </b-row>
        <!-- Hero Image row -->
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100"
          no-gutters
        >
          <b-col cols="12" class="my-2">
            <FormFile
              ref="imageUpload"
              groupId="hero-image-group"
              id="hero-image-group-input"
              class="text-prime-gray"
              :placeholder="heroImage ? getFileName(heroImage) : 'Hero Image'"
              :state="heroImageError.status"
              :invalidFeedback="`${heroImageError.message}`"
              accept="image/jpeg, image/png"
              v-model="heroImage"
              isRequired
              trim
              form="hero-content-form"
              type="array"
            ></FormFile>
          </b-col>
        </b-row>
        <!-- Hero Content Error -->
        <b-row
          v-if="saveHeroContentError.status"
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <!-- error message span class -->
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="9"
          >
            <span class="text-danger text-center" style="font-size: 0.8rem">{{
              saveHeroContentError.message
            }}</span>
          </b-col>
        </b-row>
        <!-- Hero Content Save Button -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="6"
          >
            <FormButton
              variant="primary"
              isBlock
              class="font-weight-normal text-white border-dark"
              type="submit"
              @click.native="saveHeroContent"
              :isLoading="isHeroAPILoading"
              :isDisabled="isHeroAPILoading"
            >
              Save Changes
            </FormButton>
          </b-col>
        </b-row>
      </form>
    </b-col>
    <!-- Company Content Form -->
    <b-col
      cols="12"
      class="d-flex flex-column align-items-start justify-content-start mb-4"
    >
      <h6
        class="text-left text-dark font-weight-semi-bold font-primary mb-3 mt-3"
      >
        Home (What We Do)
      </h6>
      <form
        id="what-we-do-form"
        class="d-flex flex-column align-items-start justify-content-start w-100"
      >
        <!-- What We Do Mission text area row -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-start
            justify-content-start
            w-100
            py-2
          "
          no-gutters
        >
          <b-col cols="12">
            <FormInput
              groupId="company-mission-heading-group"
              id="company-mission-heading-group-input"
              class="text-prime-gray"
              :state="companyMissionHeadingError.status"
              :invalidFeedback="`${companyMissionHeadingError.message}`"
              label="Mission Heading"
              v-model="companyMissionHeading"
              isRequired
              trim
              :counter="whatWeDoHeadingMaxCount"
              form="what-we-do-form"
              type="text"
            ></FormInput>
          </b-col>
          <b-col cols="12">
            <FormInput
              groupId="company-mission-text-group"
              id="company-mission-text-group-input"
              class="text-prime-gray"
              :state="companyMissionTextError.status"
              :invalidFeedback="`${companyMissionTextError.message}`"
              label="Mission Text"
              v-model="companyMissionText"
              isRequired
              isTextArea
              trim
              form="what-we-do-form"
              type="text"
            ></FormInput>
          </b-col>
        </b-row>
        <!-- What We Do Vision text area row -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-start
            justify-content-start
            w-100
            py-2
          "
          no-gutters
        >
          <b-col cols="12">
            <FormInput
              groupId="company-vision-heading-group"
              id="company-vision-heading-group-input"
              class="text-prime-gray"
              :state="companyVisionHeadingError.status"
              :invalidFeedback="`${companyVisionHeadingError.message}`"
              label="Vision Heading"
              v-model="companyVisionHeading"
              isRequired
              trim
              :counter="whatWeDoHeadingMaxCount"
              form="what-we-do-form"
              type="text"
            ></FormInput>
          </b-col>
          <b-col cols="12">
            <FormInput
              groupId="company-vision-text-group"
              id="company-vision-text-group-input"
              class="text-prime-gray"
              :state="companyVisionTextError.status"
              :invalidFeedback="`${companyVisionTextError.message}`"
              label="Vision Text"
              v-model="companyVisionText"
              isRequired
              isTextArea
              trim
              form="what-we-do-form"
              type="text"
            ></FormInput>
          </b-col>
        </b-row>
        <!-- What We Do Methodology text area row -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-start
            justify-content-start
            w-100
            py-2
          "
          no-gutters
        >
          <b-col cols="12">
            <FormInput
              groupId="company-methodology-heading-group"
              id="company-methodology-heading-group-input"
              class="text-prime-gray"
              :state="companyMethodologyHeadingError.status"
              :invalidFeedback="`${companyMethodologyHeadingError.message}`"
              label="Methodology Heading"
              v-model="companyMethodologyHeading"
              isRequired
              trim
              :counter="whatWeDoHeadingMaxCount"
              form="what-we-do-form"
              type="text"
            ></FormInput>
          </b-col>
          <b-col cols="12">
            <FormInput
              groupId="company-methodology-text-group"
              id="company-methodology-text-group-input"
              class="text-prime-gray"
              :state="companyMethodologyTextError.status"
              :invalidFeedback="`${companyMethodologyTextError.message}`"
              label="Methodology Text"
              v-model="companyMethodologyText"
              isRequired
              isTextArea
              trim
              form="what-we-do-form"
              type="text"
            ></FormInput>
          </b-col>
        </b-row>
        <!-- What We Do Content Error -->
        <b-row
          v-if="saveWhatWeDoError.status"
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <!-- error message span class -->
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="9"
          >
            <span class="text-danger text-center" style="font-size: 0.8rem">{{
              saveWhatWeDoError.message
            }}</span>
          </b-col>
        </b-row>
        <!-- What We Do Content Save Button -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="6"
          >
            <FormButton
              variant="primary"
              isBlock
              class="font-weight-normal text-white border-dark"
              type="submit"
              @click.native="saveWhatWeDoContent"
              :isLoading="isWhatWeDoAPILoading"
              :isDisabled="isWhatWeDoAPILoading"
            >
              Save Changes
            </FormButton>
          </b-col>
        </b-row>
      </form>
    </b-col>
    <!-- Demo Video Content Form -->
    <b-col
      cols="12"
      class="d-flex flex-column align-items-start justify-content-start"
    >
      <h6
        class="text-left text-dark font-weight-semi-bold font-primary mb-3 mt-3"
      >
        Home (Demo Video)
      </h6>
      <form
        id="demo-video-form"
        class="d-flex flex-column align-items-start justify-content-start w-100"
      >
        <!-- Demo Video YouTube Link (Youtube Video) -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-start
            justify-content-start
            w-100
            py-2
          "
          no-gutters
        >
          <b-col cols="12">
            <FormInput
              groupId="demo-video-link-group"
              id="demo-video-link-group-input"
              class="text-prime-gray"
              :state="demoVideoLinkError.status"
              :invalidFeedback="`${demoVideoLinkError.message}`"
              label="YouTube Video Link"
              v-model="demoVideoLink"
              isRequired
              trim
              form="demo-video-form"
              type="text"
            ></FormInput>
          </b-col>
        </b-row>
        <!-- Demo Video Preview Image -->
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100"
          no-gutters
        >
          <b-col cols="12" class="my-2">
            <FormFile
              ref="imageUpload"
              groupId="demo-video-preview-image-group"
              id="demo-video-preview-image-group-input"
              class="text-prime-gray"
              :placeholder="
                demoVideoPreviewImage
                  ? getFileName(demoVideoPreviewImage)
                  : 'Video Preview Image'
              "
              :state="demoVideoPreviewImageError.status"
              :invalidFeedback="`${demoVideoPreviewImageError.message}`"
              accept="image/jpeg, image/png"
              v-model="demoVideoPreviewImage"
              isRequired
              trim
              form="demo-video-form"
              type="file"
            ></FormFile>
          </b-col>
        </b-row>
        <!-- Demo Video Content Error -->
        <b-row
          v-if="saveDemoVideoError.status"
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <!-- error message span class -->
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="9"
          >
            <span class="text-danger text-center" style="font-size: 0.8rem">{{
              saveDemoVideoError.message
            }}</span>
          </b-col>
        </b-row>
        <!-- Demo Video Content Save Button -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="6"
          >
            <FormButton
              variant="primary"
              isBlock
              class="font-weight-normal text-white border-dark"
              type="submit"
              @click.native="saveDemoVideoContent"
              :isLoading="isDemoVideoAPILoading"
              :isDisabled="isDemoVideoAPILoading"
            >
              Save Changes
            </FormButton>
          </b-col>
        </b-row>
      </form>
    </b-col>
    <!-- overlay loader -->
    <b-overlay :show="initLoading" class="mx-n3" no-wrap></b-overlay>
  </b-row>
</template>

<script>
// components
import FormButton from "@/components/Form/FormButton";
import FormInput from "@/components/Form/FormInput";
import FormFile from "@/components/Form/FormFile";

// services
import {
  SaveHeroBody,
  SaveHeroImage,
  GetHeroBody,
  SaveWhatWeDoNext,
  GetWhatWeDoNext,
  SaveDemoVideo,
  GetDemoVideo,
  SaveDemoVideoPreview,
} from "@/services/content.service";

export default {
  name: "HomeContent",
  components: {
    FormButton,
    FormInput,
    FormFile,
  },
  async created() {
    // start loader
    this.initLoading = true;
    // populate all the fields
    await this.populateHeroBody();
    await this.populateWhatWeDo();
    await this.populateDemoVideoLink();

    // stop loader
    this.initLoading = false;
  },
  data() {
    return {
      // overall loader
      initLoading: false,
      // hero content
      heroTitle: null,
      heroTitleMaxCount: 62,
      heroSubtitle: null,
      heroSubtitleMaxCount: 100,
      heroImage: null,
      isHeroAPILoading: false,
      // company text content
      whatWeDoHeadingMaxCount: 24,
      companyMissionHeading: null,
      companyMissionText: null,
      companyVisionHeading: null,
      companyVisionText: null,
      companyMethodologyHeading: null,
      companyMethodologyText: null,
      isWhatWeDoAPILoading: false,
      // demo video content
      demoVideoLink: null,
      demoVideoPreviewImage: null,
      isDemoVideoAPILoading: false,
      // hero content error
      heroTitleError: {
        status: null,
        message: "",
      },
      heroSubtitleError: {
        status: null,
        message: "",
      },
      heroImageError: {
        status: null,
        message: "",
      },
      saveHeroContentError: {
        status: null,
        message: "",
      },
      // company text error
      companyMissionHeadingError: {
        status: null,
        message: "",
      },
      companyMissionTextError: {
        status: null,
        message: "",
      },
      companyVisionHeadingError: {
        status: null,
        message: "",
      },
      companyVisionTextError: {
        status: null,
        message: "",
      },
      companyMethodologyHeadingError: {
        status: null,
        message: "",
      },
      companyMethodologyTextError: {
        status: null,
        message: "",
      },
      saveWhatWeDoError: {
        status: null,
        message: null,
      },
      // demo video error
      demoVideoLinkError: {
        status: null,
        message: "",
      },
      demoVideoPreviewImageError: {
        status: null,
        message: "",
      },
      saveDemoVideoError: {
        status: null,
        message: null,
      },
    };
  },
  watch: {
    heroTitle(val) {
      if (val) {
        this.heroTitleError.status = true;
        if (val.length <= this.heroTitleMaxCount) {
          this.heroTitleError.status = true;
        } else {
          this.heroTitleError.status = false;
          this.heroTitleError.message = `Hero Title should have less than ${this.heroTitleMaxCount} characters`;
        }
      } else {
        this.heroTitleError.status = false;
        this.heroTitleError.message = "Please fill in the Hero Title";
      }
    },
    heroSubtitle(val) {
      if (val) {
        this.heroSubtitleError.status = true;
        if (val.length <= this.heroSubtitleMaxCount) {
          this.heroSubtitleError.status = true;
        } else {
          this.heroSubtitleError.status = false;
          this.heroSubtitleError.message = `Hero Subtitle should have less than ${this.heroSubtitleMaxCount} characters`;
        }
      } else {
        this.heroSubtitleError.status = false;
        this.heroSubtitleError.message = "Please fill in the Hero Subtitle";
      }
    },
    heroImage(val) {
      // validate if it is a file
      if ("File" in window && val instanceof File) {
        // check if it is an image file of type png or jpeg
        if ((val.type && val.type == "image/jpeg") || val.type == "image/png") {
          // check if the video's size is less than 5mb
          if (val.size && val.size < 5 * 1024 * 1024) {
            // clear any error and mark form file as valid
            this.heroImageError.status = true;
            this.heroImageError.message = "";
          } else {
            this.heroImageError.status = false;
            this.heroImageError.message =
              "Please upload an image file that is less than 5MB in size";
          }
        } else {
          this.heroImageError.status = false;
          this.heroImageError.message =
            "Please upload a valid image file in either .jpeg or .png formats";
        }
      } else {
        this.heroImageError.status = false;
        this.heroImageError.message = "Please upload a valid file";
      }
    },
    demoVideoPreviewImage(val) {
      // validate if it is a file
      if ("File" in window && val instanceof File) {
        // check if it is an image file of type png or jpeg
        if ((val.type && val.type == "image/jpeg") || val.type == "image/png") {
          // check if the video's size is less than 5mb
          if (val.size && val.size < 5 * 1024 * 1024) {
            // clear any error and mark form file as valid
            this.demoVideoPreviewImageError.status = true;
            this.demoVideoPreviewImageError.message = "";
          } else {
            this.demoVideoPreviewImageError.status = false;
            this.demoVideoPreviewImageError.message =
              "Please upload an image file that is less than 5MB in size";
          }
        } else {
          this.demoVideoPreviewImageError.status = false;
          this.demoVideoPreviewImageError.message =
            "Please upload a valid image file in either .jpeg or .png formats";
        }
      } else {
        this.demoVideoPreviewImageError.status = false;
        this.demoVideoPreviewImageError.message = "Please upload a valid file";
      }
    },
    companyMissionHeading() {
      if (this.companyMissionHeading && this.companyMissionHeading.length > 0) {
        this.companyMissionHeadingError.status = true;
      } else {
        this.companyMissionHeadingError.status = false;
        this.companyMissionHeadingError.message =
          "Please fill in the Company Mission Heading";
      }
    },
    companyMissionText() {
      if (this.companyMissionText && this.companyMissionText.length > 0) {
        this.companyMissionTextError.status = true;
      } else {
        this.companyMissionTextError.status = false;
        this.companyMissionTextError.message =
          "Please fill in the Company Mission Text";
      }
    },
    companyVisionHeading() {
      if (this.companyVisionHeading && this.companyVisionHeading.length > 0) {
        this.companyVisionHeadingError.status = true;
      } else {
        this.companyVisionHeadingError.status = false;
        this.companyVisionHeadingError.message =
          "Please fill in the Company Vision Heading";
      }
    },
    companyVisionText() {
      if (this.companyVisionText && this.companyVisionText.length > 0) {
        this.companyVisionTextError.status = true;
      } else {
        this.companyVisionTextError.status = false;
        this.companyVisionTextError.message =
          "Please fill in the Company Vision Text";
      }
    },
    companyMethodologyHeading() {
      if (
        this.companyMethodologyHeading &&
        this.companyMethodologyHeading.length > 0
      ) {
        this.companyMethodologyHeadingError.status = true;
      } else {
        this.companyMethodologyHeadingError.status = false;
        this.companyMethodologyHeadingError.message =
          "Please fill in the Company Methodology Heading";
      }
    },
    companyMethodologyText() {
      if (
        this.companyMethodologyText &&
        this.companyMethodologyText.length > 0
      ) {
        this.companyMethodologyTextError.status = true;
      } else {
        this.companyMethodologyTextError.status = false;
        this.companyMethodologyTextError.message =
          "Please fill in the Company Methodology Text";
      }
    },
    demoVideoLink(val) {
      // regex expression
      // var p =
      //   /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (val) {
        var p =
          /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        var matches = val.match(p);
        if (matches) {
          this.demoVideoLinkError.status = true;
          return matches[1];
        } else {
          this.demoVideoLinkError.status = false;
          this.demoVideoLinkError.message =
            "Please upload a YouTube watch URL (i.e.: https://www.youtube.com/watch?v=VgclYHW74lE)";
        }
        return false;
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("onClose");
    },
    // get file from url
    async getFileFromURL(url, name, defaultType = "image/jpeg") {
      if (url && url.length > 0) {
        try {
          const response = await fetch(url);
          const data = await response.blob();
          return new File([data], name, {
            type: response.headers.get("content-type") || defaultType,
          });
        } catch (error) {
          console.log("Unable to read file", error);
          return null;
        }
      } else {
        return null;
      }
    },
    // get file name from file object
    getFileName(object) {
      if (object) return object.name;
      return "Image File";
    },
    // populate hero body data from API
    async populateHeroBody() {
      try {
        var { data } = await GetHeroBody();
        if (data.code == 200 && data.message == "success") {
          let result = data.content;
          if (result) {
            this.heroTitle = result.heroTitle;
            this.heroSubtitle = result.heroSubTitle;
            this.heroImage = await this.getFileFromURL(
              result.heroImage,
              "hero-image.jpg",
              "image/jpeg"
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // populate what we do data from API
    async populateWhatWeDo() {
      try {
        var { data } = await GetWhatWeDoNext();
        if (data.code == 200 && data.message == "success") {
          let result = data.content;
          if (result) {
            this.companyMissionHeading = result.companyMissionHeading;
            this.companyMissionText = result.companyMissionText;
            this.companyVisionHeading = result.companyVisionHeading;
            this.companyVisionText = result.companyVisionText;
            this.companyMethodologyHeading = result.companyMethodologyHeading;
            this.companyMethodologyText = result.companyMethodologyText;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // populate demo video data from API
    async populateDemoVideoLink() {
      try {
        var { data } = await GetDemoVideo();
        if (data.code == 200 && data.message == "success") {
          let result = data.content;
          if (result) {
            this.demoVideoLink = result.videoLink;
            this.demoVideoPreviewImage = await this.getFileFromURL(
              result.previewImageLink,
              "preview-image.jpg",
              "image/jpeg"
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // catch all function for saving hero content data
    async saveHeroContent() {
      try {
        this.isHeroAPILoading = true;
        // check for errors on hero content
        var errors = this.errorHeroContentFn();
        if (errors.length == 0) {
          let payloadA = {
            heroTitle: this.heroTitle,
            heroSubTitle: this.heroSubtitle,
          };
          let resultA = await SaveHeroBody(payloadA);
          let dataA = resultA.data;

          // prepare the payload with FormData
          const formData = new FormData();
          formData.append("heroImage", this.heroImage);
          let resultB = await SaveHeroImage(formData);
          let dataB = resultB.data;

          if (
            dataA &&
            dataA.code == 200 &&
            dataA.message == "success" &&
            dataB &&
            dataB.code == 200 &&
            dataB.message == "success"
          ) {
            let payloadNotify = {
              isToast: true,
              title: "Home (Hero Content)",
              content: "Hero Content was saved successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.$emit("reloadFn");
          }
        } else {
          this.handleErrorFn(
            "It seems like you have missed some important fields when saving Hero Content",
            errors
          );
        }
      } catch (error) {
        this.saveHeroContentError.status = true;
        this.saveHeroContentError.message = `An error occured when saving Hero Content: ${error.message}`;
        this.handleErrorFn("Hero Content was not saved", [error.message]);
      } finally {
        this.isHeroAPILoading = false;
      }
    },
    // catch all function for saving what we do content data
    async saveWhatWeDoContent() {
      // start loader
      try {
        this.isWhatWeDoAPILoading = true;
        var errors = this.errorWhatWeDoContentFn();
        if (errors.length == 0) {
          let payload = {
            companyMissionHeading: this.companyMissionHeading,
            companyMissionText: this.companyMissionText,
            companyVisionHeading: this.companyVisionHeading,
            companyVisionText: this.companyVisionText,
            companyMethodologyHeading: this.companyMethodologyHeading,
            companyMethodologyText: this.companyMethodologyText,
          };
          let { data } = await SaveWhatWeDoNext(payload);
          if (data && data.code == 200 && data.message == "success") {
            let payloadNotify = {
              isToast: true,
              title: " Home (What We Do)",
              content: "What We Do Content was saved successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.$emit("reloadFn");
          }
        } else {
          this.handleErrorFn(
            "It seems like you have missed some important fields when saving What We Do Content",
            errors
          );
        }
      } catch (error) {
        this.saveHomeError.status = true;
        this.saveHomeError.message = `An error occured when saving What We Do Content: ${error.message}`;
        this.handleErrorFn("What We Do Content was not saved", [error.message]);
      } finally {
        this.isWhatWeDoAPILoading = false;
      }
    },
    // catch all function for saving demo video content data
    async saveDemoVideoContent() {
      try {
        this.isDemoVideoAPILoading = true;
        var errors = this.errorDemoVideoFn();
        if (errors.length == 0) {
          let payloadA = {
            demoVideoLink: this.demoVideoLink,
          };
          let resultA = await SaveDemoVideo(payloadA);
          let dataA = resultA.data;

          // prepare the payload with FormData
          const formData = new FormData();
          formData.append("demoVideoPreview", this.demoVideoPreviewImage);
          let resultB = await SaveDemoVideoPreview(formData);
          let dataB = resultB.data;

          if (
            dataA &&
            dataA.code == 200 &&
            dataA.message == "success" &&
            dataB &&
            dataB.code == 200 &&
            dataB.message == "success"
          ) {
            let payloadNotify = {
              isToast: true,
              title: " Home (Demo Video Content)",
              content: "Demo Video Content was saved successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.$emit("reloadFn");
          }
        } else {
          this.handleErrorFn(
            "It seems like you have missed some important fields when saving Demo Video Content",
            errors
          );
        }
      } catch (error) {
        this.saveDemoVideoError.status = true;
        this.saveDemoVideoError.message = `An error occured when saving Demo Video Content: ${error.message}`;
        this.handleErrorFn("Demo Video Content was not saved", [error.message]);
      } finally {
        this.isDemoVideoAPILoading = false;
      }
    },
    // error functions for hero content
    errorHeroContentFn() {
      let errorList = [];
      // hero title validation
      if (!this.heroTitle) {
        this.heroTitleError.status = false;
        this.heroTitleError.message = "Please fill in the Hero Title";
        errorList.push("Please fill in the Hero Title");
      } else if (this.heroTitle.length > this.heroTitleMaxCount) {
        this.heroTitleError.status = false;
        this.heroTitleError.message = `Hero Title should have less than ${this.heroTitleMaxCount} characters`;
        errorList.push(
          `Hero Title should have less than ${this.heroTitleMaxCount} characters`
        );
      } else this.heroTitleError.status = true;

      // hero subtitle validation
      if (!this.heroSubtitle) {
        this.heroSubtitleError.status = false;
        this.heroSubtitleError.message = "Please fill in the Hero Sutitle";
        errorList.push("Please fill in the Hero Sutitle");
      } else if (this.heroSubtitle.length > this.heroSubtitleMaxCount) {
        this.heroSubtitleError.status = false;
        this.heroSubtitleError.message = `Hero Sutitle should have less than ${this.heroSubtitleMaxCount} characters`;
        errorList.push(
          `Hero Sutitle should have less than ${this.heroSubtitleMaxCount} characters`
        );
      } else this.heroSubtitleError.status = true;

      // heroImage validation
      if (!this.heroImage) {
        this.heroImageError.status = false;
        this.heroImageError.message = "Please upload the Hero Image";
        errorList.push("Please upload the Hero Image");
      } else {
        if ("File" in window && this.heroImage instanceof File == false) {
          this.heroImageError.status = false;
          this.heroImageError.message =
            "Please upload a valid file as the Hero Image";
          errorList.push("Please upload a valid file as the Hero Image");
        }
        if (
          this.heroImage.type &&
          this.heroImage.type != "image/jpeg" &&
          this.heroImage.type != "image/png"
        ) {
          console.log("Hero Image File Type", this.heroImage.type);
          this.heroImageError.status = false;
          this.heroImageError.message =
            "Please upload a valid image file in either .jpeg or .png formats";

          errorList.push(
            "Please upload a valid image file in either .jpeg or .png formats"
          );
        }
        if (this.heroImage.size && this.heroImage.size > 5 * 1024 * 1024) {
          this.heroImageError.status = false;
          this.heroImageError.message =
            "Please upload an image file that is less than 5MB in size";

          errorList.push(
            "Please upload an image file that is less than 5MB in size"
          );
        }
      }

      return errorList;
    },
    // error functions for what we do content
    errorWhatWeDoContentFn() {
      let errorList = [];

      // companyMissionHeading validation
      if (!this.companyMissionHeading) {
        this.companyMissionHeadingError.status = false;
        this.companyMissionHeadingError.message =
          "Please fill in the Company Mission Heading";
        errorList.push(this.companyMissionHeadingError.message);
      } else if (
        this.companyMissionHeading.length > this.whatWeDoHeadingMaxCount
      ) {
        this.companyMissionHeadingError.status = false;
        this.companyMissionHeadingError.message = `Company Mission Heading should have less than ${this.whatWeDoHeadingMaxCount} characters`;
        errorList.push(
          `Company Mission Heading should have less than ${this.whatWeDoHeadingMaxCount} characters`
        );
      } else this.companyMissionHeadingError.status = true;

      // companyMissionText validation
      if (!this.companyMissionText) {
        this.companyMissionTextError.status = false;
        this.companyMissionTextError.message =
          "Please fill in the Company Mission Text";
        errorList.push(this.companyMissionTextError.message);
      }

      // companyVisionHeading validation
      if (!this.companyVisionHeading) {
        this.companyVisionHeadingError.status = false;
        this.companyVisionHeadingError.message =
          "Please fill in the Company Vision Heading";
        errorList.push(this.companyVisionHeadingError.message);
      } else if (
        this.companyVisionHeading.length > this.whatWeDoHeadingMaxCount
      ) {
        this.companyVisionHeadingError.status = false;
        this.companyVisionHeadingError.message = `Company Vision Heading should have less than ${this.whatWeDoHeadingMaxCount} characters`;
        errorList.push(
          `Company Vision Heading should have less than ${this.whatWeDoHeadingMaxCount} characters`
        );
      } else this.companyVisionHeadingError.status = true;

      // companyVisionText validation
      if (!this.companyVisionText) {
        this.companyVisionTextError.status = false;
        this.companyVisionTextError.message =
          "Please fill in the Company Vision Text";
        errorList.push(this.companyVisionTextError.message);
      }

      // companyMethodologyHeading validation
      if (!this.companyMethodologyHeading) {
        this.companyMethodologyHeadingError.status = false;
        this.companyMethodologyHeadingError.message =
          "Please fill in the Company Methodology Heading";
        errorList.push(this.companyMethodologyHeadingError.message);
      } else if (
        this.companyMethodologyHeading.length > this.whatWeDoHeadingMaxCount
      ) {
        this.companyMethodologyHeadingError.status = false;
        this.companyMethodologyHeadingError.message = `Company Methodology Heading should have less than ${this.whatWeDoHeadingMaxCount} characters`;
        errorList.push(
          `Company Methodology Heading should have less than ${this.whatWeDoHeadingMaxCount} characters`
        );
      } else this.companyMethodologyHeadingError.status = true;

      // companyMethodologyText validation
      if (!this.companyMethodologyText) {
        this.companyMethodologyTextError.status = false;
        this.companyMethodologyTextError.message =
          "Please fill in the Company Methodology Text";
        errorList.push(this.companyMethodologyTextError.message);
      }

      return errorList;
    },
    // error functions
    errorDemoVideoFn() {
      let errorList = [];

      // link validation
      if (!this.demoVideoLink) {
        this.demoVideoLinkError.status = false;
        this.demoVideoLinkError.message =
          "Please fill in the YouTube Video Link";
        errorList.push(this.demoVideoLinkError.message);
      } else {
        // regex expression
        // var p =
        //   /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        var p =
          /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        var matches = this.demoVideoLink.match(p);
        if (matches) {
          this.demoVideoLinkError.status = true;
        } else {
          this.demoVideoLinkError.status = false;
          this.demoVideoLinkError.message =
            "Please upload a YouTube watch URL (i.e.: https://www.youtube.com/watch?v=VgclYHW74lE)";
          errorList.push(this.demoVideoLinkError.message);
        }
      }

      // demoVideoPreviewImage validation
      if (!this.demoVideoPreviewImage) {
        this.demoVideoPreviewImageError.status = false;
        this.demoVideoPreviewImageError.message =
          "Please upload the video preview image";
        errorList.push(this.demoVideoPreviewImageError.message);
      } else {
        if (
          "File" in window &&
          this.demoVideoPreviewImage instanceof File == false
        ) {
          this.demoVideoPreviewImageError.status = false;
          this.demoVideoPreviewImageError.message =
            "Please upload a valid file as the video preview image";
          errorList.push(this.demoVideoPreviewImageError.message);
        }
        if (
          this.demoVideoPreviewImage.type &&
          this.demoVideoPreviewImage.type != "image/jpeg" &&
          this.demoVideoPreviewImage.type != "image/png"
        ) {
          console.log(this.demoVideoPreviewImage.type);
          this.demoVideoPreviewImageError.status = false;
          this.demoVideoPreviewImageError.message =
            "Please upload a valid image file in either .jpeg or .png formats";
          errorList.push(this.demoVideoPreviewImageError.message);
        }
        if (
          this.demoVideoPreviewImage.size &&
          this.demoVideoPreviewImage.size > 5 * 1024 * 1024
        ) {
          this.demoVideoPreviewImageError.status = false;
          this.demoVideoPreviewImageError.message =
            "Please upload an image file that is less than 5MB in size";
          errorList.push(this.demoVideoPreviewImageError.message);
        }
      }
      return errorList;
    },
    // handle error function
    handleErrorFn(content, list) {
      var payloadNotify = {
        isToast: true,
        title: "ERROR! Home Content",
        content,
        list,
        variant: "danger",
      };
      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
  },
};
</script>
<style scoped>
.editor {
  height: 750px;
  max-height: calc(100vh - 120px);
}
.editor >>> .ql-tooltip {
  z-index: 50;
}
</style>