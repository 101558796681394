import Instance from "./instance";

export const getBlackListWords = async () => {
  console.log(Instance.instanceToken);
  try {
    return await Instance.instanceToken.get(`/blacklist/default`);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addWordToBlackListLibrary = async (payload) => {
  console.log(payload);
  console.log("method called");
  try {
    return await Instance.instanceToken.post("/blacklist/default", payload);
  } catch (error) {
    console.log(error);

    return error;
  }
};

export const deleteWordFromBlackListLibrary = async (payload) => {
  try {
    return await Instance.instanceToken.delete("/blacklist/default", {
      data: payload,
    });
  } catch (error) {
    console.log(error);

    return error;
  }
};

//upload document
export const UploadBlacklist = async (payload) => {
  try {
    return await Instance.instanceFileUpload.post('/blacklist/upload', payload)
  } catch (error) {
    return error;
  }
};
