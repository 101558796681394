import Instance from "./instance";

export const GetEnabledTargetVideos = async () => {
  try {
    return await Instance.instanceToken.get("/targetvideos");
  } catch (error) {
    return error;
  }
};

export const GetAllTargetVideos = async (payload) => {
  try {
    return await Instance.instanceToken.get(`/targetVideos/all`, payload);
  } catch (error) {
    return error;
  }
};


export const GetSingleTargetVideo = async (id) => {
  try {
    return await Instance.instanceToken.get(`/targetvideos/${id}`);
  } catch (error) {
    return error;
  }
};

export const CreateTargetVideo = async (payload) => {
  try {
    return await Instance.instanceFileUpload.post(`/targetvideos`, payload);
  } catch (error) {
    return error;
  }
};

export const UpdateTargetVideo = async (id, payload) => {
  try {
    return await Instance.instanceToken.put(`/targetvideos/${id}`, payload);
  } catch (error) {
    return error;
  }
};

export const DeleteTargetVideo = async (id) => {
  try {
    return await Instance.instanceToken.delete(`/targetvideos/${id}`);
  } catch (error) {
    return error;
  }
};

export const EnableDisableVideo = async (payload) => {
  try {
    return await Instance.instanceToken.put(`/targetVideos/toggleStatus/`, payload);
  } catch (error) {
    return error;
  }
};
