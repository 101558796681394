<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          table-card-row
          w-100
        "
        no-gutters
      >
        <b-card
          no-body
          class="overflow-hidden shadow-sm border-0 table-container-card w-100"
        >
          <!-- table actions row -->
          <b-row
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-between
              w-100
              my-1 my-md-2
              px-3
              py-3
            "
            no-gutters
          >
            <!-- search input column -->
            <b-col
              class="
                d-flex
                flex-column
                align-items-start
                justify-content-center
                px-0
                py-2 py-lg-0
              "
              cols="12"
              lg="4"
            >
              <!-- table search input -->
              <b-input-group class="search-input-group shadow-soft">
                <b-input-group-prepend>
                  <b-row
                    class="
                      search-input-icon
                      d-flex
                      flex-row
                      align-items-center
                      justify-content-center
                      pl-3
                      pr-1
                      border border-stroke-light
                    "
                    no-gutters
                  >
                    <font-awesome-icon
                      class="text-prime-gray"
                      icon="search"
                      size="lg"
                      aria-label="Search"
                    ></font-awesome-icon>
                  </b-row>
                </b-input-group-prepend>
                <b-form-input
                  class="search-input border-left-0 border-stroke-light"
                  type="text"
                  placeholder="Search users"
                  v-model="searchTable"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
                px-0
                py-2 py-lg-0
              "
              cols="12"
              lg="2"
            >
              <FormButton
                variant="light"
                class="font-weight-normal text-dark"
                @click.native="exportUsers"
                :isLoading="isCSVLoading"
                :isDisabled="isCSVLoading"
              >
                <font-awesome-icon
                  class="text-dark"
                  icon="file-export"
                  size="lg"
                  aria-label="Filter"
                ></font-awesome-icon>
                <span class="d-inline ml-2 mr-2 mr-sm-3">Export CSV</span>
              </FormButton>
            </b-col>

            <!-- filter/create actions column -->
            <b-col
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
                px-0
                py-2 py-lg-0
              "
              cols="12"
              lg="8"
            >
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-between justify-content-lg-end
                  w-100
                  mx-0
                "
              >
                <!-- add any filter elements here -->
              </b-row>
            </b-col>
          </b-row>
          <!-- table row -->
          <b-row
            class="
              d-flex
              flex-row
              align-items-start
              justify-content-start
              px-3
              w-100
              table-container-row
            "
            no-gutters
          >
            <b-table
              :bordered="false"
              :borderless="true"
              hover
              show-empty
              class="table-element"
              thead-class="table-header font-secondary"
              tbody-class="table-body"
              :filter="searchTable"
              @filtered="onFiltered"
              :busy="isLoading"
              :per-page="perPage"
              :current-page="currentPage"
              :fields="usersHeader"
              :items="usersItems"
              sortBy="userId"
              :sortDesc="true"
            >
              <!-- :sort-compare="customSortRoutine" -->
              <!-- table busy state -->
              <template #table-busy>
                <div class="text-center text-muted my-3">
                  <b-spinner class="align-middle"></b-spinner>
                  <span class="text-left mx-1">Loading...</span>
                </div>
              </template>
              <!-- table empty state -->
              <template #empty="scope">
                <b-row
                  class="
                    d-flex
                    flex-row
                    align-items-center
                    justfiy-content-center
                    py-5
                  "
                  no-gutters
                >
                  <h6 class="text-center mb-0 mx-auto my-5">
                    {{ scope.emptyText }}
                  </h6>
                </b-row>
              </template>
              <!-- user ID  -->
              <template #cell(userId)="data">
                <router-link
                  class="
                    d-flex
                    flex-column
                    align-items-start
                    justify-content-center
                    text-decoration-none
                  "
                  :to="{
                    name: 'UsersProfile',
                    params: { userId: data.value.id },
                  }"
                >
                  <span class="main-text text-dark mb-2">{{
                    data.value._id
                  }}</span>
                  <span class="text-muted sub-text">{{ data.value.date }}</span>
                </router-link>
              </template>
              <!-- user Details  -->
              <template #cell(userDetails)="data">
                <router-link
                  class="
                    d-flex
                    flex-column
                    align-items-start
                    justify-content-center
                    text-decoration-none
                  "
                  :to="{
                    name: 'UsersProfile',
                    params: { userId: data.value.id },
                  }"
                >
                  <span class="main-text text-dark mb-2">{{
                    data.value.name
                  }}</span>
                  <a
                    class="text-muted sub-text"
                    :href="data.value.email ? `mailto:${data.value.email}` : ''"
                    >{{ data.value.email }}</a
                  >
                </router-link>
              </template>
              <!-- video link column -->
              <template #cell(videoUrl)="data">
                <router-link
                  class="
                    d-flex
                    flex-column
                    align-items-start
                    justify-content-center
                    text-decoration-none
                  "
                  :to="{
                    name: 'UsersProfile',
                    params: { userId: data.value.id },
                  }"
                >
                  <b-row
                    class="
                      d-flex
                      flex-row
                      align-items-center
                      justify-content-start
                    "
                  >
                    <span class="main-text text-dark mr-2">
                      <!-- on image error (i.e. when URL does not load), use the placeholder image instead -->
                      <b-img
                        :src="data.value.targetVideoImage"
                        style="width: 35px; height: 20px; object-fit: cover"
                        rounded="sm"
                        @error="
                          data.value.targetVideoImage = getPlaceholderImageURL
                        "
                      />
                    </span>
                    <span class="main-text text-dark ml-1">{{
                      data.value.targetVideoTitle
                    }}</span>
                  </b-row>
                </router-link>
              </template>
              <!-- actions column -->
              <template #cell(actions)="data">
                <router-link
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    text-decoration-none
                  "
                  :to="{
                    name: 'UsersProfile',
                    params: { userId: data.value },
                  }"
                >
                  <b-button
                    variant="outline-dark"
                    v-b-tooltip.hover.left
                    title="View User Details"
                    class="shadow-sm ml-auto border border-prime-gray more-btn"
                    pill
                  >
                    <font-awesome-icon
                      icon="chevron-right"
                      aria-label="View User Details"
                      class="mx-auto more-icon"
                      size="lg"
                    ></font-awesome-icon>
                  </b-button>
                </router-link>
              </template>
            </b-table>
          </b-row>
          <!-- table controls -->
          <!-- safari fix (flex-shrink-0) -->
          <b-row
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-center justify-content-md-end
              flex-shrink-0
              px-3
              py-2
              w-100
            "
            no-gutters
          >
            <!-- pages per page control -->
            <b-col
              class="
                d-flex
                flex-column
                align-items-center
                align-items-sm-start
                align-items-md-end
                align-items-xl-start
                justify-content-center
                py-2 py-md-1
              "
              cols="12"
              sm="6"
              lg="5"
              xl="2"
            >
              <b-form-group
                label="Per page"
                label-cols="12"
                label-cols-sm="6"
                label-align="left"
                label-align-sm="right"
                label-size="sm"
                label-for="perPageSelect"
                class="mb-0 w-100"
              >
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  class="per-page-select border-1 py-0"
                  :options="pageOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <!-- pagination controls -->
            <b-col
              class="
                d-flex
                flex-column
                align-items-center align-items-sm-end
                justify-content-center
                py-2 py-md-1
              "
              cols="12"
              sm="6"
              lg="4"
              xl="3"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="right"
                size="sm"
                limit="4"
                pills
                class="d-none d-sm-flex table-pagination my-0"
                page-class="custom-page-item"
                ellipsis-class="custom-page-item"
                first-class="custom-page-item"
                last-class="custom-page-item"
                next-class="custom-page-item"
                prev-class="custom-page-item"
              ></b-pagination>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                limit="5"
                pills
                class="d-flex d-sm-none table-pagination my-0"
                page-class="custom-page-item"
                ellipsis-class="custom-page-item"
                first-class="custom-page-item"
                last-class="custom-page-item"
                next-class="custom-page-item"
                prev-class="custom-page-item"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>
<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import FormButton from "@/components/Form/FormButton";

// services
import { GetAllUsers } from "@/services/users.service";

// utils

export default {
  name: "Users",
  components: {
    HomeLayout,
    FormButton,
  },
  data() {
    return {
      rowItem: null,
      rowIndex: null,
      searchTable: null,
      isLoading: false,
      isCSVLoading: false,
      usersItems: [],
      usersHeader: [
        // {
        //   key: "isBogus",
        //   label: "",
        //   sortable: true,
        // },
        {
          key: "userId",
          label: "ID",
          sortable: true,
        },
        {
          key: "userDetails",
          label: "Name",
          sortable: true,
        },
        {
          key: "videoUrl",
          label: "Video",
          sortable: true,
        },
        {
          key: "actions",
          label: "View More",
          class: "text-right",
        },
      ],
      // table pagination controls
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100, 500],
      baseURL: process.env.VUE_APP_URL,
    };
  },
  computed: {
    totalRows() {
      return this.usersItems && this.usersItems.length
        ? this.usersItems.length
        : 0;
    },
    getPlaceholderImageURL() {
      return "https://storage.googleapis.com/personalpac/dev/compliance-slides/white-placeholder.jpg";
    },
  },
  async mounted() {
    this.initFn();
  },
  methods: {
    // initial function to get all the user data
    async initFn() {
      try {
        this.isLoading = true;
        let { data } = await GetAllUsers();
        if (data.code == 200 || data.message == "success") {
          this.usersItems = data.content.map((x, index) => {
            return {
              userId: {
                _id: `U${index + 1}`,
                id: x._id,
                date: x.created_at.split("T")[0],
              },
              userDetails: {
                id: x._id,
                name: x.firstName + " " + x.lastName,
                email: x.email,
              },
              videoUrl: {
                id: x._id,
                targetVideoImage: x.targetVideoPreviewImage,
                targetVideoTitle: x.targetVideoTitle,
              },
              actions: x._id,
            };
          });
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Users",
            content: "Unable to retrieve users' data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    // function to evoke when filteration is applied
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
    },
    // function to get image url if working or a placeholder image if the url does not work/load
    getImageURL(imageURL) {
      fetch(imageURL, { method: "HEAD" })
        .then((res) => {
          if (res.ok) {
            // if image url is valid/exists send that url
            console.log(`Image [${imageURL}] exists.`);
            return imageURL;
          } else {
            // if image url is invalid/doesn't exists send the placeholder url
            console.log(`Image [${imageURL}] does not exist.`);
            return "https://storage.googleapis.com/personalpac/dev/compliance-slides/white-placeholder.jpg";
          }
        })
        .catch((err) => {
          // if there is an error send the placeholder url
          console.log("Error:", err);
          return "https://storage.googleapis.com/personalpac/dev/compliance-slides/white-placeholder.jpg";
        });
    },
    // export all users
    async exportUsers() {
      try {
        this.isCSVLoading = true;
        window.open(this.baseURL + "/user/export", "_blank");
      } catch (error) {
        console.log(error);
      } finally {
        this.isCSVLoading = false;
      }
    },
  },
};
</script>

<style scoped>
/* insights card */
.insight-cards-row {
  height: 100px;
  width: 100%;
  background-color: var(--white);
  border-radius: 0;
  border: 1px solid var(--light);
}
/* table card */
.table-card-row {
  height: 100%;
}
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 16px 16px 16px 16px;

  border: 1px solid var(--light);
}
.table-card-row .search-input-group {
  /* box-shadow: none; */
  height: 50px;
  max-width: 350px;
}
.table-card-row .search-input-group .search-input-icon {
  border-radius: 8px 0px 0px 8px;
  box-shadow: none;
}
.table-card-row .search-input-group .search-input {
  border-radius: 0px 8px 8px 0px;
  box-shadow: none;
  height: 50px;
}
.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}
.table-card-row .search-input-group .search-input:focus {
  border-color: var(--stroke-light);
}
/* filter/country/export dropdown styling */
.table-card-row .filter-dropdown,
.table-card-row .country-dropdown,
.table-card-row .export-dropdown {
  height: 50px;
  min-width: 200px;
}
/* filter/country/export dropdown menu styling */
.table-card-row .filter-dropdown >>> .filter-dropdown-menu,
.table-card-row .country-dropdown >>> .country-dropdown-menu,
.table-card-row .export-dropdown >>> .export-dropdown-menu {
  min-width: 200px;
}
/* country dropdown menu styling */
.table-card-row .country-dropdown >>> .country-dropdown-menu {
  height: auto;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
/* filter/export inner icon styling */
.table-card-row .export-dropdown >>> .export-dropdown-menu .inner-icon,
.table-card-row .filter-dropdown >>> .filter-dropdown-menu .inner-icon {
  width: 16px;
  height: 16px;
}
/* filter/country/export dropdown button styling */
.table-card-row .filter-dropdown >>> .filter-dropdown-menu button,
.table-card-row .country-dropdown >>> .country-dropdown-menu button,
.table-card-row .export-dropdown >>> .export-dropdown-menu button {
  height: 50px;
}
/* button active/selected state styling */
.table-card-row .filter-dropdown >>> .filter-dropdown-menu button.active,
.table-card-row .filter-dropdown >>> .filter-dropdown-menu button:active,
.table-card-row .country-dropdown >>> .country-dropdown-menu button.active,
.table-card-row .country-dropdown >>> .country-dropdown-menu button:active,
.table-card-row .export-dropdown >>> .export-dropdown-menu button.active,
.table-card-row .export-dropdown >>> .export-dropdown-menu button:active {
  background-color: var(--light);
}
/* country dropdown button active text styling */
.table-card-row
  .country-dropdown
  >>> .country-dropdown-menu
  button.active
  span {
  color: var(--dark);
  font-weight: bold;
}
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.table-container-row .table-element {
  min-width: 1000px;
  /* temp fix for make an inner element fill the entire table cell (https://stackoverflow.com/a/56913789) */
  display: table;
  height: 1px;
}
/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}
.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}
.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}
.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}
/* hyperlink custom styling */
.table-element >>> .table-body td {
  vertical-align: middle;
  padding: 0;
  /* temp fix for make an inner element fill the entire table cell (https://stackoverflow.com/a/56913789) */
  height: 100%;
}
.table-element >>> .table-body td > a {
  padding: 0.75rem;
  /* temp fix for make an inner element fill the entire table cell (https://stackoverflow.com/a/56913789) */
  height: 100%;
}
.table-element >>> .table-body .status-badge {
  font-size: 0.8rem;
  min-width: 95px;
}
.table-element >>> .table-body .sub-badge-text {
  font-size: 0.55rem;
}
.table-element >>> .table-body .product-img img {
  object-fit: cover;
}
/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}
/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}
.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}
/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}
/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}
.table-pagination >>> .custom-page-item button:hover {
  color: var(--secondary);
  border-color: var(--secondary);
  background-color: var(--light);
}
.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}
.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .table-card-row .filter-dropdown,
  .table-card-row .export-dropdown {
    min-width: auto;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .insight-cards-row {
    height: auto;
  }
  .table-card-row .search-input-group {
    max-width: none;
  }
}
</style>
