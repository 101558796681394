<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- card container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 card-row"
        no-gutters
      >
        <b-card
          no-body
          class="overflow-hidden border border-light shadow-sm w-100 card-container"
        >
          <b-row class="px-3 py-0 body-container" no-gutters>
            <!-- left column -->
            <b-col class="px-0 px-md-2 py-3 left-column">
              <!-- onboarding fee -->
              <h6
                class="text-left text-dark font-weight-semi-bold font-primary mb-2 mt-3"
              >
                Onboarding fees
              </h6>
              <b-row
                class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100 mt-3"
                no-gutters
              >
                <b-col cols="12">
                  <FormInputGroup
                    groupId="onboarding-group"
                    id="onboarding-group-input"
                    v-model="onboardingFee"
                    isRequired
                    :state="onboardingFeeError.status"
                    :invalidFeedback="`${onboardingFeeError.message}`"
                    form="reset-earnings-form"
                    type="number"
                    isAppend
                  >
                    <template v-slot:append>
                      <b-button
                        class="px-3 px-md-4 icon-btn border border-gray overflow-hidden shadow-sm"
                        variant="transparent"
                      >
                        <font-awesome-icon
                          class="text-prime-gray"
                          icon="dollar-sign"
                          aria-label="dollar-sign"
                        ></font-awesome-icon>
                      </b-button>
                    </template>
                  </FormInputGroup>
                </b-col>
              </b-row>
              <!-- onboarding fee -->
              <h6
                class="text-left text-dark font-weight-semi-bold font-primary mb-2 mt-3"
              >
                Minimum contribution amount
              </h6>
              <b-row
                class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100 mt-3"
                no-gutters
              >
                <b-col cols="12">
                  <FormInputGroup
                    groupId="onboarding-group"
                    id="onboarding-group-input"
                    v-model="minimumAmount"
                    isRequired
                    :state="minimumAmountError.status"
                    :invalidFeedback="`${minimumAmountError.message}`"
                    form="reset-earnings-form"
                    type="number"
                    isAppend
                  >
                    <template v-slot:append>
                      <b-button
                        class="px-3 px-md-4 icon-btn border border-gray overflow-hidden shadow-sm"
                        variant="transparent"
                      >
                        <font-awesome-icon
                          class="text-prime-gray"
                          icon="dollar-sign"
                          aria-label="dollar-sign"
                        ></font-awesome-icon>
                      </b-button>
                    </template>
                  </FormInputGroup>
                </b-col>
              </b-row>
              <hr class="border-bottom-1 w-100 bg-light my-4" />
              <h6
                class="text-left text-dark font-weight-semi-bold font-primary mb-2 mt-3"
              >
                GiveProudly commission rate
              </h6>
              <b-row
                class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100 mt-3"
                no-gutters
              >
                <b-col cols="12">
                  <FormInputGroup
                    groupId="commission-rate-group"
                    id="commission-rate-group-input"
                    v-model="commissionRate"
                    isRequired
                    type="number"
                    :state="commissionRateError.status"
                    :invalidFeedback="`${commissionRateError.message}`"
                    form="reset-earnings-form"
                    isAppend
                  >
                    <template v-slot:append>
                      <b-button
                        class="px-3 px-md-4 icon-btn border border-gray overflow-hidden shadow-sm"
                        variant="transparent"
                      >
                        <font-awesome-icon
                          class="text-prime-gray"
                          icon="percent"
                          aria-label="percent"
                        ></font-awesome-icon>
                      </b-button>
                    </template>
                  </FormInputGroup>
                </b-col>
              </b-row>
              <h6
                class="text-left text-dark font-weight-semi-bold font-primary mb-2 mt-3"
              >
                PayPal fees
              </h6>
              <b-row
                class="rounded=0 d-flex flex-row align-items-start justify-content-start w-100 mt-3"
                no-gutters
              >
                <b-col cols="12" lg="5">
                  <FormInputGroup
                    groupId="stripe-rate-group"
                    id="stripe-rate-group-input"
                    v-model="stripeRate"
                    isRequired
                    :state="stripeRateError.status"
                    :invalidFeedback="`${stripeRateError.message}`"
                    form="reset-earnings-form"
                    type="number"
                    isAppend
                  >
                    <template v-slot:append>
                      <b-button
                        class="px-3 px-md-4 icon-btn border border-gray overflow-hidden shadow-sm"
                        variant="transparent"
                      >
                        <font-awesome-icon
                          class="text-prime-gray"
                          icon="percent"
                          aria-label="percent"
                        ></font-awesome-icon>
                      </b-button>
                    </template>
                  </FormInputGroup>
                </b-col>
                <b-col
                  class="d-flex flex-column text-center px-4 ml-2 py-3 left-column"
                >
                  <font-awesome-icon
                    class="text-dark text-center"
                    icon="plus"
                    aria-label="Help"
                    size="lg"
                  ></font-awesome-icon>
                </b-col>
                <b-col cols="12" lg="5">
                  <FormInputGroup
                    groupId="stripe-fee-group"
                    id="stripe-fee-group-input"
                    class="text-muted-gray font-primary contact-number-input bg-white"
                    v-model="stripeFee"
                    :state="stripeFeeError.status"
                    :invalidFeedback="stripeFeeError.message"
                    isRequired
                    trim
                    form="reset-earnings-form"
                    type="number"
                    isPrepend
                  >
                    <template v-slot:prepend>
                      <font-awesome-icon
                        class="text-prime-gray text-center"
                        icon="dollar-sign"
                        aria-label="dollar-sign"
                        size="lg"
                      ></font-awesome-icon>
                    </template>
                  </FormInputGroup>
                </b-col>
              </b-row>
              <!-- form action -->
              <b-row
                class="d-flex flex-row align-items-center justify-content-center w-100"
                no-gutters
              >
                <!-- reset password button -->
                <b-col
                  class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
                  cols="9"
                  sm="6"
                >
                  <FormButton
                    variant="primary"
                    isBlock
                    class="font-secondary font-weight-normal text-white mt-2"
                    type="submit"
                    :isLoading="isAPILoading"
                    @click.native="submitBtn"
                    >Save Changes</FormButton
                  >
                </b-col>
              </b-row>
            </b-col>
            <!-- column divider -->
            <hr class="column-divider align-self-center" />
            <b-col class="px-0 px-md-2 py-3 left-column">
              <div class="inner-row-grid-switch w-100 px-0">
                <h6
                  class="text-left text-dark font-weight-semi-bold font-primary mb-2 mt-3"
                >
                  Manual giveproudly Approval
                </h6>
                <div
                  class="d-flex flex-row align-items-center justify-content-end"
                >
                  <b-form-checkbox
                    class="checkbox-button"
                    v-model="manualApproval"
                    @change="changeManulApproval"
                    name="check-button "
                    switch
                    size="lg"
                    form="create-supplier-form"
                  >
                  </b-form-checkbox>
                </div>
              </div>
              <div class="inner-row-grid-switch w-100 px-0">
                <h6
                  class="text-left text-dark font-weight-semi-bold font-primary mb-2 mt-3"
                >
                  Switch to 'Advanced Credit & Debit Card' Payments
                </h6>
                <div
                  class="d-flex flex-row align-items-center justify-content-end"
                >
                  <b-form-checkbox
                    v-model="switchCardPayment"
                    class="checkbox-button"
                    @change="changeSwitchCardPayment"
                    name="check-button"
                    switch
                    size="lg"
                    form="create-supplier-form"
                  >
                  </b-form-checkbox>
                </div>
              </div>
              <br />
              <hr class="border-bottom-1 w-100 bg-light my-4" />
              <br />

              <div class="inner-row-grid-switch w-100 px-0">
                <h6
                  class="text-left text-dark font-weight-semi-bold font-primary mb-2 mt-3"
                >
                  Publication 78 Data
                </h6>
                <br />
                <br />
                <br />
              </div>
              <form-file
                ref="uploadDocument"
                groupId="document-file-group"
                id="document-file-group-input"
                class="text-prime-gray"
                placeholder="Document File"
                description="Only upload document files"
                v-model="documentFile"
                isRequired
                trim
                form="new-target-document-form"
                type="document"
                :state="documentFileError.status"
                :invalidFeedback="documentFileError.message"
              >
              </form-file>

              <b-col
                class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
              >
                <FormButton
                  variant="primary"
                  isBlock
                  class="font-weight-normal text-white mt-2 border-dark mb-4"
                  type="submit"
                  @click.native="uploadDocumentFn"
                  :isDisabled="isPubLoading"
                  :isLoading="isPubLoading"
                  style="margin: 0 auto"
                  :loadingText="pub78UploadProgress"
                  >Upload Document</FormButton
                >
              </b-col>

              <b-table
                :bordered="false"
                :borderless="true"
                :hover="true"
                :striped="true"
                selectable
                select-mode="single"
                small
                class="table-element"
                thead-class="table-header font-primary"
                tbody-class="table-body"
                :fields="documentFields"
                :items="documentList"
                show-empty
                :busy="isLoading"
                id="publication-table"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #table-busy>
                  <div class="text-center text-muted my-3 main-text-container">
                    <b-spinner class="align-middle"></b-spinner>
                    <span class="text-left mx-1">Loading...</span>
                  </div>
                </template>

                <!-- filedate  -->
                <template #cell(filedate)="data">
                  <b-col
                    class="d-flex flex-column align-items-start justify-content-center col-3 px-0"
                  >
                    <div
                      class="d-flex flex-row align-items-center justify-content-center text-dark main-text-container"
                    >
                      <span class="text-truncate">
                        {{ FormatDate(data.item.created_at) }}
                      </span>
                    </div>
                  </b-col>
                </template>

                <!-- documentFile  -->
                <template #cell(documentFile)="data">
                  <b-col class="px-0">
                    <font-awesome-icon
                      icon="folder-open"
                      class="text-prime-gray mr-2 iconSize"
                      scale="0.4"
                      aria-hidden="true"
                    ></font-awesome-icon>
                    <span class="main-text text-dark mb-2">{{
                      data.item.file_name
                    }}</span>
                  </b-col>

                  <!-- <span class="main-text text-dark mb-2"> View </span> -->
                </template>
                <!-- documentFile  -->
                <template #cell(total)="data">
                  <b-col class="px-0">
                    <span class="main-text text-dark mb-2">{{
                      data.item.total ? data.item.total : 0
                    }}</span>
                  </b-col>

                  <!-- <span class="main-text text-dark mb-2"> View </span> -->
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="documentList.length"
                :per-page="perPage"
                align="right"
                size="sm"
                limit="4"
                pills
                class="d-none d-sm-flex table-pagination my-0"
                page-class="custom-page-item"
                ellipsis-class="custom-page-item"
                first-class="custom-page-item"
                last-class="custom-page-item"
                next-class="custom-page-item"
                prev-class="custom-page-item"
                aria-controls="publication-table"
              ></b-pagination>
              <b-pagination
                v-model="currentPage"
                :total-rows="documentList.length"
                :per-page="perPage"
                align="center"
                size="sm"
                limit="5"
                pills
                class="d-flex d-sm-none table-pagination my-0"
                page-class="custom-page-item"
                ellipsis-class="custom-page-item"
                first-class="custom-page-item"
                last-class="custom-page-item"
                next-class="custom-page-item"
                prev-class="custom-page-item"
                aria-controls="publication-table"
              ></b-pagination>
            </b-col>
            <b-overlay :show="isLoading" no-wrap></b-overlay>
          </b-row>
        </b-card>
      </b-row>
      <!-- BlackList -->
      <b-row
        class="d-flex flex-row align-items-stretch mt-3 justify-content-center w-100 bg-white"
        no-gutters
      >
        <b-cad
          no-body
          class="overflow-hidden border border-light shadow-sm w-100 card-container-first"
        >
          <b-row class="px-1 py-0 first-row" no-gutters>
            <!-- right column -->
            <b-col class="px-3 px-lg-0 px-md-2">
              <!-- Privacy row -->
              <b-row
                class="d-flex flex-row align-items-stretch justify-content-start"
                no-gutters
              >
                <b-col class="col px-lg-3 py-3 border-right">
                  <b-row class="d-flex justify-content-between no-gutters mb-2">
                    <h6>Forbidden Word List Text Library</h6>
                  </b-row>
                  <b-row class="no-gutters d-flex justify-content-center w-100">
                    <b-col class="col">
                      <form-file
                        ref="uploadDocument"
                        groupId="document-file-group"
                        id="document-file-group-input"
                        class="text-prime-gray"
                        placeholder="Forbidden Word List CSV File"
                        v-model="blacklistFile"
                        isRequired
                        trim
                        form="new-target-document-form"
                        type="document"
                        :state="blackListFileError.status"
                        :invalidFeedback="`${blackListFileError.message}`"
                      >
                      </form-file>
                    </b-col>
                  </b-row>
                  <b-row class="no-gutters d-flex justify-content-center w-100">
                    <b-col class="col">
                      <FormInputVue
                        groupId="declarationName-name-group"
                        id="declarationName-name-group-input"
                        class="text-prime-gray"
                        label="Type Here"
                        isRequired
                        trim
                        form="admin-registration-form"
                        type="text"
                        v-model="inputValue"
                      ></FormInputVue>
                    </b-col>
                  </b-row>

                  <b-row class="no-gutters d-flex justify-content-center">
                    <b-col class="d-flex justify-content-center">
                      <FormButton
                        variant="secondary"
                        isBlock
                        class="font-secondary font-weight-normal text-white mt-2 mr-3"
                        type="submit"
                        @click.native="uploadBlacklist"
                        :isLoading="isFileUpload"
                        :disabled="isFileUpload"
                        >Upload</FormButton
                      >
                      <FormButton
                        variant="primary"
                        isBlock
                        class="font-secondary font-weight-normal text-white mt-2"
                        type="submit"
                        :isDisabled="btnDisable"
                        @click.native="addNewWord"
                        >Add to Forbidden Word List</FormButton
                      >
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="col-7 px-lg-3 py-3">
                  <b-overlay :show="isWordListLoading" no-wrap></b-overlay>
                  <h6 class="font-weight-bold">Forbidden Word List</h6>

                  <b-row
                    class="no-gutters mt-3 blacklist-container"
                    no-gutters
                    @scroll="onScroll"
                  >
                    <b-col v-if="isWordFound">
                      <p class="text-center align-middle h3 text-muted">
                        No matching words are found
                      </p>
                    </b-col>
                    <b-col
                      v-else
                      @mouseover="() => mouseHover(index)"
                      @mouseleave="mouseLeave"
                      class="col-4 mb-3 pointer"
                      v-for="(word, index) in wordList"
                      :key="index"
                    >
                      <b-container
                        class="d-flex align-items-center justify-content-start"
                      >
                        <b-container class="word-container">
                          <b-card-text
                            class="text-truncate"
                            v-b-tooltip.hover.top
                            :title="word"
                          >
                            {{ word }}
                          </b-card-text>
                        </b-container>
                        <b-container
                          class="icon-background"
                           @click="iconClick(word, 'blackList', $event.target)"
                          :class="
                            isHovered === index ? 'display' : 'display-none'
                          "
                        >
                          <font-awesome-icon
                            v-b-tooltip.hover
                            icon="trash"
                            aria-label="Delete Slide"
                            class="delete-icon"
                            @click="iconClick(word, 'blackList', $event.target)"
                          ></font-awesome-icon>
                        </b-container>
                      </b-container>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-cad>
      </b-row>
      <DeleteBlackList
        :isShow="isShowDeleteCampaignModal"
        @onClose="onCloseDeleteItemModal"
        :itemContent="rowItem"
        :key="showDeleteCampaignModalKey"
        :apiLoader="deleteApiLoading"
        @deleteBlackList="deleteWordBlackList"
      />
    </b-container>
  </HomeLayout>
</template>

<script>
// components
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";
import FormFile from "@/components/Form/FormFile";
import FormInputVue from "../components/Form/FormInput.vue";
// import DeleteCampaign from "@/components/Audios/DeleteAudios";
import DeleteBlackList from "@/components/Admins/Modals/BlackList/DeleteBlackList";
// services
import { GetMetaData } from "@/services/earnings.service";
import { uploadDocument } from "@/services/earnings.service";
// services
import {
  CreateEarnings,
  GetEarnings,
  ManualApproval,
  SwitchPaymentGateway,
} from "../services/earnings.service";
import {
  getBlackListWords,
  addWordToBlackListLibrary,
  UploadBlacklist,
  deleteWordFromBlackListLibrary,
} from "../services/blackList.service";
export default {
  name: "Earnings",
  components: {
    HomeLayout,
    FormInputGroup,
    FormButton,
    FormFile,
    FormInputVue,
    DeleteBlackList,
  },
  data() {
    return {
      deleteApiLoading: false,
      isShowDeleteCampaignModal: false,
      rowItem: null,
      showDeleteCampaignModalKey: Number(new Date()) + 110,
      currentPage: 1,
      perPage: 5,
      btnDisable: true,
      inputValue: null,
      wordListMap: null,
      isWordListLoading: false,
      isWordFound: false,
      arrayStart: 0,
      arrayDivideSize: 35,
      initialWorldList: [],
      wordList: [],
      sortedArray: [],
      isHovered: null,
      adminId: null,
      isReset: false,
      isLoading: false,
      isAPILoading: false,
      commissionRate: null,
      localValue: null,
      isFocused: false,
      isPubLoading: false,
      isFileUpload: false,
      blackListFileError: {
        status: null,
        message: null,
      },
      commissionRateError: {
        status: null,
        message: "",
      },
      stripeRate: null,
      stripeRateError: {
        status: null,
        message: "",
      },
      stripeFee: null,
      stripeFeeError: {
        status: null,
        message: "",
      },
      onboardingFee: null,
      onboardingFeeError: {
        status: null,
        message: "",
      },
      renewalustomText: null,
      renewalCustomTextError: {
        status: null,
        message: "",
      },
      renewalCustomTextCount: 80,
      manualApproval: undefined,
      minimumAmount: null,
      minimumAmountError: {
        status: null,
        message: "",
      },
      captchakey: null,
      documentFile: null,
      documentFileError: {
        status: null,
        message: null,
      },
      documentList: [],
      documentFields: [
        {
          key: "filedate",
          label: "Uploaded Date & Time",
        },
        {
          key: "documentFile",
          label: "Document",
        },
        {
          key: "total",
          label: "Total",
        },
      ],
      pub78UploadProgress: "Uploading",
      blacklistFile: null,
      modalThatBtn: null,
    };
  },
  computed: {
    computeFileValidation() {
      // check if the video file is a valid video file in the mp4 format
      if ("File" in window && this.localValue instanceof File) {
        return true;
      } else return false;
    },
  },

  mounted() {
    this.initFn();
    this.captchakey = process.env.VUE_APP_CAPTCHA_KEY;
    this.fetchDocument();
    this.fetchWordList();
    this.FormatDate();
  },
  watch: {
    blacklistFile(val) {
      if (val) {
        if (val.type == "text/csv") {
          this.blackListFileError.status = true;
        } else {
          this.blackListFileError.status = false;
          this.blackListFileError.message = "Please upload a only csv file";
        }
      }
    },
    documentFile(val) {
      console.log(val.type);
      if (val.type == "text/plain") {
        this.documentFileError.status = true;
      } else {
        this.documentFileError.status = false;
        this.documentFileError.message = "Please upload a only text file";
      }
    },
    inputValue(val) {
      if (val) {
        this.btnDisable = false;
      } else {
        this.btnDisable = true;
      }
      this.filterWords(val);
    },
    manualApproval(val) {
      console.log("Approval Changed", val);
    },
    commissionRate(val) {
      if (val) {
        this.commissionRateError.status = true;
      } else {
        this.commissionRateError.status = false;
        this.commissionRateError.message = "Please fill in the field";
      }
    },
    stripeRate(val) {
      if (val) {
        this.stripeRateError.status = true;
      } else {
        this.stripeRateError.status = false;
        this.stripeRateError.message = "Please fill in the field";
      }
    },
    stripeFee(val) {
      if (val) {
        this.stripeFeeError.status = true;
      } else {
        this.stripeFeeError.status = false;
        this.stripeFeeError.message = "Please fill in the field";
      }
    },
    onboardingFee(val) {
      if (val) {
        this.onboardingFeeError.status = true;
      } else {
        this.onboardingFeeError.status = false;
        this.onboardingFeeError.message = "Please fill in the field";
      }
    },
    minimumAmount(val) {
      if (val) {
        this.minimumAmountError.status = true;
      } else {
        this.minimumAmountError.status = false;
        this.minimumAmountError.message = "Please fill in the field";
      }
    },
    renewalustomText(val) {
      if (val) {
        this.renewalCustomTextError.status = true;
        if (val.length <= this.renewalCustomTextCount) {
          this.renewalCustomTextError.status = true;
        } else {
          this.renewalCustomTextError.status = false;
          this.renewalCustomTextError.message = `Renewal custom text should have less than ${this.renewalCustomTextCount} characters`;
        }
      } else {
        this.renewalCustomTextError.status = false;
        this.renewalCustomTextError.message =
          "Please fill in the renewal custom Text";
      }
    },
  },
  methods: {
    updateProgress(progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      this.pub78UploadProgress = `Uploading ${percentCompleted}%`;
    },
    FormatDate(val) {
      let objectDate = new Date(val);

      let day = objectDate.getDate();
      let month = objectDate.getMonth() + 1;
      let year = objectDate.getFullYear();

      let hour = objectDate.getHours();
      let minutes = objectDate.getMinutes();
      let hourFormatted = hour < 10 ? `0${hour}` : hour; // hour returned in 24 hour format
      let minuteFormatted = minutes < 10 ? `0${minutes}` : minutes;
      return (
        month +
        "/" +
        day +
        "/" +
        year +
        " " +
        hourFormatted +
        ":" +
        minuteFormatted
      );
    },
    //Load words when scrolling
    onScroll(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      if (scrollTop + offsetHeight >= scrollHeight) {
        this.arrayDivideSize = this.arrayDivideSize + this.arrayDivideSize;
        this.arrayStart++;
        const newLoadedData = this.sortedArray.slice(
          this.arrayStart,
          this.arrayDivideSize
        );
        this.wordList = [...this.wordList, ...newLoadedData];
      }
    },
    //Search Words in blacklist word array
    filterWords(val) {
      if (val) {
        this.wordListMap.forEach((value, key) => {
          if (val.startsWith(key.toLowerCase())) {
            let list = value.filter((word) => {
              if (word.match(val.toLowerCase())) {
                return word;
              }
            });
            this.wordList = list;
            if (list.length === 0) {
              this.btnDisable = false;
              this.isWordFound = true;
            } else {
              this.btnDisable = true;
              this.isWordFound = false;
            }
          }
        });
      } else {
        this.wordList = this.initialWorldList;
      }
    },
    //Sort blacklist word list, split by ',' , remove duplicate words and create a map
    sortingArray(inputArray) {
      let number = [];
      const alpha = Array.from(Array(26)).map((e, i) => i + 65);
      const alphabetArray = alpha.map((x) => String.fromCharCode(x));
      const array = inputArray.split(",").sort();
      let uniqueChars = [...new Set(array)];
      this.sortedArray = uniqueChars;
      const slicedArray = array.slice(this.arrayStart, this.arrayDivideSize);
      this.wordList = slicedArray;
      this.initialWorldList = slicedArray;
      uniqueChars.forEach((str) => {
        if (str.match(/^\d+/)) {
          if (!number.includes(str.charAt(0))) {
            number.push(str.charAt(0));
          }
        }
      });
      const filterOptions = [...number, ...alphabetArray];
      const values = new Map();
      filterOptions.forEach((alpha) => {
        const valList = uniqueChars.filter((word) => {
          if (word.startsWith(alpha.toLowerCase())) {
            return word;
          }
        });
        values.set(alpha, valList);
      });
      this.wordListMap = values;
    },
    mouseHover(index) {
      this.isHovered = index;
    },
    mouseLeave() {
      this.isHovered = null;
    },
    onCloseDeleteItemModal() {
      this.$root.$emit(
        "bv::hide::modal",
        "delete-blackList-row-modal",
        this.modalThatBtn
      );
    },
    iconClick(item, type, button) {
      console.log(button);
      this.isShowDeleteModal = true;
      this.rowItem = item;
      this.modalThatBtn = button;
      this.$root.$emit("bv::show::modal", "delete-blackList-row-modal", button);
    },
    //Fetch word list from API
    async fetchWordList() {
      try {
        this.isWordListLoading = true;
        const res = await getBlackListWords();
        const wordList = res.data.content.wordlist;
        this.sortingArray(wordList);
        this.isWordListLoading = false;
      } catch (error) {
        console.log(error);
        this.isWordListLoading = false;
      }
    },

    //Add new word to the blackList Library
    async addNewWord() {
      try {
        this.isWordListLoading = true;
        let wordListBody = [];
        wordListBody.push(this.inputValue);
        const sendData = { new_words: wordListBody };
        const res = await addWordToBlackListLibrary(sendData);
        const wordList = res.data.content.wordlist;
        this.sortingArray(wordList);
        let payloadNotify = {
          isToast: true,
          title: "BlackList Library",
          content: "Word Added Successfully",
          variant: "success",
        };
        this.isWordFound = false;
        this.isWordListLoading = false;
        this.$store.dispatch("notification/setNotify", payloadNotify);
        this.inputValue = null;
      } catch (error) {
        this.isWordFound = false;
        this.isWordListLoading = false;
      }
    },

    //Delete word From a blackList library

    async deleteWordBlackList(deleteWord) {
      try {
        let wordList = [];
        wordList.push(deleteWord);
        const dataDelete = {
          words: wordList,
        };
        this.deleteApiLoading = true;
        let { data } = await deleteWordFromBlackListLibrary(dataDelete);
        console.log(data);
        if (data.code == 200 || data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "BlackList Library",
            content: `The word has been removed from the blacklist library.`,
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.onCloseDeleteItemModal();
          this.inputValue = null;
          await this.fetchWordList();
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! BlackList Library",
            content: `Unable to delete the word).`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        this.onCloseDeleteItemModal();
      } finally {
        this.deleteApiLoading = false;
      }
    },
    async fetchDocument() {
      console.log("called");
      this.isLoading = true;
      try {
        const response = await GetMetaData();
        console.log(response.data);
        this.documentList = response.data.content;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    async initFn() {
      try {
        this.isLoading = true;
        let { data } = await GetEarnings();
        if (data.code == 200 || data.message == "success") {
          let details = data.content;
          this.manualApproval = details.manual_approval;
          this.switchCard = details.switch_card;
          this.commissionRate = details.platform_percent;
          this.stripeRate = details.stripe_percent;
          this.stripeFee = details.stripe_fixed;
          this.onboardingFee = details.platform_onboarding_fee;
          this.minimumAmount = details.minimum_contribution;
          this.switchCardPayment = details.gateway == "PAYPAL_ADV";
          this.isLoading = false;
        } else {
          this.isLoading = false;
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Earnings details",
            content: "Failed to retrieve data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async submitForm() {
      this.isLoading = true;
      try {
        const response = await uploadDocument(this.documentFile);
        console.log(response); // handle the response as needed
      } catch (error) {
        console.error(error);
        // handle the error as needed
      }
      this.isLoading = false;
    },
    getFileName(file) {
      return file.name;
    },
    async uploadBlacklist() {
      if (!this.blacklistFile) {
        this.blackListFileError.status = false;
        this.blackListFileError.message = "Please upload a csv file";
        return;
      }
      const formData = new FormData();
      formData.append("file", this.blacklistFile);
      this.blacklistFile = null;
      this.isFileUpload = true;
      try {
        const response = await UploadBlacklist(formData);
        console.log("Document file upload response ", response.data);
        let payloadNotify = {
          isToast: true,
          title: "BlackList Library",
          content: "Document Uploaded Successfully",
          variant: "success",
        };
        this.isFileUpload = false;
        this.$store.dispatch("notification/setNotify", payloadNotify);
        await this.fetchWordList();
      } catch (error) {
        console.error(error);
        this.isFileUpload = false;
      }
    },
    async uploadDocumentFn() {
      if (!this.documentFile) {
        this.documentFileError.status = false;
        this.documentFileError.message = "Please upload an document file";
        return;
      }
      console.log(this.documentFile);
      console.log("uploadDocumentFn");
      const formData = new FormData();
      formData.append("file", this.documentFile);
      try {
        this.isPubLoading = true;
        const response = await uploadDocument(formData, this.updateProgress);
        console.log("Document file upload response ", response);
        // this.documentItems.push({
        //   filedate: { date: new Date() },
        //   documentFile: { name: this.documentFile.file.name },
        // });
        this.isPubLoading = false;
        this.documentFile = null;
        this.fetchDocument();
      } catch (error) {
        this.isPubLoading = false;
        console.error(error);
      }
    },

    changeManulApproval() {
      ManualApproval(this.manualApproval).then((res) => {
        let variant = "success";
        let msg;
        if (this.manualApproval) {
          msg = "Manual approval enabled";
        } else {
          msg = "Manual approval disabled";
        }

        if (res.status != 200) {
          variant = "danger";
          msg = "Something went wrong. Please try again later.";
        }

        let payloadNotify = {
          isToast: true,
          title: "Manual Approval",
          content: msg,
          variant,
        };

        this.$store.dispatch("notification/setNotify", payloadNotify);
      });
    },
    changeSwitchCardPayment() {
      const gatewayType = this.switchCardPayment ? "PAYPAL_ADV" : "PAYPAL_STD";
      SwitchPaymentGateway(gatewayType).then((res) => {
        let variant = "success";
        let msg;
        if (gatewayType == "PAYPAL_ADV") {
          msg = "Advance Credit & Debit Card checkout enabled";
        } else {
          msg = "PayPal standard checkout enabled";
        }

        if (res.status != 200) {
          variant = "danger";
          msg = "Something went wrong. Please try again later.";
        }

        let payloadNotify = {
          isToast: true,
          title: "Advance Credit & Debit Card Payments Approval",
          content: msg,
          variant,
        };

        this.$store.dispatch("notification/setNotify", payloadNotify);
      });
    },
    async submitBtn() {
      let errorList = this.errorFn();
      if (errorList.length == 0) {
        let payload = {
          stripe_percent: this.stripeRate,
          stripe_fixed: this.stripeFee,
          platform_percent: this.commissionRate,
          platform_onboarding_fee: this.onboardingFee,
          minimum_contribution: this.minimumAmount,
        };
        this.isAPILoading = true;
        try {
          let res = await CreateEarnings(payload);

          if (res.response && res.response.status != 200) {
            let message = res.response?.data?.others;

            if (!message) {
              message = "Something went wrong";
            }

            let payloadNotify = {
              isToast: true,
              title: "Create Earnings",
              content: "Details cannot be saved",
              list: [message],
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            return;
          }

          const data = res.data;

          if (data.code == 200 && data.message == "success") {
            console.log(data, "testing");
            let payloadNotify = {
              isToast: true,
              title: "Create Earnings",
              content: `Details has been successfully created`,
              variant: "success",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.isInfoSubmitted = true;
            this.initFn();
            try {
              this.onCaptchaExpired();
            } catch (error) {
              console.warn("rc initializing failed");
            }
            setTimeout(() => this.$emit("reloadFn"), 600);
          } else {
            // display email error message in the form field
            let payloadNotify = {
              isToast: true,
              title: "Create Earnings",
              content: "Details cannot be saved",
              list: [data.details],
              variant: "danger",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          console.log("Request failed ", error);

          let payloadNotify = {
            isToast: true,
            title: "Create Earnings",
            content: "Details cannot be saved",
            list: [error.message],
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        } finally {
          this.isAPILoading = false;
          this.isInfoSubmitted = true;
        }
      } else {
        let payloadNotify = {
          isToast: true,
          title: "Create Earnings",
          content: "Details cannot be saved ",
          list: errorList,
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    customTxtBtn() {},
    errorFn() {
      var errorList = [];

      if (!this.commissionRate) {
        this.commissionRateError.status = false;
        this.commissionRateError.message = "Please fill in commission rate";
        errorList.push(this.commissionRateError.message);
      }
      if (!this.onboardingFee) {
        this.onboardingFeeError.status = false;
        this.onboardingFeeError.message = "Please fill in onboarding fee";
        errorList.push(this.onboardingFeeError.message);
      }
      if (!this.stripeRate) {
        this.stripeRateError.status = false;
        this.stripeRateError.message = "Please fill in stripe rate";
        errorList.push(this.stripeRateError.message);
      }
      if (!this.stripeFee) {
        this.stripeFeeError.status = false;
        this.stripeFeeError.message = "Please fill in stripe fixed";
        errorList.push(this.stripeFeeError.message);
      }
      return errorList;
    },
    resetFn() {
      this.isReset = true;
      this.commissionRate = null;
      this.stripeRate = null;
      this.stripeFee = null;
    }
  },
};
</script>

<style scoped>
/* .iconSize {
  height: 20px;
  width: 20px;
} */
.table-element >>> .table-header th {
  color: rgba(135, 147, 163, 1);
  font-size: 1rem;
  font-weight: 400;
  border-color: transparent;
  font-family: "Roboto", sans-serif;
}
.table-element >>> .table-body td {
  padding: 10px;
}
.table-element >>> .table-body .main-text-container {
  max-width: 400px;
}
.table-element >>> .table-body .main-text-container span {
  display: inline-block;
}
.icon-background {
  width: 30px;
  height: 30px;
  background: var(--danger);
  border-radius: 50%;
  position: relative;
}

.blacklist-container {
  min-height: 300px;
  max-height: 500px;
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-width: thin;
}

.word-container {
  height: 30px;
}

.delete-icon {
  width: 12px;
  height: 12px;
  color: var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display {
  display: block;
}
.display-none {
  display: none;
}

.pointer {
  cursor: pointer;
}

/* table card */
.card-row {
  height: 100%;
}

.blackList-main-container {
  border-radius: 16px 16px 16px 16px;
}

.card-row .card-container {
  min-height: 560px;
  border-radius: 16px 16px 16px 16px;
}

.body-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  height: 100%;
}

.body-container .left-column .inner-row-grid {
  display: grid;
  grid-template-columns: 25% 70%;
  grid-gap: 5%;
}

.body-container .left-column .inner-row-grid .admin-type-badge {
  width: max-content;
}
.inner-row-grid-switch {
  display: grid;
  grid-template-columns: 60% 30%;
  grid-gap: 10%;
}
.body-container .column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}

.footer-img {
  max-width: 150px;
}

.version-number {
  font-size: 0.7rem;
  line-height: 20px;
}
.contact-number-input.form-group >>> input.form-control {
  border-left: 0px solid transparent;
  border-radius: 0px 4px 4px 0px;
}
.contact-number-input >>> .input-group-prepend {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dee2e6;
  border-color: var(--stroke-light);
  box-shadow: 0px 2px 8px var(--shadow-soft);
  min-width: 60px;
  margin-left: 0;
}
.checkbox-button
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: var(--primary);
  background-color: var(--primary);
}
.checkbox-button >>> .custom-control-label:before {
  border-color: var(--primary);
  color: var(--primary);
}
.column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .body-container {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }

  .body-container .column-divider {
    width: 100%;
    height: 0;
    border-right: none;
    border-top: 1px solid var(--light);
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .body-container .left-column .inner-row-grid {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
}
</style>
