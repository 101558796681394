import Instance from "./instance";

export const GetAnalytics = async (from, to) => {
    try {
        return await Instance.instanceToken.get(`/metrics/dashboard/admin`, {
            params: {
                from, to
            }
        });
    } catch (error) {
        return error;
    }
};

export const ResetRenderTimer = async () => {
    try {
        return await Instance.instanceToken.get(`/metrics/reset_render_timer`);
    } catch (error) {
        return error;
    }
}