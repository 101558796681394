<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          table-card-row
          w-100
        "
        no-gutters
      >
        <!-- home and about content card -->
        <b-card
          no-body
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            card-container
            mb-4
          "
        >
          <b-row class="px-3 py-0 body-container" no-gutters>
            <!-- left column -->
            <b-col class="px-0 px-md-2 py-3 left-column">
              <Home />
            </b-col>
            <!-- column divider -->
            <hr class="column-divider align-self-center" />
            <!-- right column -->
            <b-col class="px-0 px-md-2 py-3 right-column">
              <!-- Privacy row -->
              <About />
            </b-col>
          </b-row>
        </b-card>
        <!-- faq content card -->
        <b-card
          no-body
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            card-container
          "
        >
          <b-row class="px-3 py-0 body-container" no-gutters>
            <!-- left column -->
            <b-col class="px-0 px-md-2 py-3 left-column">
              <!-- Add a New Admin row -->
              <CreateFAQ @reloadFn="reloadFn" />
              <hr class="border-bottom-1 w-100 bg-light my-4" />
              <DonorVideoDisclaimer />
            </b-col>
            <!-- column divider -->
            <hr class="column-divider align-self-center" />
            <!-- right column -->
            <b-col class="px-0 px-md-2 py-3 right-column">
              <!-- Admin Management row -->
              <FAQList :key="isReload" />
              <hr class="border-bottom-1 w-100 bg-light my-4" />
              <CampaignRegisterDisclaimer />
            </b-col>
          </b-row>
        </b-card>
        <!-- privacy and terms card -->
        <b-card
          no-body
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            card-container
            mt-4
          "
        >
          <b-row class="px-3 py-0 body-container" no-gutters>
            <!-- left column -->
            <b-col class="px-0 px-md-2 py-3 left-column">
              <TermsandConditions />
            </b-col>
            <!-- column divider -->
            <hr class="column-divider align-self-center" />
            <!-- right column -->
            <b-col class="px-0 px-md-2 py-3 right-column">
              <!-- Privacy row -->
              <Privacy />
            </b-col>
          </b-row>
        </b-card>
        <!-- faq content card -->
        <b-card
          no-body
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            mt-4
            card-container
          "
        >
          <b-row class="px-3 py-0 body-container" no-gutters>
            <!-- left column -->
            <b-col class="px-0 px-md-2 py-3 left-column">
              <!-- Add a New Admin row -->
              <CreateGettingStarted @reloadFn="reloadFn" />
            </b-col>
            <!-- column divider -->
            <hr class="column-divider align-self-center" />
            <!-- right column -->
            <b-col class="px-0 px-md-2 py-3 right-column">
              <!-- Admin Management row -->
              <GettingStartedList :key="isReload" />
            </b-col>
          </b-row>
        </b-card>
        <b-card
          no-body
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            card-container-first
            mt-4
          "
        >
          <b-row class="px-3 py-0" no-gutters>
            <b-col class="px-0 px-md-2 py-3">
              <AccountRenewal />
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>

<script>
// components
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import CreateFAQ from "@/components/Content/FAQ/CreateFAQ";
import DonorVideoDisclaimer from "@/components/Content/DonorVideoDisclaimer";
import CampaignRegisterDisclaimer from "@/components/Content/CampaignRegisterDisclaimer";
import FAQList from "@/components/Content/FAQ/FAQList";
import TermsandConditions from "@/components/Content/TermsandCondition";
import Privacy from "@/components/Content/Privacy";
import Home from "@/components/Content/Home";
import About from "@/components/Content/About";
import CreateGettingStarted from "@/components/Content/GettingStarted/CreateGettingStarted";
import GettingStartedList from "@/components/Content/GettingStarted/GettingStartedList";
import AccountRenewal from "@/components/Content/AccountRenewal";

export default {
  name: "Content",
  components: {
    CreateFAQ,
    FAQList,
    HomeLayout,
    DonorVideoDisclaimer,
    TermsandConditions,
    Privacy,
    Home,
    About,
    CampaignRegisterDisclaimer,
    CreateGettingStarted,
    GettingStartedList,
    AccountRenewal,
  },
  data() {
    return {
      isReload: Number(new Date()),
    };
  },

  methods: {
    reloadFn() {
      this.isReload = Number(new Date());
    },
  },
};
</script>

<style scoped>
.card-container {
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  border-radius: 16px 16px 16px 16px;
}

.body-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  height: 100%;
  min-height: 560px;
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  border-radius: 16px 16px 16px 16px;
}

.body-container .column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}
.card-container-first {
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  border-radius: 16px 16px 16px 16px;
  width: 100%;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .body-container {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }

  .body-container .column-divider {
    width: 100%;
    height: 0;
    border-right: none;
    border-top: 1px solid var(--light);
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}
</style>
