import Instance from "./instance";

export const uploadAudio = async (payload) => {
  try {
    return await Instance.instanceFileUpload.post("/audiolib/default", payload);
  } catch (error) {
    return error;
  }
};

export const GetAllAudios = async (params) => {
  try {
    return await Instance.instanceToken.get(`/audiolib/default`, {
      params,
    });
  } catch (error) {
    return error;
  }
};

export const EnableDisableAudio = async (id, enable) => {
  try {
    return await Instance.instanceToken.put(`audiolib/default/${id}`, {
      enabled: enable,
    });
  } catch (error) {
    return error;
  }
};

export const DeleteAudio = async (id, params) => {
  try {
    return await Instance.instanceToken.delete(`audiolib/default/${id}`, {
      params,
    });
  } catch (error) {
    return error;
  }
};

// export const PreviewAudio = async (id) => {
//   try {
//     const response = await Instance.instanceToken.get(
//       `/audiolib/preview/${id}`,
//       {
//         responseType: "blob",
//       }
//     );
//     const url = URL.createObjectURL(response.data);
//     return url;
//   } catch (error) {
//     return error;
//   }
// };

// export const GetAllCampaigns = async (params) => {
//   try {
//     return await Instance.instanceToken.get(`/campaign`, {
//       params,
//     });
//   } catch (error) {
//     return error;
//   }
// };

export const EnableDisableCampaign = async (id, enable) => {
  try {
    return await Instance.instanceToken.post(`campaign/status/${id}`, {
      enabled: enable,
    });
  } catch (error) {
    return error;
  }
};

export const DeleteCampaign = async (id, params) => {
  try {
    return await Instance.instanceToken.delete(`campaign/${id}`, {
      params,
    });
  } catch (error) {
    return error;
  }
};

export const GetSingleUser = async (id) => {
  try {
    return await Instance.instanceToken.get(`/campaign/${id}`);
  } catch (error) {
    return error;
  }
};

export const UpdateUser = async (id, payload) => {
  try {
    return await Instance.instanceToken.put(`/campaign/update/${id}`, payload);
  } catch (error) {
    return error;
  }
};

export const VerifyCampaign = async (id, payload) => {
  try {
    return await Instance.instanceToken.post(
      `/campaign/approve/${id}`,
      payload
    );
  } catch (error) {
    return error;
  }
};

export const ExportCsv = async (params) => {
  try {
    return await Instance.instanceToken.get(`/campaign/export/csv`, {
      params,
    });
  } catch (error) {
    return error;
  }
};

export const PaypalStatus = async (campaignId) => {
  try {
    return await Instance.instanceToken.get(
      `/campaign/paypal_progress/${campaignId}`
    );
  } catch (error) {
    return error;
  }
};

export const ResetPayPalProgress = async (campaignId) => {
  try {
    return await Instance.instanceToken.post(
      `/campaign/paypal_progress/${campaignId}`
    );
  } catch (error) {
    return error;
  }
};
