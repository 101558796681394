import Instance from "./instance";

export const CreateCoupon = async (payload) => {
  try {
    return await Instance.instanceToken.post(`/payments/coupons`, payload);
  } catch (error) {
    return error;
  }
};

export const GetCoupons = async (payload) => {
  try {
    return await Instance.instanceToken.get(`/payments/coupons`, payload);
  } catch (error) {
    return error;
  }
};

export const EnableDisableCoupon = async (id, enable) => {
  try {
    return await Instance.instanceToken.put(`/payments/coupons/${id}`, {
      enabled: enable,
    });
  } catch (error) {
    return error;
  }
};

export const DeleteCoupon = async (id, params) => {
  try {
    return await Instance.instanceToken.delete(`/payments/coupons/${id}`, {
      params,
    });
  } catch (error) {
    return error;
  }
};
