<template>
  <b-row
    class="d-flex flex-row align-items-start justify-content-start main-table"
    no-gutters
  >
    <b-col
      class="d-flex flex-column align-items-start justify-content-start mb-5"
    >
      <h6
        class="text-left text-dark font-weight-semi-bold font-primary mb-2 mt-3"
      >
        Getting Started Content Management
      </h6>
      <!-- Admin Management table -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-start
          justify-content-start
          w-100
          table-container-row
        "
        no-gutters
      >
        <b-col cols="12">
          <b-table
            :bordered="false"
            :borderless="true"
            :hover="true"
            :striped="true"
            :filter="searchTable"
            @row-selected="onRowSelected"
            small
            class="table-element"
            thead-class="table-header font-primary"
            tbody-class="table-body "
            :fields="headerFields"
            :items="infoList"
            show-empty
            :busy="isLoading"
          >
            <!-- loading state -->
            <template v-slot:table-busy>
              <div class="text-center text-muted my-5">
                <b-spinner class="align-middle"></b-spinner>
                <span class="text-left mx-1">Loading...</span>
              </div>
            </template>
            <template v-slot:cell(title)="data">
              <div
                @click="data.toggleDetails"
                class="
                  d-flex
                  justify-content-between
                  flex-row
                  align-items-start
                  w-100
                  py-2
                "
                style="cursor: pointer"
              >
                <div class="text-gray-700 mr-1" style="font-size: 15px">
                  <b>Info #{{ data.index + 1 }}</b> {{ data.value.title }}
                </div>
                <font-awesome-icon
                  :icon="data.detailsShowing ? 'chevron-up' : 'chevron-down'"
                  class="text-gray-550"
                  style="font-size: 16px"
                ></font-awesome-icon>
              </div>
            </template>

            <!-- expandable row -->

            <template #row-details="row">
              <b-card class="w-100">
                <b-row
                  class="d-flex justify-content-between w-100 flex-nowrap mx-0"
                >
                  <div class="mx-0 pr-0 flex-grow-1 text-gray-750" v-html="row.item.description.description">
                  </div>
                  <div class="d-flex flex-column align-items-end mx-0 px-0">
                    <b-button
                      @click="
                        openUpdateModal(
                          row.item.actionsState.details,
                          $event.target
                        )
                      "
                      size="sm"
                      variant="dark"
                      class="
                        d-flex
                        p-2
                        justify-content-center
                        rounded-circle
                        bg-dark
                        mb-2
                        icon-button
                      "
                    >
                      <font-awesome-icon
                        icon="pen"
                        aria-label="Edit Video"
                        class="edit-icon"
                      ></font-awesome-icon>
                    </b-button>
                    <b-button
                      @click="
                        openDeleteItemModal(
                          row.item.actionsState.details,
                          $event.target
                        )
                      "
                      size="sm"
                      variant="danger"
                      class="
                        d-flex
                        p-2
                        justify-content-center
                        rounded-circle
                        bg-danger
                        icon-button
                      "
                    >
                      <font-awesome-icon
                        icon="trash"
                        aria-label="Delete Video"
                        class="delete-icon"
                      ></font-awesome-icon>
                    </b-button>
                  </div>
                </b-row>
              </b-card>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-col>
    <!-- table controls -->
    <!-- safari fix (flex-shrink-0) -->
    <b-row
      class="
        d-flex
        flex-row
        align-items-center
        justify-content-center justify-content-md-end
        flex-shrink-0
        px-3
        w-100
      "
      no-gutters
    >
      <!-- pages per page control -->
      <b-col
        class="
          d-flex
          flex-column
          align-items-center
          align-items-sm-start
          align-items-md-end
          align-items-xl-start
          justify-content-center
        "
        cols="12"
        sm="6"
      >
        <b-form-group
          label="Per page"
          label-cols="12"
          label-cols-sm="8"
          label-align="left"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0 w-100"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            class="per-page-con border-1 py-0"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <!-- pagination controls -->
      <b-col
        class="
          d-flex
          flex-column
          align-items-center align-items-sm-end
          justify-content-center
        "
        cols="12"
        sm="6"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          limit="4"
          pills
          class="d-none d-sm-flex table-pagination my-0"
          page-class="custom-page-item"
          ellipsis-class="custom-page-item"
          first-class="custom-page-item"
          last-class="custom-page-item"
          next-class="custom-page-item"
          prev-class="custom-page-item"
        ></b-pagination>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          limit="5"
          pills
          class="d-flex d-sm-none table-pagination my-0"
          page-class="custom-page-item"
          ellipsis-class="custom-page-item"
          first-class="custom-page-item"
          last-class="custom-page-item"
          next-class="custom-page-item"
          prev-class="custom-page-item"
        ></b-pagination>
      </b-col>
    </b-row>
    <DeleteInfo
      :isShow="isShowDeleteInfoModal"
      @onClose="onCloseDeleteItemModal"
      :itemContent="rowItem"
      :key="showDeleteInfoModalKey"
      @reloadFn="callInitFn"
    />
    <UpdateInfo
      :isShow="isShowUpdateModal"
      @onClose="onCloseUpdateModal"
      :itemContent="rowItem"
      :key="showUpdateModalKey"
      @reloadFn="callInitFn"
    />

    <!-- :isEnabled="rowIndex" -->
  </b-row>
</template>

<script>
// components
import DeleteInfo from "@/components/Content/GettingStarted/Modals/DeleteInfo";
import UpdateInfo from "@/components/Content/GettingStarted/Modals/UpdateInfo";

// services
import { GetAllInfoS } from "@/services/content.service";

export default {
  name: "InfoList",
  components: {
    DeleteInfo,
    UpdateInfo,
  },
  data() {
    return {
      isShowDeleteInfoModal: false,
      isShowUpdateModal: false,
      showUpdateModalKey: Number(new Date()) + 100,
      showDeleteInfoModalKey: Number(new Date()) + 110,
      rowItem: null,
      searchTable: null,
      isLoading: false,
      headerFields: [
        {
          key: "title",
          label: "",
        },
      ],
      infoList: [],
      // table pagination controls
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100, 500],
    };
  },
  mounted() {
    this.initFn();
  },
  computed: {
    totalRows() {
      return this.infoList.length;
    },
  },
  methods: {
    onRowSelected(item) {
      // only triggers when the row is selected (not when it gets unselected)
      if (item.length > 0) {
        // get the unique id of the current item
        let _id = item[0]._id;
        console.log("item - ", item);
        // find the row index of the selected row
        let selectedIndex = this.infoList.findIndex((x) => x._id == _id);
        // iterate through the object array and toggle the showDetails property to show the expanded row on the selected row only
        // the other rows' expanded row is collapsed
        this.infoList.forEach((element, index) => {
          if (index == selectedIndex) {
            element._showDetails = true;
          } else {
            element._showDetails = false;
          }
        });
      } else {
        // when unselected hides all the row details card
        this.infoList.forEach((element) => {
          element._showDetails = false;
        });
      }
    },
    openDeleteItemModal(item, button) {
      this.isShowDeleteInfoModal = true;
      this.rowItem = item;
      this.$root.$emit("bv::show::modal", "delete-info-row-modal", button);
    },
    onCloseDeleteItemModal() {
      this.isShowDeleteInfoModal = false;
    },
    openUpdateModal(item, button) {
      this.isShowUpdateModal = true;
      this.rowItem = item;
      this.$root.$emit(
        "bv::show::modal",
        "enable-disable-info-row-modal",
        button
      );
    },
    onCloseUpdateModal() {
      this.isShowUpdateModal = false;
    },
    async initFn() {
      try {
        this.isLoading = true;
        let { data } = await GetAllInfoS();
        console.log(data);
        if (data.code == 200 && data.message == "success") {
          this.infoList = data.content.map((x) => {
            return {
              _id: x._id,
              dateCreated: {
                date: x.created_at.split("T")[0],
              },
              title: {
                title: x.title,
              },
              description: {
                description: x.description,
              },
              actionsState: {
                details: x,
                state: x.isEnabled,
              },
            };
          });
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Info List",
            content: "Failed to retrieve data! please check again",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        console.log(error);
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Info List",
          content: "Failed to retrieve data! please check again: ",
          list: [error.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isLoading = false;
      }
    },
    callInitFn() {
      this.showDeleteInfoModalKey = Number(new Date()) + 100;
      this.showUpdateModalKey = Number(new Date()) + 110;
      this.initFn();
    },
  },
};
</script>

<style scoped>
/* table container */
.main-table {
  /* min-height: 592px; */
}
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  min-height: 210px;
}
.per-page-con {
  border-color: var(--prime-gray-muted);
  box-shadow: 0px 2px 8px var(--shadow-soft);
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.table-container-row .table-element {
  width: 100%;
  min-width: 200px;
}
/* table element css */
.table-element >>> .table-header {
  background-color: transparent;
}
.table-element >>> .table-header th {
  color: rgba(135, 147, 163, 1);
  font-size: 0.9rem;
  font-weight: 400;
  border-color: transparent;
  font-family: "Roboto", sans-serif;
}
.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}
.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}
.table-element >>> .table-body td {
  background-color: white;
  vertical-align: middle;
  padding-left: 0;
  padding-bottom: 12px;
}
.table-element >>> .table-body .actions-button-icon {
  width: 40px;
  height: 40px;
}
.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}
.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}
/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}
.table-pagination >>> .custom-page-item button:hover {
  color: var(--secondary);
  border-color: var(--secondary);
  background-color: var(--light);
}
.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}
.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}
.icon-button {
    width: 32px;
    height: 32px;
}
</style>
