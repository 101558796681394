<template>
  <InitLayout>
    <!-- logo placeholder -->
    <b-row class="w-full my-3" no-gutters>
      <b-img
        src="@/assets/personalPac-log.svg"
        class="px-0 w-auto"
        height="50px"
        alt="Personal PAC"
      ></b-img>
    </b-row>

    <b-card
      no-body
      bg-variant="white"
      class="
        d-flex
        flex-column
        align-items-center
        justify-content-center
        px-1 px-lg-2
        py-4
        shadow-hard
        registration-container-card
      "
    >
      <!-- headings -->
      <b-row class="mb-3" no-gutters>
        <font-awesome-icon
          class="icon-container bg-white shadow-soft fa-3x"
          icon="user-shield"
        />
      </b-row>
      <b-row no-gutters>
        <h3 class="text-center font-primary font-weight-bold main-heading">
          Master Administrator Registration
        </h3>
      </b-row>
      <b-row class="mb-3" no-gutters>
        <span class="text-center font-primary mb-4 sub-heading">
          Please enter in your master credentials to create a master admin
        </span>
      </b-row>

      <!-- master register form -->
      <form
        id="master-registration-form"
        class="
          d-flex
          flex-column
          align-items-center
          justify-content-center
          px-2 px-lg-1
          w-100
        "
      >
        <!-- full row input (Master Key) -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col cols="12" md="10">
            <FormInput
              groupId="master-key-group"
              id="master-key-group-input"
              :state="masterKeyError.status"
              :invalidFeedback="`${masterKeyError.message}`"
              label="Master Key"
              v-model="masterKey"
              isRequired
              trim
              form="master-registration-form"
              type="text"
            ></FormInput>
            <!-- @keypress.native="isEmail(email)" -->
          </b-col>
        </b-row>
        <!-- full row input (AdminName) -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col cols="12" md="10">
            <FormInput
              groupId="user-name-group"
              id="user-name-group-input"
              :state="adminNameError.status"
              :invalidFeedback="`${adminNameError.message}`"
              label="Full Name"
              v-model="adminName"
              isRequired
              trim
              form="master-registration-form"
              type="text"
            ></FormInput>
          </b-col>
        </b-row>
        <!-- full row input (Admin Email) -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col cols="12" md="10">
            <FormInput
              groupId="admin-email-group"
              id="admin-email-group-input"
              :state="emailError.status"
              :invalidFeedback="`${emailError.message}`"
              label="Email Address"
              v-model="email"
              placeholder="user@example.com"
              isRequired
              trim
              form="master-registration-form"
              type="text"
            ></FormInput>
          </b-col>
          <!-- @keypress.native="isEmail(email)" -->
        </b-row>

        <!-- full row input (Password) -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col cols="12" md="10">
            <FormInputGroup
              groupId="password-group"
              id="password-group-input"
              v-model="password"
              :state="passwordError.status"
              :invalidFeedback="`${passwordError.message}`"
              label="Password"
              placeholder="Minimum 6 characters"
              isRequired
              :type="showPassword ? 'text' : 'password'"
              form="master-registration-form"
              isAppend
            >
              <template v-slot:append>
                <b-button
                  class="px-3 px-md-4 eye-container"
                  variant="transparent"
                  @click="showPassword = !showPassword"
                >
                  <font-awesome-icon
                    class="text-prime-gray"
                    :icon="showPassword ? 'eye-slash' : 'eye'"
                    aria-label="Help"
                  ></font-awesome-icon>
                </b-button>
              </template>
            </FormInputGroup>
          </b-col>
        </b-row>
        <!-- error status -->
        <b-row
          v-if="registrationError.status"
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <span class="text-danger text-small text-center">{{
            this.registrationError.message
          }}</span>
        </b-row>
        <!-- form action -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <!-- login button -->
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="4"
            md="4"
          >
            <FormButton
              isBlock
              class="font-weight-normal"
              type="submit"
              :isLoading="isAPILoading"
              :isDisabled="isAPILoading"
              @click.native="registerFn"
              >Register</FormButton
            >
          </b-col>
        </b-row>
      </form>
    </b-card>
  </InitLayout>
</template>

<script>
// services
import { MasterRegister } from "../services/admin.service";

// components
// @ is an alias to /src
import InitLayout from "@/layout/InitLayout";
import FormInput from "@/components/Form/FormInput";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";
import CheckEmail from "@/util/CheckEmail";

export default {
  name: "MasterRegistration",
  components: {
    InitLayout,
    FormInput,
    FormInputGroup,
    FormButton,
  },
  data() {
    return {
      isAPILoading: false,
      adminName: null,
      email: "",
      password: "",
      // masterKey: "personal-pac-mstre1r23",
      masterKey: "",
      showPassword: false,
      errorEmail: null,
      errorPasswor: null,
      adminNameError: {
        status: null,
        message: "",
      },
      masterKeyError: {
        status: null,
        message: "",
      },
      emailError: {
        status: null,
        message: "",
      },
      passwordError: {
        status: null,
        message: "",
      },
      registrationError: {
        status: false,
        message: "",
      },
    };
  },
  watch: {
    adminName(value) {
      if (value) {
        this.adminNameError.status = true;
      } else {
        this.adminNameError.status = false;
        this.adminNameError.message = "Please fill in your name";
      }
    },
    masterKey(value) {
      if (value) {
        this.masterKeyError.status = true;
      } else {
        this.masterKeyError.status = false;
        this.masterKeyError.message = "Please fill in your master key";
      }
    },
    email(value) {
      if (value) {
        if (CheckEmail(value) == true) this.emailError.status = true;
        else {
          this.emailError.status = false;
          this.emailError.message = "Please enter a valid email address";
        }
      } else {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }
    },
    password(value) {
      if (value) {
        if (value.length < 6) {
          this.passwordError.status = false;
          this.passwordError.message =
            "A minimum length of 6 characters needed";
        } else {
          this.passwordError.status = true;
        }
      } else {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }
    },
  },
  methods: {
    async registerFn() {
      if (
        this.email &&
        this.password &&
        CheckEmail(this.email) &&
        this.adminName &&
        this.masterKey
      ) {
        let payload = {
          masterKey: this.masterKey,
          adminEmail: this.email,
          adminName: this.adminName,
          password: this.password,
        };
        this.isAPILoading = true;
        try {
          let { data } = await MasterRegister(payload);
          console.log(data);

          this.isAPILoading = false;
          if (data.code == 200 || data.message == "success") {
            // this.$store.dispatch("register/setVendor", res.data.content);
            let payloadNotify = {
              isToast: true,
              title: "Master Administrator Registration",
              content: "Master administrator registered successfully",
              variant: "success",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
            localStorage.setItem("adminDetails", JSON.stringify(data.content));

            // go to dashboard
            this.$router.replace({ name: "Dashboard" });
          } else {
            this.isAPILoading = false;
            this.registrationError.status = true;
            this.registrationError.message = data.details;
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.validateForm();
      }
    },
    validateForm() {
      if (!this.email) {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }

      if (!this.password) {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }
      if (!this.adminName) {
        this.adminNameError.status = false;
        this.adminNameError.message = "Please fill in your name";
      }
      if (!this.masterKey) {
        this.masterKeyError.status = false;
        this.masterKeyError.message = "Please fill in your master key";
      }

      if (this.password < 6) {
        this.passwordError.status = false;
        this.passwordError.message = "A minimum length of 6 characters needed";
      }
    },
  },
};
</script>

<style scoped>
.registration-container-card {
  width: 80%;
  min-width: 300px;
  min-height: 400px;
  max-width: 600px;
  border-radius: 14px;
}

/* heading stylings */
.registration-container-card .main-heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
.registration-container-card .sub-heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

/* icon styling */
.registration-container-card .icon-container {
  padding: 15px;
  height: 60px;
  border: 1px solid var(--stroke-light);
  box-sizing: border-box;
  border-radius: 36px;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .registration-container-card {
    min-height: fit-content;
  }
}
/* small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .registration-container-card {
    width: 90%;
  }
}
/* Extra small (iphone 6) devices (portrait phones, less than 380px) */
@media (max-width: 380.98px) {
  .registration-container-card {
    width: 90%;
    height: 85vh;
  }
  .registration-container-card .register-button {
    margin-bottom: 40px;
  }
}
/* Extra small (iphone 6) devices (portrait phones, less than 380px) */
@media (max-width: 380.98px) {
  .registration-container-card {
    width: 90%;
    height: 90vh;
  }
  .registration-container-card .register-button {
    margin-bottom: 40px;
  }

  .registration-container-card .icon-container {
    margin-top: 40px;
  }
}
</style>
