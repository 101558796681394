import { render, staticRenderFns } from "./CharityManagement.vue?vue&type=template&id=53deeec1&scoped=true"
import script from "./CharityManagement.vue?vue&type=script&lang=js"
export * from "./CharityManagement.vue?vue&type=script&lang=js"
import style0 from "./CharityManagement.vue?vue&type=style&index=0&id=53deeec1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53deeec1",
  null
  
)

export default component.exports