<template>
  <!-- order items modal -->
  <b-modal
    id="enable-disable-campaign-row-modal"
    ref="enableDisableItemModal"
    :title="`Are you sure you want to ${
      itemContent ? (itemContent.actions.enabled ? 'disable' : 'enable') : ''
    } this Audio?`"
    size="lg"
    header-class="font-primary"
    header-bg-variant="white"
    header-text-variant="secondary"
    header-border-variant="white"
    footer-bg-variant="gray-300"
    footer-border-variant="light"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end mx-0 w-100"
      >
        <FormButton
          variant="light"
          isBlock
          class="my-2 my-sm-0 mr-0 mr-sm-2 font-weight-normal text-secondary"
          @click.native="cancel"
          >Cancel</FormButton
        >
        <FormButton
          isBlock
          :variant="itemContent.actions.enabled ? 'danger' : 'secondary'"
          :disabled="isLoading"
          :isLoading="isLoading"
          type="button"
          class="my-2 my-sm-0 ml-0 ml-sm-2 border action-btn"
          :class="
            itemContent.actions.enabled ? 'border-danger' : 'border-secondary'
          "
          @click.native="enableDisableFn"
        >
          {{
            itemContent
              ? itemContent.actions.enabled
                ? "Disable"
                : "Enable"
              : ""
          }}
        </FormButton>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <font-awesome-icon
        icon="times"
        class="text-secondary"
        @click="closeModal()"
        aria-label="Close Modal"
      ></font-awesome-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 table-container-row"
      no-gutters
    >
      <p class="text-left text-prime-gray mb-4">
        Are you sure you want to
        {{
          itemContent
            ? itemContent.actions.enabled
              ? "disable"
              : "enable"
            : ""
        }}
        this audio?
        <b>{{ itemContent ? itemContent.actions.name : "" }}</b
        >This action can always be revoked.
      </p>
    </b-row>
  </b-modal>
</template>

<script>
// components
import FormButton from "@/components/Form/FormButton";

// services
import { EnableDisableAudio } from "@/services/Audios.service";

export default {
  name: "EnableDisableCampaign",
  components: {
    FormButton,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    itemContent: {
      default: null,
    },
  },
  computed: {
    notifyMsg() {
      if (this.itemContent) {
        return this.itemContent.actions.enabled ? "disabled" : "enabled";
      } else {
        return "";
      }
    },
  },
  methods: {
    async enableDisableFn() {
      console.log(!this.itemContent.actions.enabled);
      this.isLoading = true;
      const { data } = await EnableDisableAudio(
        this.itemContent.id,
        !this.itemContent.actions.enabled
      );
      console.log(data);
      if (data.code == 200) {
        let payloadNotify = {
          isToast: true,
          title: `${
            this.itemContent.actions.enabled ? "Disable" : "Enable"
          } Audio`,
          content:
            "The Audio (" +
            this.itemContent.actions.name +
            ") has been" +
            ` ${this.itemContent.actions.enabled ? "disabled" : "enabled"}` +
            " successfully.",
          variant: "success",
        };
        this.isLoading = false;
        this.$store.dispatch("notification/setNotify", payloadNotify);
        this.$emit("reloadFn");
      } else {
        this.isLoading = false;
        let content = "Failed to enable Audio. ";
        if (data.display_msg) content = data.display_msg;

        let payloadNotify = {
          isToast: true,
          title: "ERROR!",
          variant: "danger",
          content,
          list: data.reasons,
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
