<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          table-card-row
          w-100
        "
        no-gutters
      >
        <b-card
          no-body
          class="overflow-hidden shadow-sm border-0 table-container-card w-100"
        >
          <!-- table actions row -->
          <b-row
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-between
              w-100
              my-1 my-md-2
              px-3
              py-3
            "
            no-gutters
          >
            <!-- search input column -->
            <b-col cols="12" lg="6" class="mb-3 mb-lg-0 pr-0 pr-lg-2">
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-center justify-content-sm-start
                "
                no-gutters
              >
                <!-- table search input -->
                <b-input-group class="search-input-group shadow-soft">
                  <b-input-group-prepend>
                    <b-row
                      class="
                        search-input-icon
                        d-flex
                        flex-row
                        align-items-center
                        justify-content-center
                        pl-3
                        pr-1
                        border border-stroke-light
                      "
                      no-gutters
                    >
                      <font-awesome-icon
                        class="text-prime-gray"
                        icon="search"
                        size="lg"
                        aria-label="Search"
                      ></font-awesome-icon>
                    </b-row>
                  </b-input-group-prepend>
                  <b-form-input
                    class="search-input border-left-0 border-stroke-light"
                    type="text"
                    placeholder="Search Charity"
                    v-model="searchQuery"
                    @keyup.native.enter="searchFn"
                  ></b-form-input>
                </b-input-group>
              </b-row>
            </b-col>
            <b-col
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
                px-0
                py-2 py-lg-0
              "
              cols="12"
              lg="2"
            >
              <FormButton
                variant="light"
                class="font-weight-normal text-dark"
                @click.native="openCsvModal"
                :isLoading="isCSVLoading"
                :isDisabled="isCSVLoading"
              >
                <font-awesome-icon
                  class="text-dark"
                  icon="file-export"
                  size="lg"
                  aria-label="Filter"
                ></font-awesome-icon>
                <span class="d-inline ml-2 mr-2 mr-sm-3">Export CSV</span>
              </FormButton>
            </b-col>

            <!-- filter/create actions column -->
            <b-col
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
                px-0
                py-2 py-lg-0
              "
              cols="12"
              lg="8"
            >
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-between justify-content-lg-end
                  w-100
                  mx-0
                "
              >
                <!-- add any filter elements here -->
              </b-row>
            </b-col>
          </b-row>
          <!-- table row -->
          <b-row
            class="
              d-flex
              flex-row
              align-items-start
              justify-content-start
              px-3
              w-100
              table-container-row
            "
            no-gutters
          >
            <b-table
              :bordered="false"
              :borderless="true"
              hover
              show-empty
              class="table-element"
              thead-class="table-header font-secondary"
              tbody-class="table-body"
              :busy="isLoading"
              :fields="campaignHeader"
              :items="campaignItems"
              no-local-sorting="false"
              @sort-changed="sortingChanged"
              sort-icon-left
            >
              <!-- :sort-compare="customSortRoutine" -->
              <!-- table busy state -->
              <template #table-busy>
                <div class="text-center text-muted my-3">
                  <b-spinner class="align-middle"></b-spinner>
                  <span class="text-left mx-1">Loading...</span>
                </div>
              </template>
              <!-- table empty state -->
              <template #empty="scope">
                <b-row
                  class="
                    d-flex
                    flex-row
                    align-items-center
                    justfiy-content-center
                    py-5
                  "
                  no-gutters
                >
                  <h6 class="text-center mb-0 mx-auto my-5">
                    {{ scope.emptyText }}
                  </h6>
                </b-row>
              </template>
              <!-- user ID  -->
              <template #cell(userId)="data">
                <router-link
                  class="
                    d-flex
                    flex-column
                    align-items-start
                    justify-content-center
                    text-decoration-none
                  "
                  :to="{
                    name: 'Campaign Profile',
                    params: { userId: data.value.id },
                  }"
                >
                  <span class="text-larger text-dark mb-2">{{
                    data.value._id
                  }}</span>
                </router-link>
              </template>

              <!-- name  -->
              <template #cell(name)="data">
                <router-link
                  class="
                    d-flex
                    flex-column
                    align-items-start
                    justify-content-center
                    text-decoration-none
                  "
                  :to="{
                    name: 'Campaign Profile',
                    params: { userId: data.value.id },
                  }"
                >
                  <span class="main-text text-dark mb-2">{{
                    data.value.campName
                  }}</span>
                  <span class="text-muted sub-text">{{ data.value.url }}</span>
                </router-link>
              </template>
              <!-- created_at  -->
              <template #cell(created_at)="data">
                <router-link
                  class="
                    d-flex
                    flex-column
                    align-items-start
                    justify-content-center
                    text-decoration-none
                  "
                  :to="{
                    name: 'Campaign Profile',
                    params: { userId: data.value.id },
                  }"
                >
                  <span class="main-text text-dark mb-2">{{
                    data.value.Jdate
                  }}</span>
                </router-link>
              </template>
              <!-- Expiry  -->
              <template #cell(expireDate)="data">
                <router-link
                  class="
                    d-flex
                    flex-column
                    align-items-start
                    justify-content-center
                    text-decoration-none
                  "
                  :to="{
                    name: 'Campaign Profile',
                    params: { userId: data.value.id },
                  }"
                >
                  <span class="main-text text-dark mb-2">{{ data.value }}</span>
                </router-link>
              </template>

              <!-- electionDate column -->
              <template #cell(electionDate)="data">
                <router-link
                  class="
                    d-flex
                    flex-column
                    align-items-left
                    justify-content-center
                    text-decoration-none
                  "
                  :to="{
                    name: 'Campaign Profile',
                    params: { userId: data.value.id },
                  }"
                >
                  <span class="main-text text-dark mb-2">{{
                    data.value.Edate
                  }}</span>
                </router-link>
              </template>
              <!-- actions column -->
              <template #cell(enabled)="{ item }">
                <div>
                  <b-button
                    :variant="
                      item.actions.enabled ? 'dark-green' : 'light-gray'
                    "
                    v-b-tooltip.hover.left
                    :title="`${
                      item.actions
                        ? item.actions.enabled
                          ? 'Disable'
                          : 'Enable'
                        : ''
                    } campaign`"
                    class="shadow-sm ml-3 more-btn"
                    pill
                    @click="openEnabDisabModal(item, $event)"
                  >
                    <font-awesome-icon
                      icon="check"
                      class="more-icon"
                      size="lg"
                      :class="
                        item.actions.enabled
                          ? 'text-dark-green'
                          : 'text-light-gray'
                      "
                    ></font-awesome-icon>
                  </b-button>
                </div>
              </template>

              <template #cell(actions)="{ item }">
                <div>
                  <b-button
                    variant="outline-dark"
                    v-b-tooltip.hover.left
                    title="Delete User"
                    class="shadow-sm border border-danger more-btn"
                    pill
                    @click="openDeleteItemModal(item, $event)"
                  >
                    <font-awesome-icon
                      icon="trash"
                      aria-label="View User Details"
                      class="more-icon text-danger"
                      size="lg"
                    ></font-awesome-icon>
                  </b-button>
                </div>
              </template>
              <!-- Reset column -->
              <template #cell(reset)="{ item }">
                <div>
                  <b-button
                    variant="outline-dark"
                    v-b-tooltip.hover.left
                    title="Reset Progress"
                    class="shadow-sm border border-primary more-btn"
                    pill
                    @click="openResetModal(item, $event)"
                  >
                    <font-awesome-icon
                      icon="redo"
                      aria-label="View User Details"
                      class="more-icon text-primary"
                      size="lg"
                    ></font-awesome-icon>
                  </b-button>
                </div>
              </template>
              <!-- ViewMore column -->
              <template #cell(ViewMore)="data">
                <router-link
                  class="
                    d-flex
                    flex-column
                    align-items-left
                    ml-3
                    justify-content-center
                    text-decoration-none
                  "
                  :to="{
                    name: 'Campaign Profile',
                    params: { userId: data.value.id },
                  }"
                >
                  <b-button
                    variant="outline-dark"
                    v-b-tooltip.hover.left
                    title="View User Details"
                    class="shadow-sm border border-prime-gray more-btn"
                    pill
                  >
                    <font-awesome-icon
                      icon="chevron-right"
                      aria-label="View User Details"
                      class="mx-auto more-icon"
                      size="lg"
                    ></font-awesome-icon>
                  </b-button>
                </router-link>
              </template>
            </b-table>
          </b-row>
          <!-- table controls -->
          <!-- safari fix (flex-shrink-0) -->
          <b-row
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-center justify-content-md-end
              flex-shrink-0
              px-3
              py-2
              w-100
            "
            no-gutters
          >
            <!-- pages per page control -->
            <b-col
              class="
                d-flex
                flex-column
                align-items-center
                align-items-sm-start
                align-items-md-end
                align-items-xl-start
                justify-content-center
                py-2 py-md-1
              "
              cols="12"
              sm="6"
              lg="5"
              xl="2"
            >
              <b-form-group
                label="Per page"
                label-cols="12"
                label-cols-sm="6"
                label-align="left"
                label-align-sm="right"
                label-size="sm"
                label-for="perPageSelect"
                class="mb-0 w-100"
              >
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  class="per-page-select border-1 py-0"
                  :options="pageOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <!-- pagination controls -->
            <b-col
              class="
                d-flex
                flex-column
                align-items-center align-items-sm-end
                justify-content-center
                py-2 py-md-1
              "
              cols="12"
              sm="6"
              lg="4"
              xl="3"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="right"
                size="sm"
                limit="4"
                pills
                class="d-none d-sm-flex table-pagination my-0"
                page-class="custom-page-item"
                ellipsis-class="custom-page-item"
                first-class="custom-page-item"
                last-class="custom-page-item"
                next-class="custom-page-item"
                prev-class="custom-page-item"
              ></b-pagination>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                limit="5"
                pills
                class="d-flex d-sm-none table-pagination my-0"
                page-class="custom-page-item"
                ellipsis-class="custom-page-item"
                first-class="custom-page-item"
                last-class="custom-page-item"
                next-class="custom-page-item"
                prev-class="custom-page-item"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <EnableDisableCampaign
        :isShow="isShowEnableDisableModal"
        @onClose="onCloseEnableDisableModal"
        :itemContent="rowItem"
        :key="showEnableDisableModalKey"
        @reloadFn="callInitFn"
      />

      <VerifyModal
        :isShow="isShowEnableDisableModal"
        @onClose="onCloseVerifyModal"
        :itemContent="rowItem"
        :key="showVerifyModalKey"
        @reloadFn="callInitFn"
      />

      <DeleteCampaign
        :isShow="isShowDeleteCampaignModal"
        @onClose="onCloseDeleteItemModal"
        :itemContent="rowItem"
        :key="showDeleteCampaignModalKey"
        @reloadFn="callInitFn"
      />

      <ResetCampaign
        :isShow="isShowResetModal"
        @onClose="onCloseresetModal"
        :itemContent="rowItem"
        :key="showResetModalKey"
        @reloadFn="callInitFn"
      />

      <ShowCsvModel :isShow="isShowCsvModel" @onClose="CloseCsvModal" />
    </b-container>
  </HomeLayout>
</template>
<script>
// components
import HomeLayout from "@/layout/HomeLayout";
import DeleteCampaign from "@/components/CharityManagement/DeleteCampaign";
import ResetCampaign from "@/components/CharityManagement/resetModal";
import ShowCsvModel from "@/components/CharityManagement/ExportCsvModel";
import EnableDisableCampaign from "@/components/CharityManagement/EnableDisableCampaign";
import VerifyModal from "@/components/CharityManagement/VerifyModal";
import FormButton from "@/components/Form/FormButton";

// services
import { GetAllCampaigns } from "@/services/campaignManagement.service";

export default {
  name: "Users",
  components: {
    HomeLayout,
    EnableDisableCampaign,
    DeleteCampaign,
    VerifyModal,
    FormButton,
    ShowCsvModel,
    ResetCampaign,
  },
  data() {
    return {
      rowItem: null,
      rowIndex: null,
      searchQuery: null,
      searchState: false,
      isLoading: false,
      isCSVLoading: false,
      isShowEnableDisableModal: false,
      isShowDeleteCampaignModal: false,
      isShowResetModal: false,
      isShowCsvModel: false,
      isShowVerifyModal: false,
      showEnableDisableModalKey: Number(new Date()) + 100,
      showDeleteCampaignModalKey: Number(new Date()) + 110,
      showResetModalKey: Number(new Date()) + 120,
      showVerifyModalKey: Number(new Date()) + 50,
      campaignItems: [],
      campaignHeader: [
        {
          key: "userId",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
          label: "Charity Name & URL",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Joined Date",
          sortable: true,
        },
        {
          key: "expireDate",
          label: "Expiry Date",
        },
        // {
        //   key: "electionDate",
        //   label: "Election Date",
        //   sortable: true,
        // },
        {
          key: "enabled",
          label: "Status",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "reset",
          label: "Reset",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "ViewMore",
          label: "View More",
        },
      ],
      // table pagination controls
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [5, 10, 30, 60, 100, 200],
      baseURL: process.env.VUE_APP_URL,
      testing: null,
      sortBy: "created_at",
      sortDesc: true,
    };
  },
  watch: {
    perPage() {
      this.initFn();
    },
    currentPage() {
      this.initFn();
    },
    searchQuery(val) {
      // only clear filter when the searchState it true (i.e. when a search has been invoked)
      if (val == "" && this.searchState == true) {
        // when search query is empty (i.e. on backspace, invoke the reset filter)
        this.clearFilterFn();
      }
    },
  },
  computed: {
    computeFilterState() {
      return !this.searchQuery;
    },
  },
  async mounted() {
    this.initFn();
  },
  methods: {
    async sortingChanged(ctx) {
      console.log(ctx);
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.currentPage = 1;
      this.initFn();
    },
    async initFn() {
      try {
        this.isLoading = true;
        let { data } = await GetAllCampaigns({
          limit: this.perPage,
          page: this.currentPage,
          search: this.searchQuery,
          sort: this.sortBy,
          order: this.sortDesc ? -1 : 1,
        });
        if (data.code == 200 || data.message == "success") {
          this.totalRows = data.content.total;
          this.campaignItems = data.content.results.map((x, index) => {
            let expireDate = "N/A";

            if (x.dateOfElection) {
              const electionDate = new Date(x.dateOfElection);
              electionDate.setDate(
                electionDate.getDate() + data.content.expiresAfter
              );

              expireDate = `${electionDate.getFullYear()}-${String(
                electionDate.getMonth() + 1
              ).padStart(2, "0")}-${String(electionDate.getDate()).padStart(
                2,
                "0"
              )}`;
            }

            return {
              userId: {
                _id: this.getUniqueId(index),
                id: x._id,
              },
              name: {
                id: x._id,
                campName: x.name ? x.name : "N/A",
                url: x.url,
              },
              created_at: {
                id: x._id,
                Jdate: x.created_at ? x.created_at.split("T")[0] : "N/A",
              },
              expireDate,
              electionDate: {
                id: x._id,
                Edate: this.testing,
              },
              approved: x.approved,
              enabled: x.enabled,
              actions: x,
              ViewMore: {
                _id: this.getUniqueId(index),
                id: x._id,
              },
              id: x._id,
            };
          });
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Users",
            content:
              "Unable to retrieve Charity users' data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    // function to evoke when filteration is applied
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
    },

    getUniqueId(index) {
      // minus 1 from it to start from 0
      let currentPos = this.currentPage - 1;
      // get the position by multiplying with perpage (so it will change if the per page count is changed)
      let position = currentPos * this.perPage;
      let newIndex = index + 1;
      let newPos = position + newIndex;
      return `C${String(newPos).padStart(3, "0")}`;
    },
    openEnabDisabModal(item, button) {
      console.log(item);
      // this.$store.dispatch("setSelectedItem", item);
      this.isShowEnableDisableModal = true;
      this.rowItem = item;
      this.$root.$emit(
        "bv::show::modal",
        "enable-disable-campaign-row-modal",
        button
      );
    },
    openVerifyModal(item, button) {
      console.log(item);
      // this.$store.dispatch("setSelectedItem", item);
      this.isShowVerifyModal = true;
      this.rowItem = item;
      this.$root.$emit("bv::show::modal", "verify-campaign-row-modal", button);
    },
    onCloseEnableDisableModal() {
      this.isShowEnableDisableModal = false;
    },
    onCloseVerifyModal() {
      this.isShowVerifyModal = false;
    },
    callInitFn() {
      this.showEnableDisableModalKey = Number(new Date()) + 110;
      this.showDeleteCampaignModalKey = Number(new Date()) + 100;
      (this.showResetModalKey = Number(new Date()) + 120),
        (this.showVerifyModalKey = Number(new Date()) + 50);

      this.initFn();
    },
    openDeleteItemModal(item, button) {
      // this.$store.dispatch("setSelectedItem", item);
      this.isShowDeleteCampaignModal = true;
      this.rowItem = item;
      // this.rowIndex = index;
      this.$root.$emit("bv::show::modal", "delete-campaign-row-modal", button);
    },
    onCloseDeleteItemModal() {
      this.isShowDeleteCampaignModal = false;
    },
    openResetModal(item, button) {
      // this.$store.dispatch("setSelectedItem", item);
      this.rowItem = item;
      this.isShowResetModal = true;
      // this.rowIndex = index;
      this.$root.$emit("bv::show::modal", "reset-campaign-row-modal", button);
    },
    onCloseresetModal() {
      this.isShowResetModal = false;
    },
    searchFn() {
      // check if the search query is populated/exists
      if (this.searchQuery && this.searchQuery.length > 0) {
        // set the search state to true when invoking the search function
        this.searchState = true;
        // reset the current page to 1, before executing the search to ensure the search doesn't get hindered by the pagination
        this.currentPage = 1;
        // call the init function to load the data based on the search query
        this.initFn();
      }
    },
    clearFilterFn() {
      // reset the search query and selected status and then call the init function to load the inital data
      this.selectedStatus = "all";
      this.searchQuery = null;
      // set the search state to false on clear filter
      this.searchState = false;
      // reset the current page to 1
      this.currentPage = 1;
      this.initFn();
    },
    openCsvModal(button) {
      this.isShowCsvModel = true;
      this.$root.$emit("bv::show::modal", "show-csv-model", button);
    },
    CloseCsvModal() {
      this.isShowCsvModel = false;
    },
  },
};
</script>

<style scoped>
/* insights card */
.insight-cards-row {
  height: 100px;
  width: 100%;
  background-color: var(--white);
  border-radius: 0;
  border: 1px solid var(--light);
}
/* table card */
.table-card-row {
  height: 100%;
}
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 16px 16px 16px 16px;

  border: 1px solid var(--light);
}
.table-card-row .search-input-group {
  /* box-shadow: none; */
  height: 50px;
  max-width: 350px;
}
.table-card-row .search-input-group .search-input-icon {
  border-radius: 8px 0px 0px 8px;
  box-shadow: none;
}
.table-card-row .search-input-group .search-input {
  border-radius: 0px 8px 8px 0px;
  box-shadow: none;
  height: 50px;
}
.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}
.table-card-row .search-input-group .search-input:focus {
  border-color: var(--stroke-light);
}
/* filter/country/export dropdown styling */
.table-card-row .filter-dropdown,
.table-card-row .country-dropdown,
.table-card-row .export-dropdown {
  height: 50px;
  min-width: 200px;
}
/* filter/country/export dropdown menu styling */
.table-card-row .filter-dropdown >>> .filter-dropdown-menu,
.table-card-row .country-dropdown >>> .country-dropdown-menu,
.table-card-row .export-dropdown >>> .export-dropdown-menu {
  min-width: 200px;
}
/* country dropdown menu styling */
.table-card-row .country-dropdown >>> .country-dropdown-menu {
  height: auto;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
/* filter/export inner icon styling */
.table-card-row .export-dropdown >>> .export-dropdown-menu .inner-icon,
.table-card-row .filter-dropdown >>> .filter-dropdown-menu .inner-icon {
  width: 16px;
  height: 16px;
}
/* filter/country/export dropdown button styling */
.table-card-row .filter-dropdown >>> .filter-dropdown-menu button,
.table-card-row .country-dropdown >>> .country-dropdown-menu button,
.table-card-row .export-dropdown >>> .export-dropdown-menu button {
  height: 50px;
}
/* button active/selected state styling */
.table-card-row .filter-dropdown >>> .filter-dropdown-menu button.active,
.table-card-row .filter-dropdown >>> .filter-dropdown-menu button:active,
.table-card-row .country-dropdown >>> .country-dropdown-menu button.active,
.table-card-row .country-dropdown >>> .country-dropdown-menu button:active,
.table-card-row .export-dropdown >>> .export-dropdown-menu button.active,
.table-card-row .export-dropdown >>> .export-dropdown-menu button:active {
  background-color: var(--light);
}
/* country dropdown button active text styling */
.table-card-row
  .country-dropdown
  >>> .country-dropdown-menu
  button.active
  span {
  color: var(--dark);
  font-weight: bold;
}
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.table-container-row .table-element {
  min-width: 1000px;
  /* temp fix for make an inner element fill the entire table cell (https://stackoverflow.com/a/56913789) */
  display: table;
  height: 1px;
}
/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}
.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 1rem;
  font-weight: 400;
  border-color: transparent;
  font-family: "Roboto", sans-serif;
}
.table-element >>> .table-body .main-text {
  font-size: 1rem;
}
.table-element >>> .table-body .sub-text {
  font-size: 0.75rem;
}
/* hyperlink custom styling */
.table-element >>> .table-body td {
  vertical-align: middle;
  padding: 0;
  /* temp fix for make an inner element fill the entire table cell (https://stackoverflow.com/a/56913789) */
  height: 100%;
}
.table-element >>> .table-body td > a {
  padding: 0.75rem;
  /* temp fix for make an inner element fill the entire table cell (https://stackoverflow.com/a/56913789) */
  height: 100%;
}
.table-element >>> .table-body .status-badge {
  font-size: 0.8rem;
  min-width: 95px;
}
.table-element >>> .table-body .sub-badge-text {
  font-size: 0.55rem;
}
.table-element >>> .table-body .product-img img {
  object-fit: cover;
}
.testing {
  width: 100px;
  height: 40px;
  padding: 7px;
  /* background-color: var(--white); */
  transition: background-color 200ms ease-in;
}
/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}
/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--light);
}
.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}
/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}
/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}
.table-pagination >>> .custom-page-item button:hover {
  color: var(--secondary);
  border-color: var(--secondary);
  background-color: var(--light);
}
.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}
.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}
.reset-filter-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--white);
  width: auto;
  min-width: 120px;
  height: 50px;
  border: none;
  border-radius: 5px;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .table-card-row .filter-dropdown,
  .table-card-row .export-dropdown {
    min-width: auto;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .insight-cards-row {
    height: auto;
  }
  .table-card-row .search-input-group {
    max-width: none;
  }
}
</style>
