<template>
  <InitLayout>
    <!-- logo placeholder -->
   
    <b-card
      no-body
     
      class="
        d-flex
        flex-column
        align-items-center
        justify-content-center
        px-1 px-lg-2
        py-3
        shadow-hard
        login-container-card
      "
      style="background-color: rgba(255, 255, 255, 0.4); backdrop-filter: blur(5px);"

    >
    <b-row class="w-full my-3" no-gutters>
      <b-img
        src="@/assets/The Logo.png"
        class="px-0 w-auto"
        height="80px"
        alt="Personal PAC"
      ></b-img>
    </b-row>
      <!-- headings -->
      <b-col
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <b-row class="mb-3" no-gutters>
          <font-awesome-icon
            class="icon-container bg-white shadow-soft fa-3x"
            icon="user-shield"
          />
        </b-row>
        <b-row no-gutters>
          <h3 class="text-center font-primary font-weight-bold main-heading">
            Administrator Login
          </h3>
        </b-row>
        <b-row no-gutters>
          <span class="text-center font-primary mb-4 sub-heading">
            Please enter your admin credentials to gain access
          </span>
        </b-row>
      </b-col>

      <!-- login form -->
      <form
        id="login-form"
        class="
          d-flex
          flex-column
          align-items-center
          justify-content-center
          px-2 px-lg-1
          w-100
        "
      >
        <!-- full row input (Username) -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col cols="12" md="10">
            <FormInput
              groupId="email-group"
              id="email-group-input"
              :state="emailError.status"
              :invalidFeedback="`${emailError.message}`"
              label="Email Address"
              v-model="email"
              placeholder="user@example.com"
              isRequired
              trim
              form="login-form"
              type="text"
            ></FormInput>
            <!-- @keypress.native="isEmail(email)" -->
          </b-col>
        </b-row>
        <!-- full row input (Password) -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col cols="12" md="10">
            <FormInputGroup
              groupId="password-group"
              id="password-group-input"
              v-model="password"
              :state="passwordError.status"
              :invalidFeedback="`${passwordError.message}`"
              label="Password"
              placeholder="Minimum 6 characters"
              isRequired
              :type="showPassword ? 'text' : 'password'"
              form="login-form"
              isAppend
            >
              <template v-slot:append>
                <b-button
                  class="px-3 px-md-4 eye-container"
                  variant="transparent"
                  @click="showPassword = !showPassword"
                >
                  <font-awesome-icon
                    class="text-prime-gray"
                    :icon="showPassword ? 'eye-slash' : 'eye'"
                    aria-label="Help"
                  ></font-awesome-icon>
                </b-button>
              </template>
            </FormInputGroup>
          </b-col>
        </b-row>
        <!-- error status -->
        <b-row
          v-if="loginError.status"
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <span class="text-danger text-small text-center">{{
            this.loginError.message
          }}</span>
        </b-row>
        <!-- form action -->
        <b-row
          class="
            d-flex
            flex-row
            justify-content-center justify-content-md-between
            align-items-center
            px-5 px-lg-4 px-xl-5
            w-100
          "
          no-gutters
        >
          <!-- login button -->
          <b-col
            class="
              d-flex
              flex-column
              align-items-center align-items-md-start
              justify-content-center
              px-0
              py-2
            "
            cols="12"
            md="6"
            lg="5"
          >
            <FormButton
              isBlock
              class="font-weight-normal"
              type="submit"
              :isLoading="isAPILoading"
              :isDisabled="isAPILoading"
              @click.native="loginFn"
              style="background-color: #00AFF2;"
            >
              Login
            </FormButton>
          </b-col>
          <!-- forgot password button/link -->
          <b-col
            class="
              d-flex
              flex-column
              align-items-center align-items-md-end
              justify-content-center
              px-0
              py-2
            "
            cols="12"
            md="6"
            lg="5"
          >
            <!-- <b-link href="/forgot" class="register-btn-name text-dark"> -->
            <b-link to="/forgot" class="register-btn-name text-dark">
              Forgot Password?
            </b-link>
          </b-col>
        </b-row>
      </form>
    </b-card>
  </InitLayout>
</template>

<script>
// services
import { LoginNew } from "../services/login.service";

// components
// @ is an alias to /src
//import InitLayout from "@/give-proudly-layout/give-proudly-initLayout";
import InitLayout from "@/give-proudly-layout/give-proudly-initLayout";
import FormInput from "@/components/Form/FormInput";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";
import CheckEmail from "@/util/CheckEmail";

export default {
  name: "Login",
  components: {
    InitLayout,
    FormInput,
    FormInputGroup,
    FormButton,
  },
  data() {
    return {
      isAPILoading: false,
      email: "",
      password: "",
      showPassword: false,
      errorEmail: null,
      errorPasswor: null,
      emailError: {
        status: null,
        message: "",
      },
      passwordError: {
        status: null,
        message: "",
      },
      loginError: {
        status: false,
        message: "",
      },
    };
  },
  watch: {
    email(value) {
      if (value) {
        if (CheckEmail(value) == true) this.emailError.status = true;
        else {
          this.emailError.status = false;
          this.emailError.message = "Please enter a valid email address";
        }
      } else {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }
    },
    password(value) {
      if (!value) {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }
    },
  },
  methods: {
    loginFn() {
      if(this.password.length < 6){
        this.passwordError.status = false;
        this.passwordError.message = "A minimum length of 6 characters is required";
      }
      if (this.email && this.password && CheckEmail(this.email)) {
        let payload = {
          email: this.email,
          password: this.password,
        };
        this.isAPILoading = true;
        LoginNew(payload)
          .then((res) => {
            if (res.data.code == 200 || res.data.message == "success") {
              this.isAPILoading = false;
              console.log(res.data.content);
              let payloadNotify = {
                isToast: true,
                title: "Administrator Login",
                content: "Logged in successfully",
                variant: "success",
              };

              this.$store.dispatch("notification/setNotify", payloadNotify);
              localStorage.setItem(
                "adminDetails",
                JSON.stringify(res.data.content)
              );

              // go to dashboard
              this.$router.replace({ name: "Dashboard" });
            } else {
              this.isAPILoading = false;
              this.loginError.status = true;
              this.loginError.message = res.data.details;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.validateForm();
      }
    },
    validateForm() {
      if (!this.email) {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }
      if (!this.password) {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }
    },
  },
};
</script>

<style scoped>
.login-container-card {
  width: 80%;
  height: 70%;
  min-width: 300px;
  min-height: 400px;
  max-width: 600px;
  border-radius: 14px;
  margin-left: 180px;
  
}
/* heading stylings */
.login-container-card .main-heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
.login-container-card .sub-heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}
/* icon styling */
.login-container-card .icon-container {
  padding: 15px;
  height: 50px;
  width: 50px;
  border: 1px solid var(--stroke-light);
  box-sizing: border-box;
  border-radius: 36px;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .login-container-card {
    min-height: fit-content;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .login-container-card {
    width: 90%;
  }
}
.form-group.white-label-bg >>> label:before {
  background-color: transparent;
  
}
.form-group.is-focus >>> label.form-label {
  font-size: 0.8rem;
  color: var(--info);
  top: -17px;
  left: 10px;
  opacity: 1;
  z-index: 2;
}
</style>
