import Instance from "./instance";

export const GetAllUsers = async () => {
  try {
    return await Instance.instanceToken.get("/user");
  } catch (error) {
    return error;
  }
};

export const GetSingleUser = async (payload) => {
  try {
    return await Instance.instanceToken.get(`/user/${payload}`);
  } catch (error) {
    return error;
  }
};

// export const ExportAllUsers = async () => {
//   try {
//     return await Instance.instanceToken.get("/user/export");
//   } catch (error) {
//     return error;
//   }
// };