import Instance from "./instance";

export const CreatePassword = async (payload) => {
  try {
    return await Instance.instanceToken.post("password/", payload);
  } catch (error) {
    return error;
  }
};

export const GetAllPasswords = () => {
  return Instance.instanceToken.get(`/password`);
};

export const EnableDisablePassword = async (payload) => {
  try {
    return await Instance.instanceToken.put(`/password/toggleStatus`, payload);
  } catch (error) {
    return error;
  }
};

export const DeletePassword = async (payload) => {
  try {
    return await Instance.instanceToken.delete(`/password/${payload}`);
  } catch (error) {
    return error;
  }
};
