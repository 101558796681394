import Instance from "./instance";

export const CreateEarnings = async (payload) => {
    try {
        return await Instance.instanceToken.post(`/content/appdata`, payload);
    } catch (error) {
        return error;
    }
};

//upload documents, get data
export const GetMetaData = async (params) => {
    try {
      return await Instance.instanceToken.get(`/pub78`, {
        params,
      });
    } catch (error) {
      return error;
    }
  };
  

//upload document
export const uploadDocument = async (payload, onUploadProgress) => {
  try {
    const config = { onUploadProgress }
      return await Instance.instanceFileUpload.post('/pub78', payload, config)
  } catch (error) {
    return error;
  }
};

export const GetEarnings = async () => {
    try {
        return await Instance.instanceToken.get(`/content/appdata`);
    } catch (error) {
        return error;
    }
};

export const ManualApproval = async (enabled) => {
    try {
        return await Instance.instanceToken.post(`/content/appdata/manual_approval`, {
            enabled
        });
    } catch (error) {
        return error;
    }
}

export const SwitchPaymentGateway = async (type) => {
    try {
        return await Instance.instanceToken.post(`/content/appdata/switch_gateway`, {
            type
        });
    } catch (error) {
        return error;
    }
}