<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          table-card-row
          w-100
        "
        no-gutters
      >
        <b-card
          no-body
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            card-container
          "
        >
          <b-row class="px-3 py-0 body-container" no-gutters>
            <!-- left column -->
            <b-col class="px-0 px-md-2 py-3 left-column">
              <!-- Add a New Admin row -->
              <CreateCoupon @reloadFn="reloadFn" :couponList="coupons" />
            </b-col>
            <!-- column divider -->
            <hr class="column-divider align-self-center" />
            <!-- right column -->
            <b-col class="px-0 px-md-2 py-3 right-column">
              <!-- Admin Management row -->
              <CouponList
                @onChangePasswords="coupons = $event"
                :key="isReload"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>

<script>
// components
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import CreateCoupon from "@/components/Coupons/CreateCoupon";
import CouponList from "@/components/Coupons/CouponList";

export default {
  name: "Coupons",
  components: {
    CreateCoupon,
    CouponList,
    HomeLayout,
  },
  data() {
    return {
      isReload: Number(new Date()),
      coupons: [],
    };
  },

  methods: {
    reloadFn() {
      this.isReload = Number(new Date());
    },
  },
};
</script>

<style scoped>
.card-container {
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  border-radius: 16px 16px 16px 16px;
}
.body-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  height: 100%;
  min-height: 560px;
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  border-radius: 16px 16px 16px 16px;
}
.body-container .column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .body-container {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
  .body-container .column-divider {
    width: 100%;
    height: 0;
    border-right: none;
    border-top: 1px solid var(--light);
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}
</style>
