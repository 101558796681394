<template>
  <b-sidebar
    class="nav-sidebar-outer"
    sidebar-class="nav-sidebar"
    header-class="px-2 py-3 w-100 nav-sidebar-header"
    body-class="d-flex flex-column align-items-center justify-content-center"
    :visible="sideMenuExpanded"
    left
    width="260px"
    backdrop-variant="light"
    backdrop
    no-header-close
    bg-variant="light"
    text-variant="dark"
    @hidden="closeSideBar"
  >
    <!-- default title header -->
    <template v-slot:title="{ hide }">
      <b-row
        class="
          d-flex
          flex-row
          align-items-center
          justify-content-between
          px-0
          w-100
        "
        no-gutters
      >
        <!-- title logo -->
        <b-navbar-brand class="px-0 mx-0 my-1" to="/">
          <b-img
        src="@/assets/The Logo.png"
        class="px-0 w-auto"
        height="45px"
        alt="Give Proudly"
        v-if="env === 'give-proudly'"
      ></b-img>
      <b-img
        src="@/assets/personalPac-log.svg"
        class="px-0 w-auto"
        height="20px"
        alt="Personal PAC"
        v-else
      ></b-img>
        </b-navbar-brand>
        <!-- close button -->
        <b-button
          variant="link"
          pill
          class="close px-2 py-1 mr-0"
          @click="hide()"
        >
          <font-awesome-icon
            icon="times"
            variant="dark"
            aria-label="Close Sidebar"
          ></font-awesome-icon>
        </b-button>
      </b-row>
    </template>
    <!-- sidebar default content -->
    <template v-slot:default>
      <b-col
        class="
          d-flex
          flex-column
          justify-content-start
          align-items-start
          nav-container
          px-0
        "
        is-nav
      >
        <b-navbar-nav
          class="
            d-flex
            flex-column
            align-items-strecth
            justify-content-start
            w-100
          "
        >
          <template v-for="(item, index) in menuArray">
            <!-- @click.prevent is to prevent default behavior of anchor tag (that is in the b-nav-item component) -->
            <b-nav-item
              :key="key + index"
              :id="`mobile-nav-item-${index}`"
              :active="item.routeName == $route.name"
              :title="item.label"
              class="side-nav-item my-2"
              @click="routeFunc(item, index)"
              @click.prevent
            >
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-start
                  side-nav-item-row
                  mx-auto
                "
              >
                <font-awesome-icon
                  font-scale="2"
                  :icon="item.icon"
                  class="
                    d-flex
                    jusify-content-center
                    align-items-center
                    pr-2
                    side-nav-icon
                  "
                ></font-awesome-icon>
                <span
                  class="
                    side-nav-text
                    font-primary font-weight-normal
                    text-left
                  "
                  >{{ item.label }}</span
                >
              </b-row>
            </b-nav-item>
          </template>
        </b-navbar-nav>
      </b-col>
    </template>
  </b-sidebar>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "MobileSideBar",
  props: {
    sideMenuExpanded: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      key: Number(new Date()),
      menuArray: [
        {
          icon: "th-large",
          label: "Dashboard",
          routeName: "Dashboard",
        },
         {
          icon: "building",
          label: "Campaign Management",
          routeName: "Campaign Management",
        },
        {
          icon: "lock",
          label: "Coupons",
          routeName: "Coupons",
        },
        // {
        //   icon: "users",
        //   label: "Users",
        //   routeName: "Users",
        // },
        // {
        //   icon: "play",
        //   label: "Videos",
        //   routeName: "Videos",
        // },
        {
          active: false,
          icon: "cog",
          label: "Settings",
          routeName: "Account Settings",
        },
      ],
      adminType: "",
      isMasterAdmin: false,
    };
  },
  computed: {
    ...mapGetters({
      sideMenuIndex: "getSideMenuIndex",
    }),
  },
  mounted() {
    if (localStorage.getItem("adminDetails")) {
      let admin = JSON.parse(localStorage.getItem("adminDetails"));
      this.adminType = admin.adminType;
    }
    // enable other route navigation if the user is a master admin
    if (this.adminType == "MASTER") {
      this.isMasterAdmin = true;
    }
    if (this.isMasterAdmin) {
      this.menuArray = [
        {
          icon: "th-large",
          label: "Dashboard",
          routeName: "Dashboard",
        },
         {
          icon: "building",
          label: "Campaign Management",
          routeName: "Campaign Management",
        },
        {
          icon: "lock",
          label: "Coupons",
          routeName: "Coupons",
        },
        // {
        //   icon: "users",
        //   label: "Users",
        //   routeName: "Users",
        // },
        // {
        //   icon: "play",
        //   label: "Videos",
        //   routeName: "Videos",
        // },
        // {
        //   icon: "lock",
        //   label: "Passwords",
        //   routeName: "Passwords",
        // },
        {
          icon: "users-cog",
          label: "Administrators",
          routeName: "Administrators",
        },
        {
          icon: "newspaper",
          label: "Content",
          routeName: "Content",
        },
      
        {
          icon: "lightbulb",
          label: "Apps setting",
          routeName: "App-Settings",
          
        },
        {
          icon: "music",
          label: "Audios",
          routeName: "Audios",
        },
        {
          active: false,
          icon: "cog",
          label: "Settings",
          routeName: "Account Settings",
        },
      ];
    }
  },
  methods: {
    closeSideBar() {
      this.$store.dispatch("setIsSideMenuExpanded", false);
      // this.$emit("onClose");
    },
    routeFunc(item, index) {
      this.key = Number(new Date());
      this.$store.dispatch("setSideMenuIndex", index);
      this.$store.dispatch("setIsSideMenuExpanded", false);
      this.$router.push({ name: item.routeName }).catch((err) => {
        console.log("ROUTE SWITCH ERROR: ", err);
      });
    },
  },
};
</script>
<style scoped>
.nav-sidebar-outer .nav-container {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.nav-sidebar-outer .logo-img {
  max-height: 60px;
}
.nav-sidebar-outer >>> .nav-sidebar-header {
  font-size: 1rem;
}
.nav-sidebar-outer >>> .nav-sidebar-header strong {
  width: 100%;
  font-weight: normal;
  font-size: 1rem;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.nav-sidebar-outer .nav-container::-webkit-scrollbar {
  width: 0.5rem;
  scrollbar-width: thin;
  background-color: var(--dark);
}
.nav-sidebar-outer .nav-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--light);
}
.nav-sidebar-outer .nav-container::-webkit-scrollbar-thumb {
  background-color: var(--light);
}
/* side nav design */
.nav-sidebar-outer .navbar-nav .side-nav-item {
  border-radius: 0;
  transition: background-color 100ms linear 0s, color 100ms linear 0s,
    max-width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
/* nav item hover state */
.nav-sidebar-outer .navbar-nav .side-nav-item:hover {
}
/* nav link (icon/text) color (default state)  */
.side-nav-item a.nav-link {
  background-color: transparent;
  padding: 0.5rem;
  border-radius: 0;
  transition: background-color 100ms linear 0s, color 100ms linear 0s;
}
.side-nav-item a.nav-link .side-nav-icon {
  color: var(--prime-gray);
  transition: color 100ms ease-in;
  user-select: none;
  height: 32px;
  width: 32px;
}
.side-nav-item a.nav-link .side-nav-text {
  font-size: 1rem;
  color: var(--dark);
  padding-left: 0.65rem;
  transition: color 100ms ease-in, opacity 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
  opacity: 1;
}
/* nav link (icon/text) hover color (default state) */
.side-nav-item:hover a.nav-link .side-nav-icon {
  color: var(--primary);
}
.side-nav-item:hover a.nav-link .side-nav-text {
  color: var(--primary);
}
/* nav link (icon/text) color (active state)  */
.side-nav-item a.nav-link.active {
  color: var(--dark);
  cursor: auto;
  border-right: 3px solid var(--primary);
}
.side-nav-item a.nav-link.active .side-nav-icon {
  color: var(--primary);
}
.side-nav-item a.nav-link.active .side-nav-text {
  color: var(--primary);
}
/* nav link (icon/text) hover color (active state)  */
.side-nav-item:hover a.nav-link.active {
}
.side-nav-item:hover a.nav-link.active .side-nav-icon {
}
.side-nav-item:hover a.nav-link.active .side-nav-text {
}
</style>
