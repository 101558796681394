import { render, staticRenderFns } from "./DeleteCampaign.vue?vue&type=template&id=d2dd57ce&scoped=true"
import script from "./DeleteCampaign.vue?vue&type=script&lang=js"
export * from "./DeleteCampaign.vue?vue&type=script&lang=js"
import style0 from "./DeleteCampaign.vue?vue&type=style&index=0&id=d2dd57ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2dd57ce",
  null
  
)

export default component.exports