<template>
<HomeLayout>
    <b-container class="d-flex flex-column align-items-start justify-content-start px-0">
        <!-- table container row -->
        <b-row class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100" no-gutters>
            <b-card no-body class="overflow-hidden border border-light rounded-0 shadow-sm w-100 card-container">
                <b-row class="px-3 py-0 body-container" no-gutters>
                    <!-- left column -->
                    <b-col class="px-0 px-md-2 py-3 left-column">
                        <!-- Add a New Admin row -->
                        <CreateAdmin @reloadFn="reloadFn" />
                    </b-col>
                    <!-- column divider -->
                    <b-col class="px-3 left-column">
                        <div class="column-divider align-self-center" />
                    </b-col>
                    <!-- right column -->
                    <b-col class="px-0 px-md-2 py-3 right-column">
                        <!-- Admin Management row -->
                        <AdminList :key="isReload" />
                    </b-col>
                </b-row>
            </b-card>
        </b-row>
    </b-container>
</HomeLayout>
</template>

<script>
// components
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import CreateAdmin from "@/components/Admins/CreateAdmin";
import AdminList from "@/components/Admins/AdminList";

export default {
    name: "Admins",
    components: {
        CreateAdmin,
        AdminList,
        HomeLayout,
    },
    data() {
        return {
            email: null,
            password: null,
            adminType: null,
            AdminTypeOptions: ["Master Admin", "Super Admin", "General Admin"],
            showPassword: false,
            emailError: {
                status: null,
                message: "",
            },
            passwordError: {
                status: null,
                message: "",
            },
            adminTypeError: {
                status: null,
                message: "",
            },
            isReload: Number(new Date()),
        };
    },

    methods: {
        reloadFn() {
            this.isReload = Number(new Date());
        },
    },
};
</script>

<style scoped>
.body-container {
    display: grid;
    grid-template-columns: 48% 4% 48%;
    height: 100%;
}

.body-container .column-divider {
    width: 0;
    height: 100%;
    border-right: 1px solid var(--light);
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .body-container {
        grid-template-columns: 100%;
        grid-gap: 0.3rem;
    }

    .body-container .column-divider {
        width: 100%;
        height: 0;
        border-right: none;
        border-top: 1px solid var(--light);
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {}
</style>
